import React, { useEffect, useRef, useState } from 'react';

/* Import self written configs, requests or helpers */
import { components, default as ReactSelect } from 'react-select';

import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Create Multi-Dropdown-Menu
 * Suggestion: https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3
 *
 * @param {*} options Options that can be selected
 * @param {functtion} setSelected Function to save all selected items
 * @returns {html} DropdownMenu
 */
export default function DropdownMenu({ options, setSelected, id = null }) {
    const [ state, setState ] = useState({ 'optionSelected': null });
    const select = useRef(null);

    const ClearIndicator = (props) => (
        <i className="clear a-icon ui-ic-close-small" onClick={() => props.clearValue()}></i>
    );

    const DropdownIndicator = () => (
        <i className="a-icon boschicon-bosch-ic-down"></i>
    );

    const IndicatorSeparator = () => (
        null
    );

    /**
     * Definiton of Checkboxes for Menu
     * @param {*} props Values to parse them
     * @returns {html} Checkboxes
     */
    const Option = (props) => (
        <div>
            <components.Option {...props} openMenuOnClick={false}>
                <span className="a-checkbox">
                    <input type="checkbox" id={id ? `${id}_${props.value}` : null} name={props.value} checked={props.isSelected} onChange={() => null} />
                    <label htmlFor={id ? `${id}_${props.value}` : null}>{props.value}</label>
                </span>
            </components.Option>
        </div>
    );

    const MultiValueRemove = (props) => (
        <components.MultiValueRemove {...props}>
            <i className="a-icon a-chip__close ui-ic-close-small" title="close button"></i>
        </components.MultiValueRemove>
    );

    const Input = (props) => (
        <components.Input {...props} id={id ? `${id}_input` : null} />
    );

    /**
     * Reaction, if new element is chosen in list: Add element_name to a list
     * @param {*} selected All items that are selected
     */
    const handleChange = (selected) => {
        setState({ 'optionSelected': selected });

        const array_help = [];
        for (let i = 0; i < selected.length; i++) {
            array_help.push(selected[i].value);
        }
        setSelected(array_help);
    };

    useEffect(() => {
        select.current.focus();
    }, [ state ]);

    return (
        <span>
            <ReactSelect
                ref={select}
                options={options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    'ClearIndicator': ClearIndicator,
                    'DropdownIndicator': DropdownIndicator,
                    'IndicatorSeparator': IndicatorSeparator,
                    'Input': Input,
                    'MultiValueRemove': MultiValueRemove,
                    'Option': Option,
                }}
                placeholder={language.dropdown_select}
                allowSelectAll={true}
                value={state.optionSelected}
                blurInputOnSelect={true}
                unstyled={true}
                className="select"
                classNames={{
                    'menu': () => ('menu m-popover -detached'),
                    'control': () => ('control'),
                    'indicatorsContainer': () => ('indicators'),
                    'multiValue': () => ('item a-chip -btnClose'),
                    'multiValueLabel': () => ('multivaluelabel a-chip__label'),
                    'multiValueRemove': () => ('multivalueremove'),
                    'option': (state) => (
                        [
                            'option',
                            state.isFocused ? 'focused' : null,
                            state.isSelected ? 'selected' : null,
                            state.isDisabled ? 'disabled' : null,
                        ].filter(Boolean).join(' ')
                    ),
                    'valueContainer': () => ('container'),
                }}
                isMulti
                onChange={handleChange}
            />
        </span>
    );
}
