import React, { createContext, useContext, useReducer, useState } from 'react';

const ShortTripModeContext = createContext();

function stmResultParamsReducer(state, action) {
    switch (action.type) {
        case 'energyBenefit':
            return { ...state, 'energyBenefit': action.payload };
        case 'rangeBenefit':
            return { ...state, 'rangeBenefit': action.payload };
        default:
            return state;
    }
}

function measuresParamsReducer(state, action) {
    switch (action.type) {
        case 'actualVelocity':
            return { ...state, 'actualVelocity': action.payload };
        case 'vecoVelocity':
            return { ...state, 'vecoVelocity': action.payload };
        case 'actualCabinTemperature':
            return { ...state, 'actualCabinTemperature': action.payload };
        case 'vecoCabinTemperature':
            return { ...state, 'vecoCabinTemperature': action.payload };
        case 'actualBatteryConditioning':
            return { ...state, 'actualBatteryConditioning': action.payload };
        case 'vecoBatteryConditioning':
            return { ...state, 'vecoBatteryConditioning': action.payload };
        case 'actualFastChargingPreparation':
            return { ...state, 'actualFastChargingPreparation': action.payload };
        case 'vecoFastChargingPreparation':
            return { ...state, 'vecoFastChargingPreparation': action.payload };
        default:
            return state;
    }
}

function autoModeCardParamsReducer(state, action) {
    switch (action.type) {
        case 'activeMode':
            return { ...state, 'activeMode': action.payload };
        case 'range':
            return { ...state, 'range': action.payload };
        default:
            return state;
    }
}

export const ShortTripModeProvider = ({ children }) => {
    const [ vcuSignal, setVCUSignal ] = useState({
        'VehCoorn_dstRngPred': 0,
        'Com_egyHvbAvl_f': 0,
        'VehEgyMngt_vMaxLim': 0,
        'VehEgyMngt_stCbnCdng': 0,
        'Com_tEnv': 0,
        'Com_tCbn': 0,
        'ThermPrepn_wElecDmndPreCdng': 0,
        'VehEgyMngt_stHiUBattCdng': 0,
        'VehEgyMngt_wElecLimCdngBatt': 0,
    });

    const [ historicalVcuSignal, setHistoricalVCUSignal ] = useState({
        'VehCoorn_dstRngPred': 0,
        'Com_egyHvbAvl_f': 0,
        'VehEgyMngt_vMaxLim': 0,
        'VehEgyMngt_stCbnCdng': 0,
        'Com_tEnv': 0,
        'Com_tCbn': 0,
        'ThermPrepn_wElecDmndPreCdng': 0,
        'VehEgyMngt_stHiUBattCdng': 0,
        'VehEgyMngt_wElecLimCdngBatt': 0,
    });

    const [ vcuShortTripModeSignal, setVCUShortTripModeSignal ] = useState({
        'VehCoorn_dstRngPrdnDrvgCnd_0': 0,
        'VehCoorn_dstRngPrdnDrvgCnd_1': 0,
    });

    const [ environmentTemperatureParams, setEnvironmentTemperatureParams ] = useState({
        'shortTripModeActivationValue': false,
        'environmentTemperatureValue': 0,
    });

    const [ stmResultParams, dispatchSTMResultParams ] = useReducer(stmResultParamsReducer, {
        'energyBenefit': 0,
        'rangeBenefit': 0,
    });

    const [ measuresParams, dispatchMeasuresParams ] = useReducer(measuresParamsReducer, {
        'actualVelocity': 'Unlimited',
        'vecoVelocity': '---',
        'actualCabinTemperature': 0,
        'vecoCabinTemperature': '---',
        'actualBatteryConditioning': 'Off',
        'vecoBatteryConditioning': '---',
        'actualFastChargingPreparation': 'Off',
        'vecoFastChargingPreparation': '---',
    });

    const [ autoModeCardParams, dispatchAutoModeCardParams ] = useReducer(autoModeCardParamsReducer, {
        'activeMode': false,
        'range': 0,
    });

    return (
        <ShortTripModeContext.Provider
            value={{
                'vcuSignal': vcuSignal,
                'setVCUSignal': setVCUSignal,
                'historicalVcuSignal': historicalVcuSignal,
                'setHistoricalVCUSignal': setHistoricalVCUSignal,
                'environmentTemperatureParams': environmentTemperatureParams,
                'setEnvironmentTemperatureParams': setEnvironmentTemperatureParams,
                'stmResultParams': stmResultParams,
                'dispatchSTMResultParams': dispatchSTMResultParams,
                'measuresParams': measuresParams,
                'dispatchMeasuresParams': dispatchMeasuresParams,
                'autoModeCardParams': autoModeCardParams,
                'dispatchAutoModeCardParams': dispatchAutoModeCardParams,
                'vcuShortTripModeSignal': vcuShortTripModeSignal,
                'setVCUShortTripModeSignal': setVCUShortTripModeSignal,
            }}
        >
            {children}
        </ShortTripModeContext.Provider>
    );
};

export const useShortTripMode = () => useContext(ShortTripModeContext);
