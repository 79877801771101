export class TypeGuard {
    static isNonNullable(value) {
        return value !== null && value !== undefined;
    }

    static isSafeString(value) {
        return typeof value === 'string';
    }

    static isSafeNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    static isSafeBoolean(value) {
        return typeof value === 'boolean';
    }

    static isSafeSymbol(value) {
        return typeof value === 'symbol';
    }

    static isSafeObject(value) {
        return typeof value === 'object' && value !== null;
    }
}
