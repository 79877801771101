import React from 'react';
import './NavBar.scss';

export default function Navbar({ navItems, selectedIndex, changeTabCallback }) {
    return (
        <div className="a-tab-navigation__wrapper navbar">
            <ol className="a-tab-navigation" role="tablist">
                {navItems.map((item, index) => (
                    <NavItem
                        key={index}
                        item={item}
                        index={index}
                        selectedIndex={selectedIndex}
                        onClick={changeTabCallback}
                    />
                ))}
            </ol>
        </div>
    );
}

function NavItem({ index, item, selectedIndex, onClick }) {
    return (
        <li className="a-tab-navigation__item" role="none">
            <button
                type="button"
                className={`a-tab-navigation__tab ${selectedIndex === index ? '-selected' : ''}`}
                data-frok-tab-identifier={index}
                tabIndex="0"
                role="tab"
                aria-controls={`tab-${index}`}
                id={`tab-${index}`}
                onClick={() => onClick(index)}
            >
                <span className="a-tab-navigation__tab-content">
                    <span className="a-tab-navigation__label">{item}</span>
                </span>
            </button>
        </li>
    );
}
