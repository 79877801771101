import React, { createContext, useContext, useReducer, useState } from 'react';

const LastMileModeContext = createContext();

function showCaseReducer(state, action) {
    switch (action.type) {
        case 'range':
            return { ...state, 'range': action.payload };
        case 'energy':
            return { ...state, 'energy': action.payload };
        case 'lastMileEnergy':
            return { ...state, 'lastMileEnergy': action.payload };
        case 'safeStopEnergy':
            return { ...state, 'safeStopEnergy': action.payload };
        case 'consumption':
            return { ...state, 'consumption': action.payload };
        case 'travelDistance':
            return { ...state, 'travelDistance': action.payload };
        case 'travelTime':
            return { ...state, 'travelTime': action.payload };
        case 'lastMileMode':
            return { ...state, 'lastMileMode': action.payload };
        case 'changingState':
            return { ...state, 'changingState': action.payload };
        case 'changingProgress':
            return { ...state, 'changingProgress': action.payload };
        default:
            return state;
    }
}

export const LastMileModeProvider = ({ children }) => {
    const [ showCaseParams, dispatchShowCaseParams ] = useReducer(showCaseReducer, {
        'range': 0,
        'energy': 0,
        'lastMileEnergy': 0,
        'safeStopEnergy': 0,
        'consumption': 0,
        'travelDistance': 0,
        'travelTime': 0,
        'changingState': false,
        'changingProgress': 0,
    });
    const [ generalSettingsParams, setGeneralSettingsParams ] = useState({
        'vehicleCategory': 'basic',
        'safeStopThresholdType': 'range',
        'safeStopThresholdValue': 0.2,
        'preinitialisation': null,
        'ownConsumption': false,
        'consumptionFactor': 1,
    });

    const [ vehicleInfrastructureParams, setVehicleInfrastructureParams ] = useState({
        'consumptionSliderValue': 0,
        'baseLoadSliderValue': 0,
        'highVoltagePrechargeSliderValue': 0,
    });

    const [ vehicleLightingParams, setVehicleLightingParams ] = useState({
        'hazardWaningLightsSliderValue': 0,
        'daytimeLightsSliderValue': 0,
        'lowBeamSliderValue': 0,
    });

    const [ manoeuvringAndParkingParams, setManoeuvringAndParkingParams ] = useState({
        'normalParkingSliderValue': 0,
        'sideParkingSliderValue': 0,
        'uturnSliderValue': 0,
    });

    const [ safetyManoeuvresParams, setSafetyManoeuvresParams ] = useState({
        'slowBrakingSliderValue': 0,
        'fullBrakingSliderValue': 0,
        'unbrakedLaneChangeSliderValue': 0,
        'brakedLaneChangeSliderValue': 0,
    });

    const [ comfortParams, setComfortParams ] = useState({
        'cabinBlowerLevel1SliderValue': 0,
        'cabinBlowerLevel2SliderValue': 0,
        'seatHeaterFrontLevel1SliderValue': 0,
    });

    const [ lastMileMode, setLastMileMode ] = useState(false);

    const [ safeStopMode, setSafeStopMode ] = useState(false);

    const [ engagingTheParkingBrake, setEngagingTheParkingBrake ] = useState(false);

    const [ lastMileModeDialogOpened, setLastMileModeDialogOpened ] = useState(false);

    const [ lastMileModeNotificationOpened, setLastMileModeNotificationOpened ] = useState(false);

    const [ safeStopModeDialogOpened, setSafeStopModeDialogOpened ] = useState(false);

    const [ safeStopModeNotificationOpened, setSafeStopModeNotificationOpened ] = useState(false);

    const [ engagingTheParkingBrakeDialogOpened, setEngagingTheParkingBrakeDialogOpened ] = useState(false);

    const [ engagingTheParkingBrakeNotificationOpened, setEngagingTheParkingBrakeNotificationOpened ] = useState(false);

    return (
        <LastMileModeContext.Provider
            value={{
                'showCaseParams': showCaseParams,
                'dispatchShowCaseParams': dispatchShowCaseParams,
                'generalSettingsParams': generalSettingsParams,
                'setGeneralSettingsParams': setGeneralSettingsParams,
                'vehicleInfrastructureParams': vehicleInfrastructureParams,
                'setVehicleInfrastructureParams': setVehicleInfrastructureParams,
                'vehicleLightingParams': vehicleLightingParams,
                'setVehicleLightingParams': setVehicleLightingParams,
                'manoeuvringAndParkingParams': manoeuvringAndParkingParams,
                'setManoeuvringAndParkingParams': setManoeuvringAndParkingParams,
                'safetyManoeuvresParams': safetyManoeuvresParams,
                'setSafetyManoeuvresParams': setSafetyManoeuvresParams,
                'comfortParams': comfortParams,
                'setComfortParams': setComfortParams,
                'lastMileMode': lastMileMode,
                'setLastMileMode': setLastMileMode,
                'safeStopMode': safeStopMode,
                'setSafeStopMode': setSafeStopMode,
                'engagingTheParkingBrake': engagingTheParkingBrake,
                'setEngagingTheParkingBrake': setEngagingTheParkingBrake,
                'lastMileModeDialogOpened': lastMileModeDialogOpened,
                'setLastMileModeDialogOpened': setLastMileModeDialogOpened,
                'lastMileModeNotificationOpened': lastMileModeNotificationOpened,
                'setLastMileModeNotificationOpened': setLastMileModeNotificationOpened,
                'safeStopModeDialogOpened': safeStopModeDialogOpened,
                'setSafeStopModeDialogOpened': setSafeStopModeDialogOpened,
                'safeStopModeNotificationOpened': safeStopModeNotificationOpened,
                'setSafeStopModeNotificationOpened': setSafeStopModeNotificationOpened,
                'engagingTheParkingBrakeDialogOpened': engagingTheParkingBrakeDialogOpened,
                'setEngagingTheParkingBrakeDialogOpened': setEngagingTheParkingBrakeDialogOpened,
                'engagingTheParkingBrakeNotificationOpened': engagingTheParkingBrakeNotificationOpened,
                'setEngagingTheParkingBrakeNotificationOpened': setEngagingTheParkingBrakeNotificationOpened,
            }}
        >
            {children}
        </LastMileModeContext.Provider>
    );
};

export const useLastMileMode = () => useContext(LastMileModeContext);
