import styled from 'styled-components';

export const ShortTripModeWrapper = styled.div`
    input[type=range] {
        &::-webkit-slider-thumb {
            z-index: 1;
        }

        &::-moz-range-thumb {
            z-index: 1;
        }
    }
    
    .vertical-layout {
        width: 100%;
    }

    .content {
        & > * {
            margin-bottom: 1rem;
        }
    }
`;
