import React, { lazy, Suspense } from 'react';

const LazyManoeuvringAndParking = lazy(() => import('./ManoeuvringAndParking'));

const ManoeuvringAndParking = (props) => (
    <Suspense fallback={null}>
        <LazyManoeuvringAndParking {...props} />
    </Suspense>
);

export default ManoeuvringAndParking;
