import React, { useEffect, useRef, useState } from 'react';

import Header from './Header';

/* Import pictures */
import thermal_management from '../../images/sidebar/thermal_management_icon.svg';
import ph_diagram from '../../images/sidebar/pv_diagram_icon.svg';

/* Import self written configs, requests or helpers */
import { language_config } from '../config/languages';

const language = language_config();

let sidebar;

/**
 * Collapsing and expanding the sidebar
 */
export function toggle_expand() {
    sidebar.current.classList.toggle('-open');
    const selectedSubLink = sidebar.current.querySelector('li > ul > li.m-side-navigation__menuSubitem > .-selected');
    if (selectedSubLink) {
        const parentListItem = selectedSubLink.closest('ul').closest('li');
        parentListItem.classList.add('-open');

        const activate = !sidebar.current.classList.contains('-open');
        parentListItem.querySelector('button').classList.toggle('-selected', activate);
    }
}

function handle_expand(event) {
    const sidebarExpanded = sidebar.current.classList.contains('-open');
    if (!sidebarExpanded) {
        toggle_expand();
    }

    const parentListItem = event.target.closest('li');
    if (parentListItem.querySelector('ul')) {
        if (sidebarExpanded) {
            parentListItem.classList.toggle('-open');
        }
        else {
            parentListItem.classList.add('-open');
        }

        const x = parentListItem.querySelector('ul > li.m-side-navigation__menuSubitem > .-selected');
        console.log(x);
        if (x) {
            const y = x.closest('ul').closest('li');
            const activate = !sidebar.current.classList.contains('-open') || !y.classList.contains('-open');
            parentListItem.querySelector('button').classList.toggle('-selected', activate);
        }
    }
}

/**
 * Component to render sidebar
 * @returns {html} sidebar navigation
 */
export default function Nav() {
    const [ reload, setReload ] = useState(0);
    const [ username /* , setUsername */ ] = useState(localStorage.getItem('username'));
    sidebar = useRef(null);

    /**
     * Function to manage what site is currently active
     * @param {string} id sidebar element
     */
    function set_active(id) {
        document.getElementById(id).classList.add('-selected');
    }

    /**
     * select current visited page and set the element to active
     */
    useEffect(() => {
        const loc = window.location.pathname;
        switch (loc) {
            case '/':
                set_active('home');
                break;
            case '/history':
                set_active('history');
                break;
            case '/custom_dashboard':
                set_active('custom_dashboard');
                break;
            case '/tmm':
                set_active('tmm');
                break;
            case '/hvpowernet':
                set_active('cobra');
                set_active('hvpowernet');
                break;
            case '/thermalmanagement':
                set_active('cobra');
                set_active('thermal_management');
                break;
            case '/heatflow':
                set_active('heat_flow');
                break;
            case '/cvem_shm':
                set_active('cvem');
                set_active('cvem_shm');
                break;
            case '/cvem_stm':
                set_active('cvem');
                set_active('cvem_stm');
                break;
            case '/cvem_lmm':
                set_active('cvem');
                set_active('cvem_lmm');
                break;
            case '/ph_diagram': set_active('ph_diagram');
                break;
            case '/maps':
                set_active('maps');
                break;
            case '/settings':
                set_active('settings');
                break;
            default:
                console.warn('Cannot set active page in the menu: no matching site found.');
                break;
        }
    }, []);

    /**
     * function to signout
     */
    function signout() {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        window.location.reload(true);
    }

    return (
        <>
            <div id="navigation" className="o-minimal-header -primary">
                <nav className="m-side-navigation -contrast" aria-label="Side Navigation" aria-hidden="false" ref={sidebar}>
                    <div className="m-side-navigation__header">
                        <div className="m-side-navigation__header__label -size-l highlight">Connected Powertrain</div>
                        <button type="button" className="a-button a-button--integrated -without-label m-side-navigation__header__trigger -open" aria-haspopup="false" aria-label="Open Side Navigation" tabIndex="0" onClick={toggle_expand}>
                            <i className="a-icon a-button__icon boschicon-bosch-ic-list-view-mobile" title="list-view-mobile"></i>
                        </button>
                        <button type="button" className="a-button a-button--integrated -without-label m-side-navigation__header__trigger -close" aria-haspopup="false" aria-label="Close Side Navigation" tabIndex="-1" onClick={toggle_expand}>
                            <i className="a-icon a-button__icon boschicon-bosch-ic-close" title="close"></i>
                        </button>
                    </div>
                    <ul className="m-side-navigation__menuItems" role="menubar">
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/" id="home" role="menuitem" className="m-side-navigation__link" title={language.home}>
                                <i className="a-icon boschicon-bosch-ic-home"></i>
                                <span className="m-side-navigation__label">{language.home}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/tmm" id="tmm" role="menuitem" className="m-side-navigation__link" title={`Kolibri: ${language.thermo}`}>
                                <i className="a-icon boschicon-bosch-ic-car-side-engine-flash"></i>
                                <span className="m-side-navigation__label">{`Kolibri: ${language.thermo}`}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <button id="cobra" type="button" className="m-side-navigation__group" aria-haspopup="true" role="menuitem" title="Cobra" onClick={handle_expand}>
                                <i className="a-icon boschicon-bosch-ic-car-side-plug-flash"></i>
                                <span className="m-side-navigation__label">Cobra</span>
                                <i className="a-icon arrow boschicon-bosch-ic-down" title="down"></i>
                            </button>
                            <ul className="m-side-navigation__menuSubitems" role="menu">
                                <li className="m-side-navigation__menuSubitem" role="none">
                                    <a href="/hvpowernet" id="hvpowernet" role="menuitem" className="m-side-navigation__link" aria-disabled="true" tabIndex="-1" title={language.hvpowernet}>
                                        <i className="a-icon boschicon-bosch-ic-electric-motor-flash"></i>
                                        <span className="m-side-navigation__label">{language.hvpowernet}</span>
                                    </a>
                                </li>
                                <li className="m-side-navigation__menuSubitem" role="none">
                                    <a href="thermalmanagement" id="thermal_management" role="menuitem" className="m-side-navigation__link" tabIndex="-1" title={language.thermal_management}>
                                        <i className="a-icon">
                                            <img src={thermal_management} alt={language.thermal_management} />
                                        </i>
                                        <span className="m-side-navigation__label">{language.thermal_management}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/heatflow" id="heat_flow" role="menuitem" className="m-side-navigation__link" tabIndex="-1" title={language.heat_flow}>
                                <i className="a-icon boschicon-bosch-ic-hot"></i>
                                <span className="m-side-navigation__label">{language.heat_flow}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <button id="cvem" type="button" className="m-side-navigation__group" aria-haspopup="true" role="menuitem" title="CVEM" onClick={handle_expand}>
                                <i className="a-icon boschicon-bosch-ic-car-connected"></i>
                                <span className="m-side-navigation__label">CVEM</span>
                                <i className="a-icon arrow boschicon-bosch-ic-down" title="down"></i>
                            </button>
                            <ul className="m-side-navigation__menuSubitems" role="menu">
                                <li className="m-side-navigation__menuSubitem" role="none">
                                    <a href="/cvem_shm" id="cvem_shm" role="menuitem" className="m-side-navigation__link" aria-disabled="true" tabIndex="-1" title={language.safe_home_mode}>
                                        <i className="a-icon boschicon-bosch-ic-home"></i>
                                        <span className="m-side-navigation__label">{language.safe_home_mode}</span>
                                    </a>
                                </li>
                                <li className="m-side-navigation__menuSubitem" role="none">
                                    <a href="/cvem_stm" id="cvem_stm" role="menuitem" className="m-side-navigation__link" aria-disabled="true" tabIndex="-1" title={language.short_trip_mode}>
                                        <i className="a-icon boschicon-bosch-ic-home"></i>
                                        <span className="m-side-navigation__label">{language.short_trip_mode}</span>
                                    </a>
                                </li>
                                <li className="m-side-navigation__menuSubitem" role="none">
                                    <a href="/cvem_lmm" id="cvem_lmm" role="menuitem" className="m-side-navigation__link" aria-disabled="true" tabIndex="-1" title={language.last_mile_mode}>
                                        <i className="a-icon boschicon-bosch-ic-home"></i>
                                        <span className="m-side-navigation__label">{language.last_mile_mode}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/ph_diagram" id="ph_diagram" role="menuitem" className="m-side-navigation__link" tabIndex="-1" title={language.ph_diagram}>
                                <i className="a-icon">
                                    <img src={ph_diagram} alt={language.ph_diagram} />
                                </i>
                                <span className="m-side-navigation__label">{language.ph_diagram}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/custom_dashboard" id="custom_dashboard" role="menuitem" className="m-side-navigation__link" title={language.custom_dashboard}>
                                <i className="a-icon boschicon-bosch-ic-desktop-dashboard"></i>
                                <span className="m-side-navigation__label">{language.custom_dashboard}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/maps" id="maps" role="menuitem" className="m-side-navigation__link" title={language.maps}>
                                <i className="a-icon boschicon-bosch-ic-map"></i>
                                <span className="m-side-navigation__label">{language.maps}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/history" id="history" role="menuitem" className="m-side-navigation__link" title={language.history}>
                                <i className="a-icon boschicon-bosch-ic-history"></i>
                                <span className="m-side-navigation__label">{language.history}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <a href="/settings" id="settings" role="menuitem" className="m-side-navigation__link" title={language.settings}>
                                <i className="a-icon boschicon-bosch-ic-settings"></i>
                                <span className="m-side-navigation__label">{language.settings}</span>
                            </a>
                        </li>
                        <li className="m-side-navigation__menuItem" role="none">
                            <button role="menuitem" className="m-side-navigation__link" title={language.logout} onClick={signout}>
                                <i className="a-icon boschicon-bosch-ic-logout"></i>
                                <span className="m-side-navigation__label">
                                    {language.logout}: <i>{username}</i>
                                </span>
                            </button>
                        </li>
                    </ul>
                </nav>
                <Header setReload={setReload} reload={reload} />
            </div>
        </>
    );
}
