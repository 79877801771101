import Toggle from './safe-home-mode/components/Toggle/Toogle';
import React from 'react';

export default function ModeCard({ number, limit, temperature, selectedModeCallback }) {
    const handleSelectMode = (isChecked) => {
        const unselectedModeNumber = 0;
        const range = 0;
        selectedModeCallback(isChecked ? number : unselectedModeNumber, range);
    };

    return (
        <div className="a-box -floating-shadow-s mode">
            <div className="name">
                <span>Mode&nbsp;{number}</span>
            </div>
            <div className="properties">
                <p>
                    <i className="a-icon boschicon-bosch-ic-speedometer-arrow"></i>
                    <i className="a-icon boschicon-bosch-ic-brake-pedal"></i>
                </p>
                <p>Limit {limit} km/h</p>
            </div>

            <div className="properties">
                <p>
                    <i className="a-icon boschicon-bosch-ic-ice-temperature"></i>
                    <i className="a-icon boschicon-bosch-ic-car"></i>
                </p>
                <p>±{temperature} °C</p>
            </div>

            <div className="toggle">
                <Toggle name={number} changeCallback={handleSelectMode} />
            </div>

        </div>
    );
}
