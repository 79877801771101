import React, { useRef } from 'react';

export default function Input({ label, disabled, ...props }) {
    const inputRef = useRef(null);
    const elemsAfterRef = useRef(null);

    let className = 'a-text-field';

    let elemsAfter = null;
    if (props.type === 'password') {
        className = 'a-password-input';

        elemsAfter = (
            <button
                ref={elemsAfterRef}
                type="button"
                className="a-password-input__icon-password"
                disabled={disabled}
                onClick={() => {
                    if (inputRef.current.type === 'password') {
                        inputRef.current.type = 'text';
                        elemsAfterRef.current.querySelector('i').className = 'a-icon ui-ic-watch-off';
                    }
                    else {
                        inputRef.current.type = 'password';
                        elemsAfterRef.current.querySelector('i').className = 'a-icon ui-ic-watch-on';
                    }
                }}
            >
                <i className="a-icon ui-ic-watch-on"></i>
            </button>
        );
    }

    return (
        <div className={className}>
            {
                (label !== undefined)
                    ? <label htmlFor={props.id}>{label}</label>
                    : null
            }
            <input ref={inputRef} type="text" disabled={disabled} {...props} />
            {elemsAfter}
        </div>
    );
}
