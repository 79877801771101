import React from 'react';
import { useMessage } from './MessageContext';
import { StyledDialog } from './MessageDialog.styled';

const MessageDialog = () => {
    const { messageState, messageDispatch } = useMessage();

    const handleClose = () => {
        messageDispatch({ 'type': 'HIDE_MESSAGE' });
    };

    return (
        <StyledDialog
            open={messageState.open}
            title={messageState.title}
            variant={messageState.type} // Using variant from MessageContext
            modal
            onClose={handleClose}
        >
            <span>{messageState.message}</span>
        </StyledDialog>
    );
};

export default MessageDialog;
