import React from 'react';
import './SignalDisplayRow.scss';

export default function SignalDisplayRow({ name, children, dark }) {
    return (
        <div className={`signal-row ${dark && 'dark'}`}>
            <div className="signal-value clearfix">
                <span>{children}</span>
            </div>
            <div className="signal-postfix-title">{name}</div>
        </div>
    );
}
