import React, { lazy, Suspense } from 'react';

const LazySafetyManoeuvres = lazy(() => import('./SafetyManoeuvres'));

const SafetyManoeuvres = (props) => (
    <Suspense fallback={null}>
        <LazySafetyManoeuvres {...props} />
    </Suspense>
);

export default SafetyManoeuvres;
