import React from 'react';
import { saveAs } from 'file-saver';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

/* import colors for plotly */
import { light_colors, dark_colors } from './helpers/plotlyColors';

import { get_data, get_map } from './helpers/historicalData';
import { csv_icon, json_icon } from './helpers/plotlyIcons';

const Plot = createPlotlyComponent(Plotly);

/* define colors for plotly */
let color = '';
JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;

/**
 * function to build Plotly.js plot to display historical data given in items
 * @param {Array} items
 * @returns {Plot} line graph
 */
export function HistoryGraph({ items }) {
    return (
        <Plot
            divId={'history_graph'}
            data={get_data(items)}
            layout={{
                'font': { 'color': color.text_color },
                'autosize': true,
                'margin': { 'b': 40, 'l': 40, 'r': 0, 't': 30 },
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{
                'displaylogo': false,
                'modeBarButtonsToRemove': [ 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d' ],
                'modeBarButtonsToAdd': [
                    {
                        'name': 'downloadCsv',
                        'title': 'Download data as csv',
                        'icon': csv_icon,
                        'click': (gd) => {
                            let max_len = 0;
                            const data = [
                                gd.data.map((trace) => {
                                    if (trace.x.length > max_len) {
                                        max_len = trace.x.length;
                                    }

                                    return [ 'timestamp', trace.name ];
                                }).join(','),
                            ];
                            console.log(max_len);

                            for (let count = 0; count < max_len; count++) {
                                const row = [];
                                gd.data.forEach((trace) => {
                                    if (trace.x[count] && trace.y[count]) {
                                        row.push(trace.x[count], trace.y[count]);
                                    }
                                    else {
                                        row.push('', '');
                                    }
                                });
                                data.push(row);
                            }
                            const blob = new Blob([ data.join('\r\n') ], { 'type': 'text/csv' });

                            saveAs(
                                blob,
                                'export.csv'
                            );
                        },
                    },
                    {
                        'name': 'downloadJSON',
                        'title': 'Download data as JSON',
                        'icon': json_icon,
                        'click': (gd) => {
                            const data = [];
                            gd.data.forEach((trace) => {
                                console.log(trace.y);
                                data.push(`{ "name" : "${trace.name}",  "x_values" : [${JSON.stringify(trace.x)}], "y_values" : [${JSON.stringify(trace.y)}] }`);
                            });

                            console.log(JSON.parse(`{ "values" : [${data} ] }`));

                            const blob = new Blob([ JSON.stringify(JSON.parse(`{ "values" : [${data} ] }`)) ], { 'type': 'application/json' });

                            saveAs(
                                blob,
                                'export.json'
                            );
                        },
                    },
                ],
            }}
        />
    );
}

/**
 * function to build Plotly.js plot to display historical map given in items
 * @param {Array} items
 * @returns {Plot} map plot
 */
export function HistoryMap({ items }) {
    return (
        <Plot
            divId={'history_map'}
            data={get_map(items)}
            layout={{
                'font': { 'color': color.text_color },
                'autosize': true,
                'margin': { 'b': 20, 'l': 20, 'r': 20, 't': 30 },
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
                'hovermode': 'closest',
                'hovertemplate': '<extra></extra>',

                'mapbox': {
                    'style': 'carto-positron',
                    'center': {
                        'lat': 49.6341372,
                        'lon': 8.3507182,
                    },
                    'zoom': 12,
                },
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{
                'displaylogo': false,
                'modeBarButtonsToRemove': [ 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d' ],
                'modeBarButtonsToAdd': [
                    {
                        'name': 'downloadCsv',
                        'title': 'Download data as csv',
                        'icon': csv_icon,
                        'click': (gd) => {
                            const max_len = gd.data.lat.length;
                            const data = [ 'timestamp', 'latitude', 'longitude' ];
                            const row = [];
                            for (let count = 0; count < max_len; count++) {
                                row.push(gd.data.customdata.time[count], gd.data.lat[count], gd.data.lon[count]);
                                data.push(row);
                            }

                            const blob = new Blob([ data.join('\r\n') ], { 'type': 'text/csv' });

                            saveAs(
                                blob,
                                'export.csv'
                            );
                        },
                    },
                    {
                        'name': 'downloadJSON',
                        'title': 'Download data as JSON',
                        'icon': json_icon,
                        'click': (gd) => {
                            const data = [];
                            data.push(`{ "name" : "GPS", "time" : ${JSON.stringify(gd.data[0].customdata.time)}, "latitude" : ${JSON.stringify(gd.data[0].lat)}, "longitude" : ${JSON.stringify(gd.data[0].lon)} }`);

                            const blob = new Blob([ JSON.stringify(JSON.parse(data)) ], { 'type': 'application/json' });

                            saveAs(
                                blob,
                                'export.json'
                            );
                        },
                    },
                ],
            }}
        />
    );
}
