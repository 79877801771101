import React, { useState } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import Input from '../../../frok/Components/Input';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * component to delete the User
 * @param {boolean} admin Specifies whether the user is an admin
 * @returns {html} component for rendering the user-deletion
 */
export default function UserAccount({ admin }) {
    const [ openDeletePopup, setOpenDeletePopup ] = useState(false);
    const [ openChangePopup, setOpenChangePopup ] = useState(false);
    const [ errorPasswordText, setErrorPasswordText ] = useState('');
    const [ errorUserDelete, setErrorUserDelete ] = useState('');

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    /**
     * function to delete user with username 'username'
     */
    function request_delete_me() {
        fetch(requests.delete_user, {
            'method': 'DELETE',
            'body': JSON.stringify({ 'username': localStorage.getItem('username') }),
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        }).then((response) => {
            if (response.status === 200) {
                console.log('API-Request for Deleting of:', localStorage.getItem('username'));
                setOpenDeletePopup(false);

                /* Logout */
                localStorage.setItem('token', null);
                localStorage.setItem('username', null);
                window.location.reload(false);
            }
            else {
                setErrorUserDelete('Error');
            }
        });
    }

    /**
     * @param {string} username Username of the user to be changed
     * @param {string} password Password of the user to be changed
     * @param {string} new_password Password of the user to be changed
     */
    function request_user_password_change(username, password, newPassword, confirmPassword) {
        if (newPassword !== confirmPassword) {
            setErrorPasswordText('Passwords do not match!');
            return;
        }

        const request_body = JSON.stringify({
            'username': username,
            'password': password,
            'new_password': newPassword,
        });

        fetch(requests.change_password, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 201) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.change_password);
                    setFetchResponse(data.message);
                    setOpenChangePopup(false);
                }
                else {
                    setErrorPasswordText(data.message);
                }
            }))
            .catch((error) => {
                setErrorPasswordText(`${language.network_error} ${error}`);
            });
    }

    function handlePasswordChange() {
        const username = localStorage.getItem('username');
        const oldPassword = document.getElementById('old_pw').value;
        const newPassword = document.getElementById('new_pw').value;
        const confirmPassword = document.getElementById('confirm_pw').value;

        request_user_password_change(username, oldPassword, newPassword, confirmPassword);
    }

    return (
        <>
            <div id="account_settings" className="settings_block">
                <h4>{language.account}</h4>
                <Button
                    id="delete_account"
                    icon="delete"
                    onClick={() => setOpenDeletePopup(true)}
                >
                    {language.delete_account}
                </Button>

                <Button
                    id="change_password"
                    icon="binary"
                    onClick={() => {
                        setErrorPasswordText('');
                        setOpenChangePopup(true);
                    }}
                >
                    {language.change_password}
                </Button>
            </div>

            <Dialog
                open={openDeletePopup}
                header={language.delete_account}
                level="warning"
                button1={
                    (admin === true)
                        ? null
                        : (
                            <Button
                                icon="delete"
                                onClick={() => request_delete_me()}
                            >
                                {language.delete}
                            </Button>)
                }
                button2={(
                    <Button
                        icon="close"
                        secondary
                        onClick={() => setOpenDeletePopup(false)}
                    >
                        {language.cancel}
                    </Button>
                )}
            >

                <p>
                    {
                        (admin === true)
                            ? language.delete_account_admin_self_admin
                            : language.delete_account_text_self
                    }
                </p>

                <NotificationBar type="error">{errorUserDelete}</NotificationBar>
            </Dialog>

            <Dialog
                open={openChangePopup}
                header={language.change_password}
                button1={(
                    <Button
                        icon="save"
                        onClick={() => handlePasswordChange()}
                    >
                        {language.save}
                    </Button>
                )}
                button2={(
                    <Button
                        icon="close"
                        secondary
                        onClick={() => setOpenChangePopup(false)}
                    >
                        {language.cancel}
                    </Button>
                )}
            >
                <div className="m-form-field">
                    <Input type="password" id="old_pw" name="old_password" label={language.old_password} />
                </div>

                <div className="m-form-field">
                    <Input type="password" id="new_pw" name="new_password" label={language.new_password} />
                </div>

                <div className="m-form-field">
                    <Input type="password" id="confirm_pw" name="confirm_password" label={language.confirm_password} />
                </div>

                <NotificationBar type="error">{errorPasswordText}</NotificationBar>
            </Dialog>

            <Dialog
                header={fetchResponseTitle}
                level={fetchResponseSuccess ? 'success' : 'error'}
                button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                open={fetchResponse.length > 0}
            >
                {fetchResponse}
            </Dialog>
        </>
    );
}
