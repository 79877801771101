import React from 'react';
import './Toggle.scss';

export default function Toggle({ name, changeCallback }) {
    return (
        <div className="a-toggle">
            <label>
                <input
                    id={`mode_${name}_toggle`}
                    className="mode_toggle"
                    type="checkbox"
                    role="switch"
                    onChangeCapture={(event) => {
                        const isChecked = event.target.checked;
                        if (isChecked) {
                            uncheckOtherToggles(event);
                        }

                        changeCallback(isChecked);
                    }}
                />
                <div className="a-toggle__trigger"></div>
            </label>
        </div>
    );
}

function uncheckOtherToggles(event) {
    [ ...document.querySelectorAll('input.mode_toggle:checked') ].forEach((element) => {
        if (event.target !== element) {
            element.checked = false;
        }
    });
}
