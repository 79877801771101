/*
 * History-Page for rendering an overview of historical data
 * - functions:        plot_graph(), plot_scale(), plot_maps()
 * - Documentation:    plotly: https://plotly.com/javascript/
 */

/* plotly.js */
import React from 'react';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

/* import colors for plotly */
import { light_colors, dark_colors } from './helpers/plotlyColors';
import { language_config } from '../../config/languages';
import { convert_to_displayable_unit } from '../../config/converter';
import { focus_icon } from './helpers/plotlyIcons';

const language = language_config();

const Plot = createPlotlyComponent(Plotly);

/* define colors for plotly */
let color = '';
JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;

/**
 * Create a line-graph with the divId "id" and the description "text", size, margin etc are predefined
 * @param {string} id
 * @returns {Plot} graph
 */
function plot_graph(id) {
    const plots = [ 'catalystTemp_graph', 'evaporativePurge_graph', 'barometricPressure_graph', 'O2WrCurrent_graph', 'speed_graph', 'ambientAirTemp_graph', 'timingAdvance_graph', 'intakeTemp_graph', 'fuelRate_graph', 'oilTemp_graph', 'coolantTemp_graph', 'engineLoad_graph', 'rpm_graph', 'intakePressure_graph', 'throttlePos_graph', 'relativeThrottlePos_graph', 'acceleratorPos_graph', 'throttleActuator_graph', 'pitch_graph', 'effectiveEnergy_graph', 'relativeEffectiveEnergy_graph', 'neededDriveForce_graph' ];
    const text = `${language.graph_of} ${id.replace('_graph', '')}`;
    let unit = '';
    if (id.includes('speed')) {
        unit = convert_to_displayable_unit('speed');
    }
    else if (id.includes('Temp')) {
        unit = convert_to_displayable_unit('temperature');
    }
    else if (id.includes('timingAdvance') || id.includes('pitch')) {
        unit = language.degree;
    }
    else if (id.includes('Pressure')) {
        unit = 'kPa';
    }
    else if (id.includes('O2WrCurrent')) {
        unit = 'mA';
    }
    else if (id.includes('rpm')) {
        unit = 'x100';
    }
    else if (id.includes('fuel')) {
        unit = 'l/h';
    }
    else if (id.includes('Effective')) {
        unit = '%';
    }

    return (
        <Plot
            divId={id}
            data={[
                {
                    'y': [],
                    'x': [],
                    'type': 'scatter',
                    'mode': 'lines+markers',
                    'marker': { 'color': color.primary_accent_color },
                    'line': { 'color': color.primary_accent_color },
                    'hovertemplate': '<b>Value</b>: %{y:.2f}%{yaxis.title.text}<extra></extra>',
                },
            ]}
            layout={{
                'title': { 'text': text, 'xref': 'paper', 'x': 0, 'font': { 'size': 24 } },
                'font': { 'color': color.text_color },
                'hovermode': 'closest',
                'autosize': true,
                'margin': { 'b': 20, 'l': 30, 'r': 20, 't': 50 },
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
                'yaxis': { 'title': { 'text': unit } },
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{ 'displayModeBar': false }}
            onHover={(eventdata) => {
                const points = eventdata.points;
                if (points) {
                    const time = points[0].x;
                    plots.forEach((e) => {
                        if (exists(e)) {
                            const exdata = document.getElementById(e).data[0].x;
                            let pn;
                            exdata.forEach((etime, index) => {
                                if (etime.toISOString().slice(13, -3) === time.toString().slice(13, -2)) {
                                    pn = index;
                                }
                            });
                            return (
                                Plotly.Fx.hover(
                                    e, [
                                        {
                                            'curveNumber': 0,
                                            'pointNumber': pn,
                                        },
                                    ]
                                )
                            );
                        }
                    });
                    if (exists('latLong_maps')) {
                        const colors = [],
                            sizes = [];
                        const cdata = document.getElementById('latLong_maps').data[0].customdata;
                        cdata.forEach((etime) => {
                            if (etime.toISOString().slice(13, -3) === time.toString().slice(13, -2)) {
                                colors.push(color.indicator_orange);
                                sizes.push(12);
                            }
                            else {
                                colors.push(color.primary_accent_color);
                                sizes.push(6);
                            }
                        });

                        const update = { 'marker': { 'color': colors, 'size': sizes } };
                        Plotly.restyle('latLong_maps', update, [ 0 ]);
                    }
                }
            }}
            onUnhover={(eventdata) => {
                const points = eventdata.points;
                if (points) {
                    const time = points[0].x;
                    plots.forEach((e) => {
                        if (exists(e)) {
                            return (
                                Plotly.Fx.unhover(
                                    e, [
                                        {
                                            'curveNumber': 0,
                                            'x': time,
                                        },
                                    ]
                                )
                            );
                        }
                    });
                    if (exists('latLong_maps')) {
                        Plotly.Fx.unhover(
                            'latLong_maps', [
                                {
                                    'curveNumber': 0,
                                    'customdata': time,
                                },
                            ]
                        );
                        const update = { 'marker': { 'color': color.primary_accent_color, 'size': 6 } };
                        Plotly.restyle('latLong_maps', update, [ 0 ]);
                    }
                }
            }}
        />
    );
}

/**
 * Create ascale with the divId "id" and the description "text", size, margin etc are predefined
 * defines a lower and a upper bound (lower_bound, upper_bound) of the scale
 * voluntarily given are steps: print colored steps
 * @param {string} id
 * @param {string} text - optional
 * @returns {Plot} scale
 */
function plot_scale(id, text = `${language.scale_of} ${id.replace('_scale', '')}`) {
    let unit = '';
    if (id.includes('speed')) {
        unit = ` (${convert_to_displayable_unit('speed')})`;
    }
    else if (id.includes('Temp')) {
        unit = ` (${convert_to_displayable_unit('temperature')})`;
    }
    else if (id.includes('timingAdvance') || id.includes('pitch')) {
        unit = ` (${language.degree})`;
    }
    else if (id.includes('Pressure')) {
        unit = ' (kPa)';
    }
    else if (id.includes('O2WrCurrent')) {
        unit = ' (mA)';
    }
    else if (id.includes('rpm')) {
        unit = ' x100';
    }
    else if (id.includes('fuel')) {
        unit = ' (mm^3/s)';
    }
    else if (id.includes('Effective')) {
        unit = ' (%)';
    }

    return (
        <Plot
            divId={id}
            data={[
                {
                    'type': 'indicator',
                    'mode': 'gauge+number',
                    'value': 0,
                    'gauge': { 'bar': { 'color': color.text_color } },
                },
            ]}
            layout={{
                'title': { 'text': text + unit, 'xref': 'paper', 'x': 0, 'font': { 'size': 24 } },
                'font': { 'color': color.text_color },
                'autosize': true,
                'margin': { 'b': 20, 'l': 35, 'r': 35, 't': 50 },
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{ 'displayModeBar': false }}
        />
    );
}

/**
 * Create a map with the divId "id" either in default or fullsize mode
 * @param {string} id
 * @param {string} layout - optional
 * @returns {Plot} map
 */
function plot_maps(id, layout = 'default') {
    const plots = [ 'catalystTemp_graph', 'evaporativePurge_graph', 'barometricPressure_graph', 'O2WrCurrent_graph', 'speed_graph', 'ambientAirTemp_graph', 'timingAdvance_graph', 'intakeTemp_graph', 'fuelRate_graph', 'oilTemp_graph', 'coolantTemp_graph', 'engineLoad_graph', 'rpm_graph', 'intakePressure_graph', 'throttlePos_graph', 'relativeThrottlePos_graph', 'acceleratorPos_graph', 'throttleActuator_graph', 'pitch_graph', 'effectiveEnergy_graph', 'relativeEffectiveEnergy_graph', 'neededDriveForce_graph' ];
    let title, margin, style;
    if (layout === 'fullscreen') { // Variable definition for fullscreen map used in Maps
        title = '';
        margin = { 'b': 0, 'l': 0, 'r': 0, 't': 0 };
        style = 'open-street-map';
    }
    else {  // Default values for generel use of map plot in Main, Drive and CustomDashboard
        title = { 'text': 'GPS', 'xref': 'paper', 'x': 0, 'font': { 'size': 24 } };
        margin = { 'b': 20, 'l': 30, 'r': 30, 't': 50 };
        style = 'carto-positron';
    }

    return (
        <Plot
            divId={id}
            data={[
                {
                    'lat': [],
                    'lon': [],
                    'customdata': [], /* Used to display time */
                    'type': 'scattermapbox',
                    'mode': 'lines+markers',
                    'marker': { 'color': color.primary_accent_color },
                    'line': { 'color': color.primary_accent_color },
                    'hovertemplate': '<extra></extra>',
                },
            ]}
            layout={{
                'title': title,
                'font': { 'color': color.text_color },
                'autosize': true,
                'margin': margin,
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
                'hovermode': 'closest',

                'mapbox': {
                    'style': style,
                    'center': {
                        'lat': 49.6341372,
                        'lon': 8.3507182,
                    },
                    'zoom': 12,
                },
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{
                'displaylogo': false,
                'modeBarButtonsToRemove': [ 'resetViewMapbox', 'pan2d', 'toImage', 'zoomInGeo', 'zoomOutGeo', 'zoom2d', 'zoomIn2d', 'zoomOut2d', 'zoom3d' ],
                'modeBarButtonsToAdd': [
                    {
                        'name': 'Auto focus',
                        'title': 'Toggle auto focus',
                        'icon': focus_icon,
                        'click': () => {
                            localStorage.setItem('auto_zoom_maps', JSON.stringify(JSON.parse(localStorage.getItem('auto_zoom_maps')) === null ? true : !JSON.parse(localStorage.getItem('auto_zoom_maps'))));
                        },
                    },
                ],
            }}
            onHover={(eventdata) => {
                const points = eventdata.points;
                if (points) {
                    const time = points[0].customdata;
                    plots.forEach((e) => {
                        if (exists(e)) {
                            const exdata = document.getElementById(e).data[0].x;
                            let pn;
                            exdata.forEach((etime, index) => {
                                if (etime.toISOString().slice(0, -3) === time.toISOString().slice(0, -3)) {
                                    pn = index;
                                }
                            });
                            return (
                                Plotly.Fx.hover(
                                    e, [
                                        {
                                            'curveNumber': 0,
                                            'pointNumber': pn,
                                        },
                                    ]
                                )
                            );
                        }
                    });
                    if (exists('latLong_maps')) {
                        const pn = time,
                            colors = [],
                            sizes = [];
                        for (let i = 0; i < points[0].data.lat.length; i++) {
                            colors.push(color.primary_accent_color);
                            sizes.push(6);
                        }
                        colors[pn] = color.indicator_orange;
                        sizes[pn] = 12;

                        const update = { 'marker': { 'color': colors, 'size': sizes } };
                        Plotly.restyle('latLong_maps', update, [ 0 ]);
                    }
                }
            }}
            onUnhover={(eventdata) => {
                const points = eventdata.points;
                if (points) {
                    const time = points[0].customdata;
                    plots.forEach((e) => {
                        if (exists(e)) {
                            return (
                                Plotly.Fx.unhover(
                                    e, [
                                        {
                                            'curveNumber': 0,
                                            'x': time,
                                        },
                                    ]
                                )
                            );
                        }
                    });
                    if (exists('latLong_maps')) {
                        Plotly.Fx.unhover(
                            'latLong_maps', [
                                {
                                    'curveNumber': 0,
                                    'customdata': time,
                                },
                            ]
                        );
                        const update = { 'marker': { 'color': color.primary_accent_color, 'size': 6 } };
                        Plotly.restyle('latLong_maps', update, [ 0 ]);
                    }
                }
            }}
        />
    );
}

/**
 * Create a bar chart with the divId "id"
 * @param {string} id
 * @param {string} title
 * @returns {Plot} bar
 */
function plot_bar(id, title) {
    return (
        <Plot
            divId={id}
            data={[
                {
                    'type': 'bar',
                    'x': [],
                    'text': [],
                    'marker': { 'color': color.primary_accent_color },
                    'orientation': 'h',
                    'textfont': { 'size': 22 },
                    'constraintext': 'inside',
                    'insidetextanchor': 'start',
                },
            ]}
            layout={{
                'title': { 'text': title, 'xref': 'paper', 'x': 0, 'font': { 'size': 24 } },
                'font': { 'color': color.text_color },
                'autosize': true,
                'margin': { 'b': 20, 'l': 30, 'r': 30, 't': 50 },
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
                'showlegend': false,
                'hovermode': false,
                'yaxis': { 'visible': false },
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{ 'displayModeBar': false }}
        />
    );
}

/**
 * Create a number chart with the divId "id"
 * @param {string} id
 * @param {string} title
 * @returns {Plot} number
 */
function plot_number(id, title) {
    return (
        <Plot
            divId={id}
            data={[
                {
                    'type': 'indicator',
                    'mode': 'number',
                    'value': 0,
                },
            ]}
            layout={{
                'title': { 'text': title, 'xref': 'paper', 'x': 0.05, 'font': { 'size': 24 } },
                'font': { 'color': color.text_color },
                'autosize': true,
                'margin': { 'b': 20, 'l': 30, 'r': 30, 't': 50 },
                'plot_bgcolor': color.background,
                'paper_bgcolor': color.background,
            }}
            useResizeHandler={true}
            style={{ 'width': '100%', 'height': '100%' }}
            config={{ 'displayModeBar': false }}
        />
    );
}

/**
 * Function to return if an element exists on the page
 * @param {string} id element name
 * @returns {boolean} true | false
 */
function exists(id) {
    return (document.getElementById(id));
}

export const plots = {
    'plot_graph': plot_graph,
    'plot_scale': plot_scale,
    'plot_maps': plot_maps,
    'plot_bar': plot_bar,
    'plot_number': plot_number,
};
