import React, { useState } from 'react';

/* Import FROK components */
import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import Dropdown from '../../../frok/Components/Dropdown';
import Input from '../../../frok/Components/Input';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * function to request cars from API and save data using callback(cars)
 */
export function request_cars(callback) {
    fetch(requests.get_cars, {
        'headers': {
            'Authorization': localStorage.getItem('token'),
            //
        },
    })
        .then((response) => response.json())
        .then((data) => callback(data));
}

/**
 * Component to load all cars of a user or in case of admin, all
 *
 * @param {boolean} admin props, Value wheather user is admin or not
 * @param {int} reload props, value to trigger update
 * @param {function} setReload  props, function to set the reload
 * @param {Object} cars props, Object which contains the models
 * @param {Object} models props, Object which contains the models
 * @returns {html} return list of cars
 */
export default function Cars({ admin, reload, setReload, cars, models }) {
    const [ deleteCar, setDeleteCar ] = useState(null);

    const [ openCar, setOpenCar ] = useState({});
    const [ openCarId, setOpenCarId ] = useState(null);

    const [ carNotificationBarText, setCarNotificationBarText ] = useState('');
    const [ responseSuccess, setResponseSuccess ] = useState(false);

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    const [ openDeletePopup, setOpenDeletePopup ] = useState(false);

    /**
     * create a new Car
     */
    function saveCar() {
        /* Check if all fields are filled */
        if (openCar.license_plate === '' || openCar.model_id === '' || openCar.vin === '') {
            setResponseSuccess(false);
            setCarNotificationBarText(language.error_fields_not_filled);
            return;
        }
        else {
            setResponseSuccess(true);
            setCarNotificationBarText('');
        }

        /* Set Header for creating a new car */
        const request_body_car = JSON.stringify({
            'model_id': openCar.model_id,
            'vin': openCar.vin,
            'license_plate': openCar.license_plate,
            'can_dbc': openCar.can_dbc,
            'uds_dbc': openCar.uds_dbc,
        });

        fetch(requests.create_car, {
            'method': 'POST',
            'body': request_body_car,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 201) {
                    setReload(reload + 1);
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.new_car);
                    setFetchResponse('Car created successfully!');
                    handleCarClose();
                }
                else {
                    setResponseSuccess(false);
                    setCarNotificationBarText((data && data.message) ? `Error: ${data.message}` : 'Error! Something went wrong!');
                }
            }))
            .catch((error) => {
                setResponseSuccess(false);
                setCarNotificationBarText('Error: ', error);
            });
    }

    /**
     * function to remove a existing car
     *
     * @param {int} car_id Id of car to be removed
     */
    function request_delete_car(car_id) {
        if (car_id === null) {
            setFetchResponseSuccess(false);
            setFetchResponseTitle(language.delete_car);
            setFetchResponse('Error! This car does not exist!');
            setOpenDeletePopup(true);
        }

        if (car_id === 0) {
            setFetchResponseSuccess(false);
            setFetchResponseTitle(language.delete_car);
            setFetchResponse('Error! Can not modify "Simulator" car!');
            return;
        }

        const request_body = JSON.stringify({ 'car_id': car_id });

        fetch(requests.delete_car, {
            'method': 'DELETE',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setReload(reload + 1);
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.delete_car);
                    setFetchResponse('Car deleted successfully!');
                    setOpenDeletePopup(false);
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.delete_car);
                    setFetchResponse((data && data.message) ? `Error: ${data.message}` : 'Error! Something went wrong!');
                    setOpenDeletePopup(false);
                }
            }));
    }

    /**
     * Create car-Data-Div
     *
     * @param {int} car_id Id of the car to be printed
     * @param {*} car Data of the car to be printed
     * @returns {html} Data of the car
     */
    function print_cars(car_id, car, index) {
        const model = models.find((model) => model.id.toString() === car.model_id.toString());
        const model_name = model ? model.model_name : <i>?</i>;
        return (
            <tr key={index}>
                <th>{car_id}</th>
                <td>{car.license_plate}</td>
                <td>{model_name}</td>
                <td>{
                    (admin && car_id !== '0')
                        ? (
                            <Button
                                icon="delete"
                                onClick={() => {
                                    setOpenDeletePopup(true);
                                    setDeleteCar(car_id);
                                }}
                            >
                                {language.delete}
                            </Button>
                        )
                        : null
                }
                <Button
                    icon="info-i-frame"
                    secondary
                    onClick={() => {
                        setOpenCar(car);
                        setOpenCarId(car_id);
                        setResponseSuccess(true);
                        setCarNotificationBarText('');
                    }}
                >{language.info}
                </Button>
                </td>
            </tr>
        );
    }

    function modifyCar(car_id) {
        setResponseSuccess(false);

        if (car_id === null) {
            setCarNotificationBarText('Error! This car does not exist!');
            return;
        }

        if (car_id === 0) {
            setCarNotificationBarText('Error! Can not modify "Simulator" car!');
            return;
        }

        setCarNotificationBarText('');

        const request_body = JSON.stringify({
            'car_id': car_id,
            'model_id': openCar.model_id,
            'vin': openCar.vin,
            'license_plate': openCar.license_plate,
            'can_dbc': openCar.can_dbc,
            'uds_dbc': openCar.uds_dbc,
        });

        fetch(requests.modify_car, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setResponseSuccess(true);
                    setCarNotificationBarText('Car successfully modified!');
                    setReload(reload + 1);
                }
                else {
                    setResponseSuccess(false);
                    setCarNotificationBarText('Car could not be modified!');
                }
            })
            .catch((error) => {
                setResponseSuccess(false);
                setCarNotificationBarText(`${language.error_occured}: ${error}`);
            });
    }

    function handleCarChange() {
        if (openCarId === -1) {
            saveCar();
        }
        else {
            modifyCar(openCarId);
        }
    }

    function handleCarClose() {
        setOpenCarId(null);
        setResponseSuccess(true);
        setCarNotificationBarText('');
    }

    function onChangeNewCar(event) {
        const { name, value } = event.target;
        setOpenCar({ ...openCar, [name]: value });
    }

    /**
     * called, if the value of Model in the newCar Popup is changed
     */
    function changeModelData(event) {
        if (event.target.options[0].value === 'select...') {
            event.target.remove(0);
        }
        setOpenCar({ ...openCar, 'model_id': event.target.value });
    }

    return (
        <>
            <div id="car_settings" className="settings_block">
                <h4>{language.cars}</h4>
                <table className="m-table">
                    <thead>
                        <tr>
                            <th>{language.id}</th>
                            <th>{language.licence_plate}</th>
                            <th>{language.model_name}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (Object.keys(cars).length > 0)
                                ? Object.keys(cars).map((car, index) => (
                                    print_cars(car, cars[car], index)
                                ))
                                : <tr><th colSpan="4"><i>{`No cars${admin === true ? '' : ' associated with your account'}.`}</i></th></tr>
                        }
                    </tbody>
                </table>

                {
                    (admin)
                        ? (
                            <p>
                                <Button
                                    id="create_car"
                                    icon="add"
                                    onClick={() => {
                                        setOpenCar({
                                            'model_id': (models.length > 0) ? models[0].id : '',
                                            'vin': '',
                                            'license_plate': '',
                                            'can_dbc': '',
                                            'uds_dbc': '',
                                        });
                                        setOpenCarId(-1);
                                        setResponseSuccess(true);
                                        setCarNotificationBarText('');
                                    }}
                                >
                                    {language.new_car}
                                </Button>
                            </p>)
                        : null
                }
            </div>

            <Dialog
                open={openDeletePopup}
                header={language.delete_car}
                level="warning"
                button1={(<Button icon="delete" onClick={() => request_delete_car(deleteCar)}>{language.delete}</Button>)}
                button2={(<Button icon="close" secondary onClick={() => setOpenDeletePopup(false)}>{language.cancel}</Button>)}
            >
                {language.delete_car_text}
            </Dialog>

            <Dialog
                open={Boolean(openCarId)}
                header={(openCarId === -1) ? language.new_car : language.car}
                button1={(
                    (admin)
                        ? (
                            <Button id="save_car" onClick={handleCarChange}>
                                {
                                    (openCarId === -1)
                                        ? language.save
                                        : language.edit
                                }
                            </Button>
                        )
                        : null
                )}
                close={handleCarClose}
            >
                <div className="m-form-field">
                    {
                        (openCarId !== -1)
                            ? <Input label={language.id} name="car_id" defaultValue={openCarId} readOnly />
                            : null
                    }
                </div>
                <div className="m-form-field">
                    {
                        (admin)
                            ? (
                                <Dropdown
                                    id="model_car_select"
                                    label={`${language.model} *`}
                                    defaultValue={
                                        (openCarId !== -1)
                                            ? openCar.model_id
                                            : ((models.length > 0) ? models[0].id : '')
                                    }
                                    onChange={changeModelData}
                                >
                                    {models.map((model, index) => (
                                        <option key={index} value={model.id}>
                                            {model.model_name.toString()}
                                        </option>
                                    ))}
                                </Dropdown>
                            )
                            : (
                                <Input label={`${language.model} *`} name="model_id" defaultValue={openCar.model_id} disabled onChange={onChangeNewCar} />
                            )
                    }
                </div>
                <div className="m-form-field">
                    <Input label={`${language.vin} *`} name="vin" disabled={!(admin)} defaultValue={openCar.vin} onChange={onChangeNewCar} />
                </div>
                <div className="m-form-field">
                    <Input label={`${language.licence_plate} *`} name="license_plate" disabled={!(admin)} defaultValue={openCar.license_plate} onChange={onChangeNewCar} />
                </div>
                <div className="m-form-field">
                    <Input label={language.can_dbc} name="can_dbc" disabled={!(admin)} defaultValue={openCar.can_dbc} onChange={onChangeNewCar} />
                </div>
                <div className="m-form-field">
                    <Input label={language.uds_dbc} name="uds_dbc" disabled={!(admin)} defaultValue={openCar.uds_dbc} onChange={onChangeNewCar} />
                </div>

                <NotificationBar type={responseSuccess ? 'success' : 'error'}>{carNotificationBarText}</NotificationBar>
            </Dialog>

            <Dialog
                header={fetchResponseTitle}
                level={fetchResponseSuccess ? 'success' : 'error'}
                button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                open={fetchResponse.length > 0}
            >
                {fetchResponse}
            </Dialog>
        </>
    );
}
