export const lastMileModeTopicType = 'shortTripAndLastMileMode';
export const shortTripModeTopicType = 'shortTripAndLastMileMode';
export const safeHomeModeTopicType = 'safehomemode';
export const lastMileModeTopicKey = 'shortTripSafeHomeAndLastMileMode';
export const shortTripModeTopicKey = 'shortTripSafeHomeAndLastMileMode';
export const safeHomeModeTopicKey = 'shortTripSafeHomeAndLastMileMode';

export const TOPICS = {
    [safeHomeModeTopicType]: {
        'measurements': [
            'VehCoorn_dstRngPrdnDrvgCnd_0',
            'VehCoorn_dstRngPrdnDrvgCnd_1',
            'VehCoorn_dstRngPred',
            'Com_dstOdo',
            'Com_egyHvbAvl_f',
            'ComEmac_uDcLnk01',
            'ComEmac_iDcLnk01',
            'Com_uCmprInp',
            'Com_iCmprInp',
            'Com_uPtcAct',
            'Com_iPtcAct',
        ],
        'path': '/modified/UDS',
        'key': safeHomeModeTopicKey,
    },
    [lastMileModeTopicType]: {
        'measurements': [
            'VehCoorn_dstRngPred',
            'Com_dstOdo',
            'Com_egyHvbAvl_f',
            'GlbDa_lTotDst',
            'VehV_v',
            'VehEgyMngt_vMaxLim',
            'VehEgyMngt_stCbnCdng',
            'Com_tEnv',
            'Com_tCbn',
            'ThermPrepn_wElecDmndPreCdng',
            'VehEgyMngt_stHiUBattCdng',
            'VehEgyMngt_wElecLimCdngBatt',
        ],
        'path': '/modified/UDS',
        'key': lastMileModeTopicKey,
    },
};

export const TOPIC_PATH_KEY_MAP = { '/modified/UDS': 'shortTripSafeHomeAndLastMileMode' };

