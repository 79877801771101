import React from 'react';

export default function ShowcaseBlock({ Icon, title, children }) {
    return (
        <div className="showcase-row">
            <div className="left-col">
                <Icon />
                <h3 className="show-case-title">{title}</h3>
            </div>
            <div className="right-col">
                {children}
            </div>
        </div>
    );
}
