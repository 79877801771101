import React, { lazy, Suspense } from 'react';

const LazyGeneralSettings = lazy(() => import('./GeneralSettings'));

const GeneralSettings = props => (
  <Suspense fallback={null}>
    <LazyGeneralSettings {...props} />
  </Suspense>
);

export default GeneralSettings;
