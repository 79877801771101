/**
 * Check for existence of element by ID
 * @param {string} id ID of the tested html-object
 * @returns boolean: Element exists or not
 */
export function exists(id) {
    return (document.getElementById(id));
}

/**
 * Format a number with set number of digits, or return a string representation of the object
 * @param {any} value Number to be converted to string
 * @param {SVGElement} element SVG element of the label
 * @returns string: Formatted number or string representation of the object
 */
export function formatNumber(value, element) {
    if ((typeof value) === 'number') {
        const FORMATTER = new Intl.NumberFormat('en-US', {
            'style': 'decimal',
            'useGrouping': false,
            'minimumFractionDigits': element.dataset.digits ? parseInt(element.dataset.digits) : (element.dataset.mindigits ? parseInt(element.dataset.mindigits) : 0),
            'maximumFractionDigits': element.dataset.digits ? parseInt(element.dataset.digits) : (element.dataset.maxdigits ? parseInt(element.dataset.maxdigits) : 3),
        });
        return FORMATTER.format(value);
    }
    else {
        return `${value}`;
    }
}
