import React, { useState, useEffect } from 'react';
import { BounceLoader } from 'react-spinners';
import Plotly from 'plotly.js';
import Popup from 'reactjs-popup';

/* Import own components */
import DropdownMenu from './Elements/History/DropdownMenu';

/* Import self written configs, requests or helpers */
import { language_config } from './config/languages';
import { requests } from './config/network';
import { HistoryGraph, HistoryMap } from './Elements/Plots/HistoricalPlots';
import { parseJson } from './config/network';
import { set_topics, topics } from './RealtimeData/SSE_index';
import { request_cars } from './Elements/Settings/Cars';

const language = language_config();

/**
 * Component to render history site
 * @returns {html} history site html
 */
export default function History() {
    /* Save data returning from Data-API */
    const [ items, setItems ] = useState([]);

    /* Helpers for Displaying */
    const [ displayStyle, setDisplayStyle ] = useState('Graph');
    const [ loadingInProcess, setLoading ] = useState(false);

    /* Save  parameter used to create API-Request */
    const [ dateFrom, setDateFrom ] = useState(null);
    const [ dateTo, setDateTo ] = useState(null);
    const [ carId, setCarId ] = useState(localStorage.getItem('car_id'));
    const [ selectedMeasurement, setSelectedMeasurement ] = useState([]);
    const [ selectedField, setSelectedField ] = useState([]);

    /* Save Structures for creating Drop-Down-Menus */
    const [ cars, setCars ] = useState([]);
    const [ measurement, setMeasurement ] = useState([]);
    const [ fieldStructure, setFieldStructure ] = useState([]);

    /* Errors occured */
    const [ errorFilter, setErrorFilter ] = useState(null);
    const [ errorServer, setErrorServer ] = useState(null);
    const [ errorPopup, setErrorPopup ] = useState(false);

    /* Helper for reloading the page */
    const [ reload, setReload ] = useState(0);

    /**
     * useEffect triggered by [items] to deactivate Loading-Symbol it Items are loaded
     */
    useEffect(() => {
        if (items.length !== 0) {
            setLoading(false);
        }
    }, [ items ]);

    /**
     * useEffect to load topic tree
     */
    useEffect(() => {
        request_cars(setCars);
        set_topics();
    }, []);

    /**
     * function that requests the structure (tags) to write them into the DropDown-Menu
     */
    function request_structure() {
        const helpArrayMeasurement = [ ...new Set(topics.map((topic) => topic.path.split('/').slice(-1)[0])) ].map((name) => ({ 'value': name, 'label': name }));
        const helpArrayField = [ ...new Set(topics.flatMap((topic) => topic.measurements)) ].map((name) => ({ 'value': name, 'label': name }));

        setMeasurement(helpArrayMeasurement);
        setFieldStructure(helpArrayField);

        setReload(reload + 1);
    }

    const filter_bar = document.getElementById('all_filter_api'); // get filter container

    /**
     * function to generate API-Request from all selected filters
     * triggered when the "Save"-Button is pressed
     */
    function save_filter() {
        const print_fields = [];
        const print_tags = [];

        const today = new Date();

        // Reset Items
        setItems([]);

        // Reset Error-Messages
        setErrorFilter(null);

        // Request date_from
        if (dateFrom === null) {
            setErrorFilter(language.error_missing_start);
            return -1;
        }

        if (new Date(dateFrom) > today) {
            setErrorFilter(language.error_start_after_today);
            return -1;
        }

        let request_start = `${requests.addr_database}&slim=1&date_from=${dateFrom}`;
        let request_end = '';

        // Add date_to to Request
        if (dateTo !== null) {
            if (new Date(dateTo) > today) {
                setErrorFilter(language.error_end_after_today);
                return -1;
            }
            else if (dateTo <= dateFrom) {
                setErrorFilter(language.error_start_for_end);
                return -1;
            }

            request_start += `&date_to=${dateTo}`;
        }

        if (Object.keys(cars).filter((id) => id.toString() === carId).length !== 1) {
            setErrorFilter(language.error_carId_missing);
            return -1;
        }

        print_tags.push(`&tags=carId+equals+${carId}`);

        if (print_tags.length !== 0) {
            for (let i = 0; i < print_tags.length; i++) {
                request_end += print_tags[i];
            }
        }

        if (print_fields.length !== 0) {
            for (let i = 0; i < print_fields.length; i++) {
                request_end += print_fields[i];
            }
        }

        console.log(selectedField);
        let request_field = '';
        if (selectedField.length !== 0) {
            for (let i = 0; i < selectedField.length; i++) {
                request_field += `&field=${selectedField[i]}`;
            }
        }

        // Add Measurements to Request and combine request_end and request_start
        let request_measurement = '';
        if (selectedMeasurement.length !== 0) {
            for (let i = 0; i < selectedMeasurement.length; i++) {
                request_measurement += `&measurement=${selectedMeasurement[i]}`;
            }
        }
        else {
            setErrorFilter(language.error_measurement_missing);
            return -1;
        }

        // Form request
        const request = (request_start + request_measurement + request_field + request_end);
        console.info('Request:', request);

        // Request API
        fetch(request)
            .then(parseJson)
            .then((data) => {
                setErrorServer(null);

                console.log('Data: ', data);
                const elemData = [];
                for (const d in data) {
                    data[d].forEach((e) => {
                        elemData.push(e);
                    });
                }
                data.length === 0 ? setLoading(false) : setItems((items) => [ ...items, elemData ]);

                // Shrink filter bar
                if (filter_bar) {
                    filter_bar.classList.add('minimized');
                }
            })
            .catch((error) => {
                setErrorServer(error);
                const error_message = String(error);
                if (error_message.includes('TypeError')) {
                    console.log('TypeError');
                    setErrorPopup(true);
                }
                setLoading(false);
            });

        // Request GPS-Data from API, if the displayStyle expects it
        if (displayStyle === 'graph_and_map') {
            const request = (`${request_start}&measurement=GPS${request_end}`);
            console.log(request);

            // Request API
            fetch(request)
                .then(parseJson)
                .then((data) => {
                    setErrorServer(null);

                    const latLong = [];
                    for (const d in data) {
                        data[d].forEach((e) => {
                            latLong.push(e);
                        });
                    }
                    console.log('latLongData: ', latLong);
                    (data.length === 0) ? setLoading(false) : setItems((items) => [ ...items, latLong ]);

                    // Shrink filter bar
                    if (filter_bar) {
                        filter_bar.classList.add('minimized');
                    }
                })
                .catch((error) => {
                    setErrorServer(error);
                    const error_message = String(error);
                    if (error_message.includes('TypeError')) {
                        console.log('TypeError');
                        setErrorPopup(true);
                    }
                    setLoading(false);
                });
        }

        setLoading(true);
    }

    // TODO: Refactoring!
    useEffect(() => {
        request_structure();

        const plot_container = document.getElementById('plot_container');
        const resizeObserver = new ResizeObserver(() => {
            const history_graph = document.getElementById('history_graph');
            const history_map = document.getElementById('history_map');
            if (window.innerWidth < 600) {
                if (plot_container && history_graph) {
                    Plotly.Plots.resize('history_graph');

                    history_graph.style.height = '100%';
                }
                if (plot_container && history_map && history_graph) {
                    Plotly.Plots.resize('history_map');
                    Plotly.Plots.resize('history_graph');

                    // Update width
                    history_graph.style.width = '100%';
                    history_graph.style.height = '50%';
                    history_map.style.width = '100%';
                    history_map.style.height = '50%';
                }
            }
            else {
                if (plot_container && history_graph) {
                    Plotly.Plots.resize('history_graph');

                    history_graph.style.width = '100%';
                }
                if (plot_container && history_map && history_graph) {
                    Plotly.Plots.resize('history_map');
                    Plotly.Plots.resize('history_graph');

                    // Update width
                    history_graph.style.width = '50%';
                    history_map.style.width = '50%';
                }
            }
        });
        if (plot_container) {
            resizeObserver.observe(plot_container);
        }
    }, [ displayStyle ]);

    return (
        <main id="history_site">
            <div id="filters" className="a-accordion a-accordion--open">
                <div className="a-accordion__headline">
                    <div>
                        <div className="a-text-field">
                            <label htmlFor="select_time_start_api">{language.time_period}</label>
                            <input id="select_time_start_api" type="datetime-local" onChange={(event) => setDateFrom(new Date(event.target.value).toISOString())} />
                        </div>
                        <div className="a-text-field">
                            <label htmlFor="select_time_end_api">{language.to}</label>
                            <input
                                id="select_time_end_api"
                                type="datetime-local"
                                onChange={(event) => {
                                    try {
                                        setDateTo(new Date(event.target.value).toISOString());
                                    }
                                    catch (RangeError) {
                                        setDateTo(null);
                                        event.target.value = '';
                                    }
                                }}
                            />
                        </div>
                        <div className="a-dropdown">
                            <label htmlFor="select_car_id">{language.car_id}</label>
                            <select id="select_car_id" onChange={(event) => setCarId(event.target.value)}>
                                {
                                    Object.entries(cars).map(([ car_id, car ]) => (
                                        <option key={car_id} value={car_id}>{car.license_plate}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="a-dropdown">
                            <label htmlFor="select_shown_api">{language.display_type}</label>
                            <select id="select_shown_api" onChange={(event) => setDisplayStyle(event.target.value)}>
                                <option value="graph">{language.graph}</option>
                                <option value="graph_and_map">{language.graph_and_map}</option>
                            </select>
                        </div>
                    </div>
                    <button id="accordion open" type="button" className="a-accordion__headline-button" aria-expanded="false" aria-controls="content headline button accordion open" onClick={(event) => event.target.closest('.a-accordion').classList.toggle('a-accordion--open')}>
                        <i className="a-icon a-accordion__headline-icon boschicon-bosch-ic-down"></i>
                    </button>
                </div>
                <div
                    className="a-accordion__content"
                    id="content accordion open"
                    role="region"
                    aria-labelledby="content accordion open"
                >
                    <div id="filter_measurement_api">
                        <label htmlFor="measurements_input">{language.measurement}*</label>
                        <DropdownMenu id="measurements" setSelected={setSelectedMeasurement} options={measurement} />
                    </div>
                    <div id="filter_field_api">
                        <label htmlFor="fields_input">Field</label>
                        <DropdownMenu id="fields" setSelected={setSelectedField} options={fieldStructure} />
                    </div>
                </div>
                {
                    (errorFilter)
                        ? (
                            <div className="a-notification -error" role="alert">
                                <i className="a-icon ui-ic-alert-error" title="Loren Ipsum"></i>
                                <div id="error_filter" className="a-notification__content">{errorFilter}</div>
                            </div>
                        )
                        : null
                }
                <button id="save_filter" type="button" className="a-button a-button--primary -without-icon"onClick={save_filter}>
                    <span className="a-button__label">{language.show}</span>
                </button>
            </div>

            <div id="data_container">
                {loadingInProcess
                    ? (
                        <div id="loading_field">
                            <BounceLoader />
                        </div>
                    )
                    : (errorServer
                        ? (
                            <p className="error_field">{errorServer.toString()}</p>
                        )
                        : (
                            <div id="plot_container">
                                {(items !== undefined) ? <HistoryGraph items={items} /> : <BounceLoader />}
                                {(displayStyle === 'graph_and_map') ? <HistoryMap items={items} /> : ''}
                            </div>)
                    )}
            </div>

            <Popup className="errorPopup" open={errorPopup} position="center center" arrow="false" closeOnDocumentClick={false}>
                <h3>{language.error_occurred}</h3>
                <hr />
                <div>
                    <p>{language.error_popup_text_1}</p>
                    <p>{language.error_popup_text_2}<a href={requests.addr_database} target="_blank" rel="noreferrer">{requests.addr_database}</a></p>
                    <p>{language.error_popup_text_3}</p>
                </div>
                <button
                    id="close_popup"
                    onClick={() => {
                        setErrorPopup(false);
                        window.location.reload(true);
                    }}
                >{language.close}
                </button>
            </Popup>

        </main>
    );
}
