import React, { useState, useEffect } from 'react';

import Dropdown from '../frok/Components/Dropdown';

import Cars from './Elements/Settings/Cars';
import Dbc from './Elements/Settings/Dbc';
import Fleets from './Elements/Settings/Fleets';
import Models from './Elements/Settings/Models';

import { language_config } from './config/languages';
import { requests } from './config/network';

import UserManagment from './Elements/Settings/UserManagement';
import UserAccount from './Elements/Settings/UserAccount';
import LoginTokens from './Elements/Settings/LoginTokens';
import RequestPhysics from './Elements/Settings/RequestPhysics';

const language = language_config();

/**
 * comonent to render settings site
 * @param {function} handle_theme_change
 * @param {function} handle_language_change
 * @param {function} handle_temperature_change
 * @param {function} handle_length_unit_change
 * @param {boolean} is_dark
 * @returns {html} html for settings site
 */
export default function Settings({ handle_theme_change, handle_language_change, /* handle_temperature_change, handle_length_unit_change, */ is_dark }) {
    const [ users, setUsers ] = useState([]);
    const [ admin, setAdmin ] = useState(false);
    const [ reload, setReload ] = useState(0);
    const [ cars, setCars ] = useState([]);
    const [ models, setModels ] = useState([]);
    const [ fleets, setFleets ] = useState([]);
    const [ dbcFiles, setDbcFiles ] = useState([]);

    /**
     * useEffect triggered by [reload] and reload the user-roles
     */
    useEffect(() => {
        request_role();
        request_cars();
        requestCarModels();
        if (admin === true) {
            requestFleets();
            request_users();
            getDBCFiles();
        }
    }, [ reload, admin ]);

    /**
     * Request of userrole and setting state in 'admin'
     */
    function request_role() {
        const username = localStorage.getItem('username');
        fetch(`${requests.get_user_role}?username=${username}`, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json().then((data) => {
                setAdmin(data.role === 'admin');
            }));
    }

    /**
     * function to request all users
     */
    function request_users() {
        /* API-Request to the Fleet-API - request all User */
        fetch(requests.get_users, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setUsers(data);
            });
    }

    function request_cars() {
        fetch(requests.get_cars, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCars(data);
            });
    }

    function requestCarModels() {
        fetch(requests.get_models, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const carModelsArray = Object.entries(data).map(([ key, value ]) => ({
                    'id': key,
                    'model_name': value.model_name,
                    'weight': value.weight,
                    'manufacturer': value.manufacturer,
                    'year': value.year,
                    'projection_surface': value.projection_surface,
                    'rolling_resistance': value.rolling_resistance,
                    'drag_coefficient': value.drag_coefficient,
                }));

                setModels(carModelsArray);
            });
    }

    function requestFleets() {
        fetch(requests.get_fleets, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const fleetsArray = Object.entries(data).map(([ key, value ]) => ({
                    'id': key,
                    'username': value.username,
                    'car_id': value.car_id,
                }));
                setFleets(fleetsArray);
            });
    }

    function getDBCFiles() {
        fetch(requests.get_dbc_files, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const dbcFilesArray = Object.entries(data).map(([ key, value ]) => ({ 'name': value, 'id': key }));
                setDbcFiles(dbcFilesArray);
            });
    }

    function tabChange(event) {
        const tab = event.target.closest('button').getAttribute('data-tab');
        [ ...document.querySelectorAll('button[data-tab]') ].forEach((element) => {
            element.classList.remove('-selected');
        });
        event.target.closest('button').classList.add('-selected');
        [ ...document.querySelectorAll('div[data-tab]') ].forEach((element) => {
            element.style.display = 'none';
        });
        document.querySelector(`div[data-tab="${tab}"]`).style.display = 'block';
    }

    /* Render different ranges and drop-down-menus */
    return (
        <main id="settings_site">

            <div id="tab_bar" className="a-tab-navigation__wrapper">
                <ol className="a-tab-navigation" role="tablist">
                    <li className="a-tab-navigation__item" role="none">
                        <button type="button" className="a-tab-navigation__tab -selected" role="tab" data-tab="settings" onClick={tabChange}>
                            <span className="a-tab-navigation__tab-content">
                                <span className="a-tab-navigation__label">{language.settings}</span>
                            </span>
                        </button>
                    </li>
                    {
                        (admin === true)
                            ? (
                                <li className="a-tab-navigation__item" role="none">
                                    <button type="button" className="a-tab-navigation__tab" role="tab" data-tab="physics" onClick={tabChange}>
                                        <span className="a-tab-navigation__tab-content">
                                            <span className="a-tab-navigation__label">{language.physics_api}</span>
                                        </span>
                                    </button>
                                </li>
                            )
                            : null
                    }
                    <li className="a-tab-navigation__item" role="none">
                        <button type="button" className="a-tab-navigation__tab" role="tab" data-tab="cars" onClick={tabChange}>
                            <span className="a-tab-navigation__tab-content">
                                <span className="a-tab-navigation__label">{language.cars}</span>
                            </span>
                        </button>
                    </li>
                    <li className="a-tab-navigation__item" role="none">
                        <button type="button" className="a-tab-navigation__tab" role="tab" data-tab="account" onClick={tabChange}>
                            <span className="a-tab-navigation__tab-content">
                                <span className="a-tab-navigation__label">{language.account}</span>
                            </span>
                        </button>
                    </li>
                </ol>
            </div>

            <div id="tabs">

                <div data-tab="settings">
                    <section id="theme_settings">
                        <h4>{language.themes}</h4>
                        <div className="a-toggle">
                            <label htmlFor="mode">
                                <span className="a-toggle__label a-toggle__label--left">Light mode</span>
                                <input id="mode" type="checkbox" role="switch" defaultChecked={is_dark} onChange={handle_theme_change} />
                                <div className="a-toggle__trigger"></div>
                                <span className="a-toggle__label a-toggle__label--right">Dark mode</span>
                            </label>
                        </div>
                    </section>

                    <section id="select_settings">
                        <h4>{language.settings}</h4>
                        <div id="select_buttons">
                            <Dropdown
                                id="language_select"
                                label={language.language}
                                defaultValue={JSON.parse(localStorage.getItem('language'))}
                                onChange={handle_language_change}
                            >
                                <option value="english">English</option>
                                <option value="german">Deutsch</option>
                                <option value="french">Français</option>
                            </Dropdown>
                            {/*
                            // TODO remove or implement correctly
                            <div className="settings_field">
                                <label><h5>{language.length_unit}</h5></label>
                                <select id="distance_select" defaultValue={JSON.parse(localStorage.getItem('length_unit'))} onChange={handle_length_unit_change}>
                                    <option value="kilometer">{language.kilometer}</option>
                                    <option value="miles">Miles</option>
                                </select>
                            </div>
                            <div className="settings_field">
                                <label><h5>{language.temperature}</h5></label>
                                <select id="temperature_select" defaultValue={JSON.parse(localStorage.getItem('temperature'))} onChange={handle_temperature_change}>
                                    <option value="celsius">Celsius</option>
                                    <option value="fahrenheit">Fahrenheit</option>
                                </select>
                            </div>
                            */}
                        </div>
                    </section>
                </div>

                {
                    (admin === true)
                        ? (
                            <div data-tab="physics">
                                <section id="physics_settings">
                                    <RequestPhysics admin={admin} setReload={setReload} reload={reload} />
                                </section>
                            </div>
                        )
                        : null
                }

                <div data-tab="cars">
                    <section id="vehicle_settings">
                        <Cars admin={admin} setReload={setReload} reload={reload} models={models} cars={cars} />
                        {
                            (admin === true)
                                ? (
                                    <>
                                        <Models admin={admin} reload={reload} setReload={setReload} models={models} />
                                        <Fleets admin={admin} reload={reload} setReload={setReload} users={users} cars={cars} fleets={fleets} />
                                        <Dbc admin={admin} setReload={setReload} reload={reload} dbcFiles={dbcFiles} setDbcFiles={setDbcFiles} />
                                    </>
                                )
                                : null
                        }
                    </section>
                </div>

                <div data-tab="account">
                    <section id="account_settings">
                        <div>
                            {
                                (admin === true)
                                    ? (
                                        <>
                                            <UserManagment setReload={setReload} reload={reload} users={users} />
                                            <LoginTokens reload={reload} setReload={setReload} users={users} />
                                        </>
                                    )
                                    : null
                            }
                            <UserAccount admin={admin} />
                        </div>
                    </section>
                </div>

            </div>
        </main>
    );
}
