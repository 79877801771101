import { topics, safehomemode_state } from '../RealtimeData/SSE_index';

export function initialize_safehomemode_state() {
    safehomemode_state.counter = 0;

    safehomemode_state.measurements = {};
    topics.filter((t) => (t.type === 'safehomemode'))
        .flatMap((t) => t.measurements)
        .forEach((t) => {
            safehomemode_state.measurements[t] = {
                'value': null,
                'timestamp': 0,
            };
        });
}
