import React, { useState } from 'react';
import SignalDisplayRow from '../../components/SignalDisplayRow/SignalDisplayRow';
import TimeDisplay from '../../components/TimeDisplay';
import NoData from '../../../NoData/NoData';
import './ResultTab.scss';
import { ReactComponent as RangeIcon } from '../../../../../images/shm/Range.svg';
import { ReactComponent as RoadIcon } from '../../../../../images/shm/Road.svg';
import { ReactComponent as EnergyIcon } from '../../../../../images/shm/Energy.svg';

/**
 * Display Safe home mode result tab
 */
export default function ResultsTab() {
    const [ referenceShowcase, setReferenceShowcase ] = useState(JSON.parse(localStorage.getItem('last-reference-show-case')));
    const [ shmShowcase, setShmShowcase ] = useState(JSON.parse(localStorage.getItem('last-shm-show-case')));
    const [ autoShowcase, setAutoShowcase ] = useState(JSON.parse(localStorage.getItem('last-auto-mode-show-case')));

    function handleReset(setterCallback, localstorageKey) {
        setterCallback(null);
        localStorage.removeItem(localstorageKey);
    }

    return (
        <div id="shm">
            <ShowcaseResultCard
                title="Reference"
                showcase={referenceShowcase}
                noDataTitle="No previous reference showcase"
                onClickReset={() => handleReset(setReferenceShowcase, 'last-reference-show-case')}
            />
            <ShowcaseResultCard
                title="Safe home mode"
                showcase={shmShowcase}
                hasBenefit={true}
                reference_param_Consumption={referenceShowcase?.param_Consumption}
                noDataTitle="No previous safe home mode showcase"
                onClickReset={() => handleReset(setShmShowcase, 'last-shm-show-case')}
            />
            <ShowcaseResultCard
                title="Automatic mode"
                showcase={autoShowcase}
                hasBenefit={true}
                reference_param_Consumption={referenceShowcase?.param_Consumption}
                noDataTitle="No previous automatic mode showcase"
                onClickReset={() => handleReset(setAutoShowcase, 'last-auto-mode-show-case')}
            />
        </div>
    );
}

function round(number) {
    if (number === null || number === undefined) {
        return number;
    }

    return parseFloat(number.toFixed(1));
}

/**
 * Display Showcase card and fetch data from localstorage
 */
function ShowcaseResultCard({ title, showcase, reference_param_Consumption, hasBenefit, noDataTitle, onClickReset }) {
    return (
        <div className="a-box -floating-shadow-s side info-card showcase-card show-case-result-card">
            {showcase
                ? (<ShowcaseResultCardContent
                    title={title}
                    showcase={showcase}
                    reference_param_Consumption={reference_param_Consumption}
                    hasBenefit={hasBenefit}
                    onClickReset={onClickReset}
                />)
                : <NoData title={noDataTitle} />}
        </div>
    );
}

function ShowcaseResultCardContent({ title, showcase, hasBenefit, reference_param_Consumption, onClickReset }) {
    const range_Motion = showcase.param_Consumption
        ? showcase.param_EnergyMotion / showcase.param_Consumption / 100
        : 0;

    const range_Thermal = showcase.param_Consumption
        ? showcase.param_EnergyThermal / showcase.param_Consumption / 100
        : 0;

    const overall_Range_Benefit = (showcase.param_Consumption && reference_param_Consumption)
        ? Math.abs(100 * ((showcase.param_Consumption - reference_param_Consumption) / reference_param_Consumption))
        : null;

    return (
        <>
            <div className="showcase-header">
                <span className="showcase-header-title">{title} {showcase?.shmMode}</span>
                <ResetButton onClick={onClickReset} />
            </div>
            <ResultShowcaseBlock Icon={RoadIcon} title="Travel">
                <SignalDisplayRow name="km distance">{round(showcase.param_TravelDistance)}</SignalDisplayRow>
                <SignalDisplayRow name={`:${String(showcase.param_TravelTime % 60).padStart(2, '0')} duration`}><TimeDisplay seconds={showcase.param_TravelTime} /></SignalDisplayRow>
            </ResultShowcaseBlock>

            <ResultShowcaseBlock Icon={EnergyIcon} title="Energy">
                <SignalDisplayRow name="kWh/100km "> {round(showcase.param_Consumption) || 0}</SignalDisplayRow>
                <SignalDisplayRow name="kWh overall">{round(showcase.overallConsumedEnergy)}</SignalDisplayRow>
                <SignalDisplayRow name="Wh thermal">{round(showcase.param_EnergyThermal) || 0}</SignalDisplayRow>
                <SignalDisplayRow name="Wh motion">{round(showcase.param_EnergyMotion) || 0}</SignalDisplayRow>
            </ResultShowcaseBlock>

            <ResultShowcaseBlock Icon={RangeIcon} title="Range">
                <SignalDisplayRow name="km motion">{round(range_Motion)}</SignalDisplayRow>
                <SignalDisplayRow name="km thermal">{round(range_Thermal)}</SignalDisplayRow>
                {hasBenefit ? <SignalDisplayRow name="% benefit" dark={true}>{round(overall_Range_Benefit) || '-'}</SignalDisplayRow> : null}
            </ResultShowcaseBlock>
        </>
    );
}

function ResultShowcaseBlock({ Icon, title, children }) {
    return (
        <div className="result-showcase-block">
            <div className="showcase-block-title">
                <Icon />
                <h3 className="show-case-title">{title}</h3>
            </div>
            <div className="showcase-block-content">
                {children}
            </div>
        </div>
    );
}

function ResetButton({ onClick }) {
    return (
        <button type="button" className="a-button a-button--primary reset-button" onClick={onClick}>
            <i className="a-icon a-button__icon boschicon-bosch-ic-reset" title="emoji-reset"></i>
            <span className="a-button__label">Reset</span>
        </button>
    );
}
