import styled from 'styled-components';

export const LastMileModeWrapper = styled.div`
    input[type=range] {
        &::-webkit-slider-thumb {
            z-index: 1;
        }
        &::-moz-range-thumb {
            z-index: 1;
        }
    }

    .vertical-layout {
        width: 100%;
    }

    .content {
        & > * {
            margin-bottom: 1rem;
        }
    }

    .a-button__label {
        white-space: nowrap;
    }

    .a-slider.a-slider--labels-on-top > label:first-child {
        text-align: left;
    }

    .dialog {

        .a-box {
            background-color: #ffffff;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }

    .notification-with-title {

        height: 6rem !important;
        top: calc(100vh - 6rem) !important;

        h5 {
            margin-top: 0.125rem;
            margin-bottom: 0.125rem;
        }

        .a-icon {
            margin-top: 1.5rem !important;
        }
    }

    .start-btn {
        position: relative;
        .start-btn-spinner {
            position: absolute;
            top: 4px;
            left: 4px;
        }
    }
`;
