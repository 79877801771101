import React, { useEffect } from 'react';

import request_sse, { set_topics, topics, tmm_state } from './RealtimeData/SSE_index';

import { ReactComponent as Thermo } from '../images/svg/tmm.svg';

/**
 * Check for existence of element by ID
 * @param {string} id ID of the tested html-object
 * @returns boolean: Element exists or not
 */
function exists(id) {
    return document.getElementById(id);
}

/**
 * Show element
 * @param {string} id Element ID
 */
function show(id) {
    document.querySelector(`#${id}`).style.display = 'block';
}

/**
 * Hide element
 * @param {string} id Element ID
 */
function hide(id) {
    document.querySelector(`#${id}`).style.display = 'none';
}

/**
 * Add moving line animation
 * @param {string} id Element ID
 */
function move_line(id) {
    document.querySelector(`#${id}`).classList.add('moving_line');
}

/**
 * Remove moving line animation
 * @param {string} id Element ID
 */
function stop_line(id) {
    document.querySelector(`#${id}`).classList.remove('moving_line');
}

/**
 * Turn on pump animation
 * @param {string} id Element ID
 */
function pump_on(id) {
    document.querySelector(`#${id}`).classList.add('pumping');
}

/**
 * Turn off pump animation
 * @param {string} id Element ID
 */
function pump_off(id) {
    document.querySelector(`#${id}`).classList.remove('pumping');
}

/**
 * Turn on fan rotation
 * @param {string} id Element ID
 */
function fan_on(id) {
    document.querySelector(`#${id}`).classList.add('rotating_fan');
}

/**
 * Turn off fan rotation
 * @param {string} id Element ID
 */
function fan_off(id) {
    document.querySelector(`#${id}`).classList.remove('rotating_fan');
}

/**
 * Check if the state is complete
 * @param {object} state TMM state object
 * @returns boolean: state contains all required variables or not
 */
export function is_state_complete(state) {
    if ((typeof state.measurements.Cnv_stThmm.value) !== 'object' || state.measurements.Cnv_stThmm.value === null) {
        return false;
    }

    // TODO more checks

    return true;
}

/**
 * Check if the signals are up to date
 * @param {object} state TMM state object
 * @returns boolean: all state variables are fresh or not
 */
export function is_state_fresh(state) {
    // TODO do the checks
    state;
    return true;
}

/**
 * Display current state in SVG
 */
export function draw_tmm(state) {
    const value = (variable) => state.measurements[variable].value;

    if (!exists('TMM_SVG')) {
        return;
    }

    if (!is_state_complete(state)) {
        console.warn('TMM state is not complete.');
        return;
    }

    if (!is_state_fresh(state)) {
        console.warn('TMM state is not up to date.');
        return;
    }

    /**
     * refrigerant circuit
     */
    const refrigerant_pump_on = (value('VCU_eCompSpeed') > 0);

    if (value('Cnv_stThmm').EvaporatorValve === true) {
        show('valve_RV2_active');
        hide('valve_RV2_inactive');
        if (refrigerant_pump_on) {
            move_line('line_evaporator');
            fan_on('fan_blower');
        }
        else {
            stop_line('line_evaporator');
            fan_off('fan_blower');
        }
    }
    else {
        show('valve_RV2_inactive');
        hide('valve_RV2_active');
        stop_line('line_evaporator');
        fan_off('fan_blower');
    }

    if (value('Cnv_stThmm').ChillerValve === true) {
        show('valve_RV1_active');
        hide('valve_RV1_inactive');
        if (refrigerant_pump_on) {
            move_line('line_refrigerant_chiller');
        }
        else {
            stop_line('line_refrigerant_chiller');
        }
    }
    else {
        show('valve_RV1_inactive');
        hide('valve_RV1_active');
        stop_line('line_refrigerant_chiller');
    }

    if (refrigerant_pump_on) {
        pump_on('pump_refrigerant');
        if (value('Cnv_stThmm').EvaporatorValve === true || value('Cnv_stThmm').ChillerValve === true) {
            move_line('line_refrigerant');
        }
        else {
            stop_line('line_refrigerant');
        }
    }
    else {
        pump_off('pump_refrigerant');
        stop_line('line_refrigerant');
    }

    if (value('Cnv_stThmm').LTFan === true) {
        fan_on('fan_LT');
    }
    else {
        fan_off('fan_LT');
    }

    /**
     * LT circuit
     */
    const LT_flow = (value('VCU_flowWtrHVBatt_F2') > 0);

    if (LT_flow) {
        move_line('line_LT');
        pump_on('pump_LT');
    }
    else {
        stop_line('line_LT');
        pump_off('pump_LT');
    }

    /**
     * MT circuit
     */
    const MT_flow = (value('VCU_flowWtrINV2_F3') > 0);

    if (MT_flow) {
        move_line('line_MT');
        pump_on('pump_MT');
    }
    else {
        stop_line('line_MT');
        pump_off('pump_MT');
    }

    if (value('Cnv_stThmm').GeneratorCoolantValveActive === true) {
        show('valve_CV2_active');
        hide('valve_CV2_inactive');
        if (MT_flow && value('VCU_flowWtrINV1_F1') > 0) {
            move_line('line_MT_generator');
        }
        else {
            stop_line('line_MT_generator');
        }
    }
    else {
        show('valve_CV2_inactive');
        hide('valve_CV2_active');
        stop_line('line_MT_generator');
    }

    if (value('Cnv_stThmm').MTRadiatorBypassActive === true) {
        show('valve_MT_radiator_bypass_active');
        hide('valve_MT_radiator_bypass_inactive');
        if (MT_flow) {
            move_line('line_MT_radiator_bypass');
        }
        stop_line('line_MT_radiator');
    }
    else {
        show('valve_MT_radiator_bypass_inactive');
        hide('valve_MT_radiator_bypass_active');
        if (MT_flow) {
            move_line('line_MT_radiator');
        }
        stop_line('line_MT_radiator_bypass');
    }

    const MT_fan_state = value('Cnv_stThmm').MTFanState;
    if (MT_fan_state > 0) {
        fan_on('fan_MT');
        if (MT_fan_state === 1) {
            document.getElementById('fan_MT_bar_1').style.fill = '#000';
            document.getElementById('fan_MT_bar_2').style.fill = 'none';
            document.getElementById('fan_MT_bar_3').style.fill = 'none';
        }
        else if (MT_fan_state === 2) {
            document.getElementById('fan_MT_bar_1').style.fill = '#000';
            document.getElementById('fan_MT_bar_2').style.fill = '#000';
            document.getElementById('fan_MT_bar_3').style.fill = 'none';
        }
        else if (MT_fan_state === 3) {
            document.getElementById('fan_MT_bar_1').style.fill = '#000';
            document.getElementById('fan_MT_bar_2').style.fill = '#000';
            document.getElementById('fan_MT_bar_3').style.fill = '#000';
        }
    }
    else {
        fan_off('fan_MT');
        document.getElementById('fan_MT_bar_1').style.fill = 'none';
        document.getElementById('fan_MT_bar_2').style.fill = 'none';
        document.getElementById('fan_MT_bar_3').style.fill = 'none';
    }

    /**
     * LT-MT valve
     */
    if (value('Cnv_stThmm').LTMTConnected === true) {
        show('valve_LTMT_connected');
        hide('valve_LTMT_separated');
    }
    else {
        show('valve_LTMT_separated');
        hide('valve_LTMT_connected');
    }

    /**
     * measurements
     */
    for (const measurement in state.measurements) {
        const textElem = exists(`text_${measurement}`);
        if (textElem) {
            const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
            let value = state.measurements[measurement].value;
            if (value === undefined || value === null) {
                value = '?';
            }
            textElem.innerHTML = `${value}${unit}`;
        }
    }
}

// TMM state
export let tmm_update_timer = null;

export function initialize_tmm_state() {
    tmm_state.counter = 0;

    tmm_state.measurements = {};
    topics.filter((t) => t.type === 'tmm')
        .flatMap((t) => t.measurements)
        .forEach((t) => {
            tmm_state.measurements[t] = {
                'value': null,
                'timestamp': 0,
            };
        });
}

export function initialize_tmm_timer() {
    clearInterval(tmm_update_timer);

    tmm_update_timer = setInterval(() => {
        draw_tmm(tmm_state);
        tmm_state.counter += 1;
    }, 1000);
}

/**
 * Page to show SVGs and Animations
 */
export default function TMM() {
    /**
     * useEffect to request and activate realtime data
     */
    useEffect(() => {
        set_topics();
        initialize_tmm_state();
        initialize_tmm_timer();
        request_sse();
    }, []);

    return (
        <main id="tmm_page" className="no_padding svg">
            <Thermo />
        </main>
    );
}
