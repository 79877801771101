import React, { lazy, Suspense } from 'react';

const LazySTMResult = lazy(() => import('./STMResult'));

const STMResult = (props) => (
    <Suspense fallback={null}>
        <LazySTMResult {...props} />
    </Suspense>
);

export default STMResult;
