import React from 'react';
import './NoData.scss'; //

export default function NoData({ title = 'No data available', icon = 'history', large = false }) {
    return (
        <div className={`no-data-container ${large && 'large'}`}>
            <i className={`a-icon boschicon-bosch-ic-${icon} no-data-icon`} />
            <p className="no-data-text">{title}</p>
        </div>
    );
}

