/* eslint-disable no-multi-spaces */
const IP_SERVER             = process.env.REACT_APP_SERVER_ADDRESS;
const ADDR_FLEET_USER_API   = `${IP_SERVER}/fleet-user`;
const ADDR_HIST_API         = `${IP_SERVER}/mobility-data`;
const ADDR_PHYSICS_API      = `${IP_SERVER}/physics`;
const ADDR_SIMULATOR        = `${IP_SERVER}/simulator`;
const ADDR_USERINT_API      = `${IP_SERVER}/user-interaction`;
const DB_NAME               = process.env.REACT_APP_DB_NAME;
/* eslint-enable no-multi-spaces */

/* eslint-disable key-spacing */
/**
 * function to save API-Request-Adresses
 * used in functions, where API-Requests are called
 */
export const requests = {
    /* Database Requests */
    'addr_database':        `https://${ADDR_HIST_API}/influx/read?database=${DB_NAME}`,
    'db_structure':         `https://${ADDR_HIST_API}/influx/structure?database=${DB_NAME}`,

    /* General Purpose API Requests */
    'simulator_status':     `https://${ADDR_SIMULATOR}/simulator?action=status`,
    'simulator_start':      `https://${ADDR_SIMULATOR}/simulator?action=start`,
    'simulator_stop':       `https://${ADDR_SIMULATOR}/simulator?action=stop`,

    /* User-Interaction API Requests */
    'user_interaction_cvem_shm':                 `https://${ADDR_USERINT_API}/cvem/shm`,
    'user_interaction_cvem_lmm':                 `https://${ADDR_USERINT_API}/cvem/lmm`,
    'user_interaction_cvem_stm':                 `https://${ADDR_USERINT_API}/cvem/stm`,

    /* Dashboard-Server-Requests */
    'listen':               `https://${IP_SERVER}/listen`,

    /* User API Requests */
    'validate_token':       `https://${ADDR_FLEET_USER_API}/validate-token`,
    'login':                `https://${ADDR_FLEET_USER_API}/login`,
    'login_token':          `https://${ADDR_FLEET_USER_API}/login-token`,
    'create_user':          `https://${ADDR_FLEET_USER_API}/create-user`,
    'create_login_token':   `https://${ADDR_FLEET_USER_API}/create-login-token`,
    'get_users':            `https://${ADDR_FLEET_USER_API}/get-users`,
    'get_user_role':        `https://${ADDR_FLEET_USER_API}/get-user-role`,
    'get_login_tokens':     `https://${ADDR_FLEET_USER_API}/get-login-tokens`,
    'set_auth_status':      `https://${ADDR_FLEET_USER_API}/set-auth-status`,
    'delete_user':          `https://${ADDR_FLEET_USER_API}/delete-user`,
    'delete_login_token':   `https://${ADDR_FLEET_USER_API}/delete-login-token`,
    'set_role':             `https://${ADDR_FLEET_USER_API}/set-role`,
    'reset_password':       `https://${ADDR_FLEET_USER_API}/reset-pw`,
    'change_password':      `https://${ADDR_FLEET_USER_API}/change-pw`,

    /* Fleet API Requests */
    'create_model':         `https://${ADDR_FLEET_USER_API}/create-model`,
    'create_car':           `https://${ADDR_FLEET_USER_API}/create-car`,
    'create_fleet':         `https://${ADDR_FLEET_USER_API}/create-fleet`,
    'get_cars_from_user':   `https://${ADDR_FLEET_USER_API}/get-cars-from-user`,
    'get_model_from_id':    `https://${ADDR_FLEET_USER_API}/get-model-from-id`,
    'get_cars':             `https://${ADDR_FLEET_USER_API}/get-cars`,
    'get_models':           `https://${ADDR_FLEET_USER_API}/get-models`,
    'get_fleets':           `https://${ADDR_FLEET_USER_API}/get-fleets`,
    'delete_car':           `https://${ADDR_FLEET_USER_API}/delete-car`,
    'delete_model':         `https://${ADDR_FLEET_USER_API}/delete-model`,
    'delete_fleet':         `https://${ADDR_FLEET_USER_API}/delete-fleet`,
    'get_dbc_files':        `https://${ADDR_FLEET_USER_API}/get-dbc-files`,
    'delete_dbc_file':      `https://${ADDR_FLEET_USER_API}/delete-dbc-file`,
    'upload_dbc_file':      `https://${ADDR_FLEET_USER_API}/upload-dbc-file`,
    'modify_model':         `https://${ADDR_FLEET_USER_API}/modify-model`,
    'modify_car':         `https://${ADDR_FLEET_USER_API}/modify-car`,

    /**
     * Physik API Requests
     *  - get all constants: GET /constants
     *  - get one constant: GET /constants/foo
     *  - put one constant: PUT /constants/foo
     */
    'constants':            `https://${ADDR_PHYSICS_API}/constants`,
    'react_client':         `wss://${IP_SERVER}/mqtt`,

};
/* eslint-enable key-spacing */

/**
 * parsing a response to JSON if it is in JSON format, otherwise print Error-Request
 */
export const parseJson = async (response) => {
    const text = await response.text();
    try {
        const json = JSON.parse(text);
        return json;
    }
    catch (err) {
        throw new Error(`${response.status} ${response.statusText}`);
    }
};
