import React, { useState } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import Input from '../../../frok/Components/Input';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Component to create a new car on the settings page
 *
 * @param {boolean} admin props, if user is admin
 * @param {int} reload trigger for reloading the page
 * @param {function} setReload function to set reload
 * @param {Object} models Object which contains the models
 * @returns {html} Button to create a new car
 */
export default function Models({ admin, reload, setReload, models }) {
    const [ openModel, setOpenModel ] = useState({});
    const [ openModelId, setOpenModelId ] = useState(null);

    const [ modelNotificationBarText, setModelNotificationBarText ] = useState('');
    const [ modelNotificationBarSuccess, setModelNotificationBarSuccess ] = useState(false);

    const [ openDeletePopup, setOpenDeletePopup ] = useState(null);

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    /**
     * function to create a new Model
     */
    function createModel() {
        /* Check if all fields are filled */
        if (Object.values(openModel).some((value) => value === '')) {
            setModelNotificationBarSuccess(false);
            setModelNotificationBarText(language.error_fields_not_filled);
            return;
        }
        else {
            setModelNotificationBarText('');
        }

        /* Set Request-Header for Model */
        const request_body = JSON.stringify({
            'weight': openModel.weight,
            'model_name': openModel.model_name,
            'manufacturer': openModel.manufacturer,
            'year': openModel.year,
            'projection_surface': openModel.projection_surface,
            'rolling_resistance': openModel.rolling_resistance,
            'drag_coefficient': openModel.drag_coefficient,
        });

        /* Request creation of a new model */
        fetch(requests.create_model, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setReload(reload + 1);
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.new_model);
                    setFetchResponse('Model successfully created!');
                    setOpenModelId(null);
                }
                else {
                    setModelNotificationBarSuccess(false);
                    setModelNotificationBarText('Model could not be created!');
                }
            })
            .catch((error) => {
                setModelNotificationBarSuccess(false);
                setModelNotificationBarText(`${language.error_occurred}: ${error}`);
            });
    }

    /**
     * @param {int} model_id Id of the model to be deleted
     */
    function deleteModel(model_id) {
        if (model_id === null) {
            setFetchResponseSuccess(false);
            setFetchResponseTitle('Error');
            setFetchResponse('This model does not exist!');
            setOpenDeletePopup(null);
        }

        const request_body = JSON.stringify({ 'model_id': model_id });

        fetch(requests.delete_model, {
            'method': 'DELETE',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setReload(reload + 1);
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.delete_model);
                    setFetchResponse('Model successfully deleted!');
                    setOpenDeletePopup(null);
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.delete_model);
                    setFetchResponse('Error! This model is used by a car!');
                    setOpenDeletePopup(null);
                }
            })
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.delete_model);
                setFetchResponse(error);
                setOpenDeletePopup(null);
            });
    }

    function modifyModel(model_id) {
        /* Check if all fields are filled */
        if (Object.values(openModel).some((value) => value === '')) {
            setModelNotificationBarSuccess(false);
            setModelNotificationBarText(language.error_fields_not_filled);
            return;
        }
        else {
            setModelNotificationBarText('');
        }

        if (model_id === null) {
            setModelNotificationBarSuccess(false);
            setModelNotificationBarText('Error! This model does not exist!');
        }

        const request_body = JSON.stringify({
            'model_id': model_id,
            'weight': openModel.weight,
            'model_name': openModel.model_name,
            'manufacturer': openModel.manufacturer,
            'year': openModel.year,
            'projection_surface': openModel.projection_surface,
            'rolling_resistance': openModel.rolling_resistance,
            'drag_coefficient': openModel.drag_coefficient,
        });

        fetch(requests.modify_model, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setModelNotificationBarSuccess(true);
                    setModelNotificationBarText('Model successfully modified!');
                    setReload(reload + 1);
                }
                else {
                    setModelNotificationBarSuccess(false);
                    setModelNotificationBarText('Model could not be modified!');
                }
            })
            .catch((error) => {
                setModelNotificationBarSuccess(false);
                setModelNotificationBarText(`${language.error_occurred}: ${error}`);
            });
    }

    function handleModelChange() {
        if (openModelId === -1) {
            createModel();
        }
        else {
            modifyModel(openModel.id);
        }
    }

    function handleModelClose() {
        setOpenModelId(null);
        setModelNotificationBarText('');
    }

    /**
     * called, if a value in the popup newModel is changed
     */

    function onChangeNewModel(event) {
        const { name, value } = event.target;
        setOpenModel({ ...openModel, [name]: value });
    }

    // don't display any models, if user is not an admin
    if (!admin) {
        return null;
    }

    return (
        <>
            <div id="model_settings" className="settings_block">
                <h4>{language.models}</h4>
                <table className="m-table">
                    <thead>
                        <tr>
                            <th>{language.id}</th>
                            <th>{language.model_name}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (models.length > 0)
                                ? (
                                    models.map((model) => (
                                        <tr key={model.id}>
                                            <td>{model.id}</td>
                                            <td>{model.model_name}</td>
                                            <td>
                                                <Button
                                                    icon="delete"
                                                    onClick={() => setOpenDeletePopup(model.id)}
                                                >
                                                    {language.delete}
                                                </Button>
                                                <Button
                                                    icon="info-i-frame"
                                                    secondary
                                                    onClick={() => {
                                                        setOpenModel(model);
                                                        setOpenModelId(model.id);
                                                    }}
                                                >
                                                    {language.info}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )
                                : <tr><th colSpan="3"><i>No models.</i></th></tr>
                        }
                    </tbody>
                </table>

                <p>
                    <Button
                        icon="add"
                        onClick={() => {
                            setOpenModel({
                                'weight': '',
                                'model_name': '',
                                'manufacturer': '',
                                'year': '',
                                'projection_surface': '',
                                'rolling_resistance': '',
                                'drag_coefficient': '',
                            });
                            setOpenModelId(-1);
                            setModelNotificationBarText('');
                        }}
                    >
                        {language.new_model}
                    </Button>
                </p>
            </div>

            <Dialog
                open={Boolean(openModelId)}
                header={(openModelId === -1) ? language.new_model : language.model}
                button1={(
                    <Button
                        onClick={handleModelChange}
                    >
                        {
                            (openModelId === -1)
                                ? language.save
                                : language.edit
                        }
                    </Button>
                )}
                close={handleModelClose}
            >
                <div className="m-form-field">
                    {
                        (openModelId !== -1)
                            ? (
                                <div className="m-form-field">
                                    <Input label={language.id} name="model_id" defaultValue={openModelId} readOnly />
                                </div>
                            )
                            : null
                    }
                    <div className="m-form-field">
                        <Input label={`${language.weight} *`} type="number" min="1" max="99999" name="weight" defaultValue={openModel.weight} onChange={onChangeNewModel} />
                    </div>
                    <div className="m-form-field">
                        <Input label={`${language.year} *`} type="number" min="1900" max="2100" step="1" name="year" defaultValue={openModel.year} onChange={onChangeNewModel} />
                    </div>
                    <div className="m-form-field">
                        <Input label={`${language.model_name} *`} type="text" name="model_name" defaultValue={openModel.model_name} onChange={onChangeNewModel} />
                    </div>
                    <div className="m-form-field">
                        <Input label={`${language.manufacturer} *`} type="text" name="manufacturer" defaultValue={openModel.manufacturer} onChange={onChangeNewModel} />
                    </div>
                    <div className="m-form-field">
                        <Input label={`${language.cross_section_area} *`} type="number" step="0.001" name="projection_surface" defaultValue={openModel.projection_surface} onChange={onChangeNewModel} />
                    </div>
                    <div className="m-form-field">
                        <Input label={`${language.rolling_resistance} *`} type="number" step="0.001" name="rolling_resistance" defaultValue={openModel.rolling_resistance} onChange={onChangeNewModel} />
                    </div>
                    <div className="m-form-field">
                        <Input label={`${language.drag_coefficient} *`} type="number" step="0.001" name="drag_coefficient" defaultValue={openModel.drag_coefficient} onChange={onChangeNewModel} />
                    </div>

                    <NotificationBar type={modelNotificationBarSuccess ? 'success' : 'error'}>{modelNotificationBarText}</NotificationBar>
                </div>
            </Dialog>

            <Dialog
                open={(openDeletePopup !== null)}
                header={language.delete_model}
                level="warning"
                button1={(<Button icon="delete" onClick={() => deleteModel(openDeletePopup)}>{language.delete}</Button>)}
                button2={(<Button icon="close" secondary onClick={() => setOpenDeletePopup(null)}>{language.cancel}</Button>)}
            >
                {language.delete_model_text}
            </Dialog>

            <Dialog
                header={fetchResponseTitle}
                level={fetchResponseSuccess ? 'success' : 'error'}
                button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                open={fetchResponse.length > 0}
            >
                {fetchResponse}
            </Dialog>
        </>
    );
}
