/*
 * Set default layouts for react-grid-layout
 * - const: default_layouts
 */

/**
 * set default layout for main page
 */
export const default_layouts = {
    'lg': [
        { 'i': '1', 'x': 0, 'y': 0, 'w': 4, 'h': 10 },
        { 'i': '2', 'x': 4, 'y': 0, 'w': 4, 'h': 10 },
        { 'i': '3', 'x': 0, 'y': 10, 'w': 4, 'h': 10 },
        { 'i': '4', 'x': 4, 'y': 10, 'w': 4, 'h': 10 },
        { 'i': '5', 'x': 0, 'y': 20, 'w': 4, 'h': 10 },
        { 'i': '6', 'x': 4, 'y': 20, 'w': 4, 'h': 10 },
        { 'i': '7', 'x': 8, 'y': 0, 'w': 4, 'h': 28 },
        { 'i': '8', 'x': 0, 'y': 30, 'w': 4, 'h': 12 },
        { 'i': '9', 'x': 4, 'y': 30, 'w': 4, 'h': 12 },
        { 'i': '10', 'x': 0, 'y': 40, 'w': 4, 'h': 12 },
        { 'i': '11', 'x': 4, 'y': 40, 'w': 4, 'h': 12 },
    ],
    's': [
        { 'i': '1', 'x': 0, 'y': 0, 'w': 1, 'h': 4 },
        { 'i': '2', 'x': 0, 'y': 4, 'w': 1, 'h': 4 },
        { 'i': '3', 'x': 0, 'y': 8, 'w': 1, 'h': 4 },
        { 'i': '4', 'x': 0, 'y': 12, 'w': 1, 'h': 4 },
        { 'i': '5', 'x': 0, 'y': 16, 'w': 1, 'h': 4 },
        { 'i': '6', 'x': 0, 'y': 20, 'w': 1, 'h': 4 },
        { 'i': '7', 'x': 0, 'y': 24, 'w': 1, 'h': 4 },
        { 'i': '8', 'x': 0, 'y': 28, 'w': 1, 'h': 4 },
        { 'i': '9', 'x': 0, 'y': 32, 'w': 1, 'h': 4 },
        { 'i': '10', 'x': 0, 'y': 36, 'w': 1, 'h': 4 },
        { 'i': '11', 'x': 0, 'y': 40, 'w': 1, 'h': 4 },
    ],
};

/**
 * set default layout for prediction page
 */
export const prediction_layouts = {
    'lg': [
        { 'i': '1', 'x': 0, 'y': 0, 'w': 4, 'h': 14 },
        { 'i': '2', 'x': 4, 'y': 0, 'w': 4, 'h': 14 },
        { 'i': '3', 'x': 8, 'y': 0, 'w': 4, 'h': 14 },
        { 'i': '4', 'x': 0, 'y': 14, 'w': 4, 'h': 14 },
        { 'i': '5', 'x': 4, 'y': 14, 'w': 4, 'h': 14 },
        { 'i': '6', 'x': 8, 'y': 14, 'w': 4, 'h': 14 },
    ],
    's': [
        { 'i': '1', 'x': 0, 'y': 0, 'w': 1, 'h': 4 },
        { 'i': '2', 'x': 0, 'y': 4, 'w': 1, 'h': 4 },
        { 'i': '3', 'x': 0, 'y': 8, 'w': 1, 'h': 4 },
        { 'i': '4', 'x': 0, 'y': 12, 'w': 1, 'h': 4 },
        { 'i': '5', 'x': 0, 'y': 16, 'w': 1, 'h': 4 },
        { 'i': '6', 'x': 0, 'y': 20, 'w': 1, 'h': 4 },
    ],
};

export const tmm_layouts = {
    'lg': [ { 'i': '1', 'x': 0, 'y': 0, 'w': 12, 'h': 28 } ],
    's': [ { 'i': '1', 'x': 0, 'y': 0, 'w': 1, 'h': 4 } ],
};

export const hvpowernet_layouts = {
    'lg': [ { 'i': '1', 'x': 0, 'y': 0, 'w': 12, 'h': 28 } ],
    's': [ { 'i': '1', 'x': 0, 'y': 0, 'w': 1, 'h': 4 } ],
};

export const thermal_management_layouts = {
    'lg': [ { 'i': '1', 'x': 0, 'y': 0, 'w': 12, 'h': 28 } ],
    's': [ { 'i': '1', 'x': 0, 'y': 0, 'w': 1, 'h': 4 } ],
};
