import React, { useEffect, useState } from 'react';
import OverflowSlider from '../OverflowSlider/OverflowSlider';
import { Box, Toggle } from '@bosch/react-frok';
import { AutoModeCardWrapper } from './AutoModeCard.styled';
import { Col, Container, Row } from 'react-grid-system';
import { useShortTripMode } from '../../ShortTripModeContext';
import { requests } from '../../../../config/network';
import { TypeGuard } from '../../../../../utils/TypeGuard';

export default function AutoModeCard({ number }) {
    const {
        autoModeCardParams,
        dispatchAutoModeCardParams,
        vcuShortTripModeSignal,
    } = useShortTripMode();

    const [ minMaxRange, setMinMaxRange ] = useState({
        'minRange': 120,
        'maxRange': 180,
        'defaultAvgRange': 150,
    });

    const unselectedModeNumber = 0;

    const [ shouldShowRangeSlider, setShouldShowRangeSlider ] = useState(true);

    useEffect(() => {
        const minRange = vcuShortTripModeSignal?.VehCoorn_dstRngPrdnDrvgCnd_0 || 0;
        const maxRange = vcuShortTripModeSignal?.VehCoorn_dstRngPrdnDrvgCnd_1 || 0;
        const defaultAvgRange = Math.floor((minRange + maxRange) / 2);
        const shouldShowRangeSlider = TypeGuard.isSafeNumber(minRange) && TypeGuard.isSafeNumber(maxRange) && (minRange || maxRange);
        setMinMaxRange({
            'minRange': minRange,
            'maxRange': maxRange,
            'defaultAvgRange': defaultAvgRange,
        });
        setShouldShowRangeSlider(shouldShowRangeSlider);
    }, [
        vcuShortTripModeSignal.VehCoorn_dstRngPrdnDrvgCnd_0,
        vcuShortTripModeSignal.VehCoorn_dstRngPrdnDrvgCnd_1,
    ]);

    function postSelectedMode(mode, range) {
        const carId = localStorage.getItem('car_id');

        if (carId === undefined || carId === null) {
            console.error('No car id available');
            return;
        }

        fetch(requests.user_interaction_cvem_shm, {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
            'body': JSON.stringify({ 'mode': Number(mode), 'range': Number(range), 'carId': Number(carId) }),
        })
            .then((response) => response.json().then(async (data) => {
                if (response.status === 201) {
                    console.log('Post shm ok', data, response);
                }
                else {
                    console.error('Post shm NOT ok', data, response);
                }
            }))
            .catch((error) => {
                console.error(error);
            });
    }

    const handleToggleMode = (isChecked) => {
        dispatchAutoModeCardParams({
            'type': 'activeMode',
            'payload': isChecked,
        });

        const mode = isChecked ? number : unselectedModeNumber;

        postSelectedMode(mode, autoModeCardParams.range);
    };

    const handleChangeRange = (value) => {
        dispatchAutoModeCardParams({
            'type': 'range',
            'payload': value,
        });

        if (autoModeCardParams.activeMode) {
            postSelectedMode(number, value);
        }
    };

    return (
        <AutoModeCardWrapper>
            <Box
                className="vertical-layout info-box"
                shadow
                onClick={() => handleToggleMode(!autoModeCardParams.activeMode)}
            >
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <span className="title">Auto</span>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col>
                            <div className="pt-05 slider-auto-range-wrapper full-width full-height flex-row flex-align-center">
                                {shouldShowRangeSlider
                                    ? (<OverflowSlider
                                        id="slider_auto_range"
                                        minValue={minMaxRange.minRange}
                                        maxValue={minMaxRange.maxRange}
                                        defaultValue={minMaxRange.defaultAvgRange}
                                        onChangeCallback={handleChangeRange}
                                        onClick={(event) => event.stopPropagation()}
                                    />)
                                    : null}
                            </div>
                        </Col>
                        <Col sm="content">
                            <div className="pt-05 pb-15 full-width full-height flex-row flex-align-end">
                                <Toggle
                                    id="short-trip-mode-toggle"
                                    checked={autoModeCardParams.activeMode}
                                    readOnly
                                    onClick={(event) => event.stopPropagation()}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </AutoModeCardWrapper>
    );
}
