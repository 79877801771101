/*
 * Modify the graphs and scales, if new SSE-Values arriving
 * - functions:        modify the plots - used in "setInterval" in e.g. Main.js
 * - Documentation:    plotly: https://plotly.com/javascript/
 *                     extendTraces: https://plotly.com/javascript/plotlyjs-function-reference/#plotlyextendtraces
 *                     restyle: https://plotly.com/javascript/plotlyjs-function-reference/#plotlyrestyle
 */

import Plotly from 'plotly.js';

/**
 * function to add a new value (element_value) to a given graph (plot_name)
 * @param {float} element_value
 * @param {float} element_time
 * @param {string} plot_name
 * @param {int} number - optional
 */
function new_value_in_graph(element_value, element_time, plot_name, number = 500) {
    if (exists(plot_name)) {
        if (element_value !== null && element_value !== isNaN && element_value !== undefined) {
            Plotly.extendTraces(plot_name, { 'y': [ [ element_value ] ], 'x': [ [ element_time ] ] }, [ 0 ], number);
        }
    }
}

/**
 * function to change the shown value to "element_value" in a given scale (new_value_in_bar,plot_name)
 * @param {float} element_value
 * @param {string} color
 * @param {float} min
 * @param {float} max
 * @param {string} plot_name
 */
function new_value_in_scale(element_value, color, min, max, plot_name) {
    if (exists(plot_name)) {
        if (element_value !== null && element_value !== isNaN && element_value !== undefined) {
            Plotly.restyle(plot_name, {
                'value': element_value,
                'gauge': {
                    'axis': { 'range': [ min, max ] },
                    'bar': { 'color': color },
                },
            }, [ 0 ]);
        }
    }
}

/**
 * function to add a new value (element_value) to a given maps (plot_name)
 * @param {float} element_value
 * @param {float} time
 * @param {string} plot_name
 */
function new_value_in_maps(element_value, time, plot_name) {
    /* TODO: Next semester: remove elements in between */
    if (exists(plot_name)) {
        if (element_value !== null && element_value[0] !== isNaN && String(element_value[0]) !== 'NaN' && element_value !== undefined) {
            Plotly.extendTraces(plot_name, {
                'lat': [ [ element_value[0] ] ],
                'lon': [ [ element_value[1] ] ],
                'customdata': [ [ time ] ],
            }, [ 0 ], 1000);
            if (JSON.parse(localStorage.getItem('auto_zoom_maps'))) {
                Plotly.relayout(plot_name, {
                    'mapbox': {
                        'style': 'carto-positron',
                        'center': {
                            'lat': element_value[0],
                            'lon': element_value[1],
                        },
                        'zoom': 12,
                    },
                });
            }
        }
    }
}

/**
 * function to add a new value to a number chart
 * @param {float} element_value
 * @param {string} plot_name
 */
function new_value_in_number(element_value, plot_name) {
    if (exists(plot_name)) {
        Plotly.restyle(plot_name, { 'value': element_value }, 0);
    }
}

/**
 * Function to return if an element exists on the page
 * @param {string} id Element name
 * @returns {boolean} true | false
 */
function exists(id) {
    return (document.getElementById(id));
}

export const sse_plots = {
    'new_value_in_graph': new_value_in_graph,
    'new_value_in_scale': new_value_in_scale,
    'new_value_in_maps': new_value_in_maps,
    'new_value_in_number': new_value_in_number,
};
