import React, { useState } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import Dropdown from '../../../frok/Components/Dropdown';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Component to create a new car on the settings page
 *
 * @param {boolean} admin props, if user is admin
 * @param {int} reload trigger for reloading the page
 * @param {function} setReload function to set reload
 * @param {Object} users Object which contains the users
 * @param {Object} cars Object which contains the cars
 * @param {Object} fleets Object which contains the fleets
 * @returns {html} Button to create a new car
 */

export default function Fleets({ admin, reload, setReload, users, cars, fleets }) {
    const [ openFleet, setOpenFleet ] = useState(false);
    const [ openDeletePopup, setOpenDeletePopup ] = useState(null);

    const [ fleetNotificationBarText, setFleetNotificationBarText ] = useState('');
    const [ responseSuccess, setResponseSuccess ] = useState(false);

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    const [ newFleetData, setNewFleetData ] = useState({
        'username': '',
        'car_id': '',
    });

    function createFleet() {
        const request_body_fleet = JSON.stringify({
            'username': newFleetData.username,
            'car_id': newFleetData.car_id,
        });

        fetch(requests.create_fleet, {
            'method': 'POST',
            'body': request_body_fleet,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setNewFleetData({
                        ...newFleetData,
                        'username': response.username,
                        'car_id': response.car_id,
                    });
                    setReload(reload + 1);
                    setOpenFleet(false);
                }
                else {
                    setResponseSuccess(false);
                    setFleetNotificationBarText('Error! Something went wrong!');
                }
            })
            .catch((error) => {
                setResponseSuccess(false);
                setFleetNotificationBarText('Error: ', error);
            });
    }

    /**
     * remove an existing fleet
     *
     * @param {int} fleet_id Id of the fleet to be removed
     */
    function deleteFleet(fleet_id) {
        if (fleet_id === null) {
            setFetchResponseSuccess(false);
            setFetchResponseTitle(language.delete_fleet);
            setFetchResponse('Error! This fleet does not exist!');
        }

        const request_body = JSON.stringify({ 'fleet_id': fleet_id });

        fetch(requests.delete_fleet, {
            'method': 'DELETE',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setReload(reload + 1);
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.delete_fleet);
                    setFetchResponse('Fleet successfully deleted!');
                    setOpenDeletePopup(null);
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.delete_fleet);
                    setFetchResponse('Error! Something went wrong!');
                    setOpenDeletePopup(null);
                }
            });
    }

    function onChangeNewFleet(event) {
        const { name, value } = event.target;
        setNewFleetData({ ...newFleetData, [name]: value });
    }

    /* don't display any fleets, if user is not an admin */
    if (!admin) {
        return null;
    }

    /*
     * if there are no users and/or cars, there should be no fleets, so return
     * maybe these aren't loaded yet, and will be updated later
     */
    if (!(Object.keys(users).length) || !Object.keys(cars).length) {
        return null;
    }

    return (
        <>
            <div id="fleet_settings" className="settings_block">
                <h4>{language.fleets}</h4>
                <table className="m-table">
                    <thead>
                        <tr>
                            <th>{language.username}</th>
                            <th>{language.car}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (fleets.length > 0)
                                ? (
                                    fleets.map((fleet) => (
                                        <tr key={fleet.id}>
                                            <td>{fleet.username}</td>
                                            <td>{(cars[fleet.car_id] || {}).license_plate}</td>
                                            <td>
                                                <Button
                                                    icon="delete"
                                                    onClick={() => {
                                                        setOpenDeletePopup(fleet.id);
                                                    }}
                                                >
                                                    {language.delete}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )
                                : <tr><th colSpan="4"><i>No fleets.</i></th></tr>
                        }
                    </tbody>
                </table>

                <p>
                    <Button
                        id="create_fleet"
                        icon="add"
                        onClick={() => {
                            setOpenFleet(true);
                            setResponseSuccess(false);
                            setFleetNotificationBarText('');
                        }}
                    >
                        {language.create_fleet}
                    </Button>
                </p>
            </div>

            <Dialog
                open={openDeletePopup !== null}
                header={language.delete_fleet}
                level="warning"
                button1={(<Button icon="delete" onClick={() => deleteFleet(openDeletePopup)}>{language.delete}</Button>)}
                button2={(<Button icon="close" secondary onClick={() => setOpenDeletePopup(null)}>{language.cancel}</Button>)}
            >
                {language.delete_fleet_text}
            </Dialog>

            <Dialog
                open={openFleet}
                header={language.create_fleet}
                button1={(
                    <Button onClick={() => createFleet()}>
                        {language.create_fleet}
                    </Button>
                )}
                close={() => setOpenFleet(false)}
            >
                <div className="m-form-field">
                    <Dropdown
                        id="username_select"
                        label={`${language.username} *`}
                        defaultValue=""
                        name="username"
                        onChange={onChangeNewFleet}
                    >
                        <option value="" disabled>{language.select}</option>
                        {
                            Object.keys(users).map((user) => (
                                <option key={user} value={user}>
                                    {user}
                                </option>
                            ))
                        }
                    </Dropdown>
                </div>
                <div className="m-form-field">
                    <Dropdown
                        id="id_select"
                        label={`${language.licence_plate} *`}
                        defaultValue=""
                        name="car_id"
                        onChange={onChangeNewFleet}
                    >
                        <option value="" disabled>{language.select}</option>
                        {
                            Object.keys(cars).map((key) => (
                                <option key={key} value={key}>
                                    {cars[key].license_plate}
                                </option>
                            ))
                        }
                    </Dropdown>
                </div>
                <NotificationBar type={responseSuccess ? 'success' : 'error'}>{fleetNotificationBarText}</NotificationBar>
            </Dialog>

            <Dialog
                header={fetchResponseTitle}
                level={fetchResponseSuccess ? 'success' : 'error'}
                button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                open={fetchResponse.length > 0}
            >
                {fetchResponse}
            </Dialog>
        </>
    );
}
