import React from 'react';

export default function NotificationBar({ children, type, icon }) {
    // hide the notification bar, if there is no content
    if (children instanceof String && children.length === 0) {
        return null;
    }

    if (!children) {
        return null;
    }

    // handle the type of the notification
    if (type instanceof String) {
        type = type.toLowerCase();
    }
    switch (type) {
        case 'neutral':
        case 'success':
        case 'warning':
        case 'error':
            break;
        default:
            type = 'neutral';
    }

    // handle the icon of the notification
    let iconClass;
    if (icon) {
        iconClass = `boschicon-bosch-ic-${icon}`;
    }
    else if (type !== 'neutral') {
        iconClass = `ui-ic-alert-${type}`;
    }

    return (
        <div className={`a-notification -${type}`}>
            {(iconClass) ? <i className={`a-icon ${iconClass}`}></i> : null}
            <div className="a-notification__content">
                {children}
            </div>
        </div>
    );
}
