function update_weather_status(element_value) {
    if (document.getElementById('weather_header')) {
        document.getElementById('weather_degree').innerText = `${element_value[0]} °C`;
        document.getElementById('weather_location').innerText = element_value[1];

        let img_class = null;
        switch (element_value[2]) {
            case 1:
                img_class = 'sun';
                break;
            case 2:
                img_class = 'weather-cloud-sun';
                break;
            case 3:
                img_class = 'weather-cloudy';
                break;
            case 4:
                img_class = 'weather-rain';
                break;
            case 5:
                img_class = 'weather-rain';
                break;
            case 6:
                img_class = 'weather-rain-thunder';
                break;
            default:
                img_class = 'question';
                break;
        }
        document.getElementById('weather_img').className = `a-icon a-button__icon boschicon-bosch-ic-${img_class}`;
    }
}

export const sse_meta = { 'update_weather_status': update_weather_status };
