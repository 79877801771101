import { topics, heatflow_state } from './RealtimeData/SSE_index';

// Mamba Heat Flow state
let heatflow_update_timer = null;

export function initialize_heatflow_state() {
    heatflow_state.counter = 0;

    heatflow_state.measurements = {};
    topics.filter((t) => (t.type === 'heatflow'))
        .flatMap((t) => t.measurements)
        .forEach((t) => {
            heatflow_state.measurements[t] = {
                'value': null,
                'timestamp': 0,
            };
        });
}

export function initialize_heatflow_timer(fn) {
    clearInterval(heatflow_update_timer);

    heatflow_update_timer = setInterval(() => {
        fn();
        heatflow_state.counter++;
    }, 200);
}
