import React from 'react';
import { useEffect } from 'react';

/* Import own components */
import { plots } from './Elements/Plots/Plot';

/* Import self written configs, requests or helpers */
import request_sse, { set_topics } from './RealtimeData/SSE_index';

/**
 * Maps-Page to show a GPS-Map
 * @param {*} reload trigger for reloading page
 * @returns {html} Maps-Page
 */
export default function Maps({ reload }) {
    /**
     * useEffect that requests and activate realtime data
     */
    useEffect(() => {
        set_topics();
        request_sse();
    }, []);

    /**
     * useEffect triggered by [reload] that Triggers update (e.g. on change of car_id)
     */
    useEffect(() => {}, [ reload ]);

    return (
        // print map
        <main id="maps_site" className="no_padding">
            {plots.plot_maps('latLong_maps', 'fullscreen')}
        </main>
    );
}
