import React, { lazy, Suspense } from 'react';

const LazyVehicleInfrastructure = lazy(() => import('./VehicleInfrastructure'));

const VehicleInfrastructure = props => (
  <Suspense fallback={null}>
    <LazyVehicleInfrastructure {...props} />
  </Suspense>
);

export default VehicleInfrastructure;
