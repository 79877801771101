import React, { lazy, Suspense } from 'react';

const LazyEnvironmentTemperature = lazy(() => import('./EnvironmentTemperature'));

const EnvironmentTemperature = (props) => (
    <Suspense fallback={null}>
        <LazyEnvironmentTemperature {...props} />
    </Suspense>
);

export default EnvironmentTemperature;
