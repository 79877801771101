import { sse_plots } from './SSE_plots';
import { sse_meta } from './SSE_meta';
import { convert_rad_to_degree, convert_speed, convert_temperature } from '../config/converter';
import { get_cab_efficiency, get_cab_speed, get_cab_temp, get_color } from '../Elements/Plots/helpers/gaugeConfig';
import { requests } from '../config/network';

/**
 * Updates the topics depending to changes on selected_car_id for showing realtime-data
 * Used in Header for setting car_id
 *
 * @param {int} car_id
 */
export function set_topics(car_id = null) {
    if (car_id !== null) {
        localStorage.setItem('car_id', car_id);
    }
    else if (localStorage.getItem('car_id')) {
        car_id = localStorage.getItem('car_id');
    }
    else {
        car_id = -1;
        localStorage.setItem('car_id', car_id);
    }

    /* eslint-disable array-element-newline, array-bracket-newline */
    topics = [
        /* Sprint 1 - Battery Pre-Condition */
        {
            'measurements': [
                'VCU_tHVBatt',
            ],
            'path': `${car_id}/modified/BatteryPreCon`,
            'type': null,
        },
        {
            'measurements': [
                'Cloud_dtHVBatt',
            ],
            'path': `${car_id}/result/BatteryPreCon`,
            'type': null,
        },

        /* rangePrediction Data */
        {
            'measurements': [
                'remainingDistanceCur', 'remainingDistanceDrive', 'remainingDistanceBufferTime',
            ],
            'path': `${car_id}/result/RangePredictionTest`,
            'type': null,
        },
        {
            'measurements': [
                'Cloud_dstAvlRngShrt', 'Cloud_dstAvlRngMed', 'Cloud_dstAvlRngAll',
            ],
            'path': `${car_id}/result/RangePrediction`,
            'type': null,
        },
        {
            'measurements': [
                'Veh_vVehAvrg1s', 'Veh_cpVehAvlEgyAct', 'Veh_cnsVeh1s',
            ],
            'path': `${car_id}/raw/RangePrediction`,
            'type': null,
        },
        {
            'measurements': [
                'Cnv_vVehAvrg1s', 'Cnv_cpVehAvlEgyAct', 'Cnv_cnsVeh1s',
            ],
            'path': `${car_id}/modified/RangePrediction`,
            'type': null,
        },

        /* weather Data */
        {
            'measurements': [
                'latitude', 'longitude',
            ],
            'path': `${car_id}/modified/GPS`,
            'type': 'gps',
        },
        {
            'measurements': [
                'location', 'condition_code', 'temp_c', 'temp',
            ],
            'path': `${car_id}/external/weather`,
            'type': 'weather',
        },
        {
            'measurements': [
                'Cloud_dstAvlRngAllWth',
            ],
            'path': `${car_id}/result/RangePredictionExternal`,
            'type': null,
        },

        /* TMM */
        {
            'measurements': [
                'VCU_tWtrLT', 'VCU_tWtrMT', 'VCU_tWtrHT', 'VCU_dtUSeComp', 'VCU_dtDSeComp', 'VCU_flowWtrINV1_F1',
                'VCU_flowWtrHVBatt_F2', 'VCU_flowWtrINV2_F3', 'VCU_tHVBatt', 'VCU_tInv1Gen', 'VCU_tInv2Trac',
                'Cnv_stThmm', 'VCU_stCloudCnctd', 'VCU_flgHVBattCoolReq', 'VCU_flgHVBattHeatReq', 'VCU_tAmbVehRoom',
                'VCU_eCompSpeed', 'Cnv_p_RefrComprDS', 'Cnv_p_RefrComprUS',
            ],
            'path': `${car_id}/modified/VCU_TMM`,
            'type': 'tmm',
        },
        {
            'measurements': [
                'Cnv_stHVBatt', 'VCU_ratSocHVBatt', 'VCU_iHVBatt', 'VCU_iGen', 'VCU_iTrac', 'VCU_iElecComp',
                'VCU_iDcDc', 'VCU_iChrgr',
            ],
            'path': `${car_id}/modified/VCU_ElecPT`,
            'type': 'tmm',
        },

        /* Thermomanagement */
        {
            'measurements': [
                'T_A_AMB', 'T_A_CentrCons', 'T_A_DFFRST', 'T_A_FootWellF', 'T_A_HeadF', 'T_A_VENT1', 'T_A_VENT2',
                'T_A_VENT3', 'T_A_VENT4', 'T_A_VENT5', 'T_A_VENT6', 'T_A_uRAD', 'T_C_dBAT', 'T_C_dCHL', 'T_C_dDCDC',
                'T_C_dEDU1', 'T_C_dHC', 'T_C_dOBC', 'T_C_dPTC', 'T_C_dRAD', 'T_C_uBAT', 'T_C_uCHL', 'T_C_uDCDC',
                'T_C_uEDU1', 'T_C_uHC', 'T_C_uOBC', 'T_C_uPMP1', 'T_C_uPTC', 'T_C_uRAD',
            ],
            'path': `${car_id}/modified/THMM`,
            'type': 'thermomanagement',
        },
        {
            'measurements': [
                'p_C_dRAD', 'p_C_uRAD', 'p_C_uPMP2', 'p_C_uHC', 'p_C_dHC', 'p_C_dPMP2', 'p_C_uPMP3', 'p_C_uPMP1',
                'V_C_EDU1', 'V_C_HC', 'V_C_BAT', 'V_C_PMP3',
            ],
            'path': `${car_id}/modified/ADMM`,
            'type': 'thermomanagement',
        },
        {
            'measurements': [
                'Com_iHvbAct', 'ComEmac_iDcLnk01', 'Com_iCmprInp', 'Com_iDcdcHvActRvsn1', 'ComChrgr_iDcHvAct',
                'Com_nCmprAct', 'CmprUsFldP_p_f', 'Com_pSnsrHi', 'Com_uHvbAct', 'Com_uDcdcHvAct', 'Com_uDcdcLvAct',
                'ComEmac_nAct01', 'ComEmac_tqAct01', 'ComEmac_uDcLnk01', 'Com_iPtcAct', 'Com_iGridActual',
                'Com_iDcdcLvActRvsn1', 'Com_agHeatrVlvCurrPosn', 'Fan_nDes', 'Blwr_ratAct', 'Com_stHvbMod',
                'Com_ratHvbSoc', 'CTEVHvbUs_t_f', 'MedCooltT1_t_f', 'CbnAirT_tEvapAct', 'CbnAirT_tEvapDes',
                'Com_tHvbAct', 'ComEmac_tInvr01', 'ElM_t01', 'Com_tCbn', 'Com_tDcdcAct', 'Com_tPtcIntPcb',
                'ComChrgr_tChrgrMaxAct', 'Com_uPtcAct', 'Com_uCmprInp', 'Com_uGridActual', 'ComChrgr_uDcHvAct',
            ],
            'path': `${car_id}/modified/UDS`,
            'type': 'thermomanagement',
        },
        {
            'measurements': [
                'V_C_PMP2',
            ],
            'path': `${car_id}/result/ADMM`,
            'type': 'thermomanagement',
        },
        {
            'measurements': [
                'ComChrgr_iACGridActual', 'ComChrgr_iDCGridActual',
            ],
            'path': `${car_id}/result/UDS`,
            'type': 'thermomanagement',
        },
        {
            'measurements': [
                'p_C_uLCC', 'p_C_dLCC', 'p_C_MTR1', 'p_C_MTR2', 'p_C_LTR1', 'p_C_LTR2', 'p_C_uCC', 'p_C_dCC', 'V_C_CHL',
                'V_C_BAT', 'V_C_EDU', 'V_C_HC', 'V_C_CC', 'V_C_LTR', 'V_C_MTR', 'H_A_CBN01', 'H_A_CBN02', 'U_LV_DCDC',
                'U_LV_BAT', 'I_LV_DCDC', 'I_LV_FAN', 'I_LV_BAT', 'I_LV_MeasEQ', 'I_LV_BLWR', 'I_HV_DCDC2', 'I_HV_OBC',
                'I_HV_PTU2', 'p_C_uCHL', 'p_C_dCHL', 'p_C_uBAT', 'p_C_dDCDC', 'p_C_uEDU', 'p_C_dEDU', 'p_C_uHC', 'p_C_dHC',
            ],
            'path': `${car_id}/modified/MAMBAADMM`,
            'type': 'heatflow',
        },
        {
            'measurements': [
                'T_C_uCHL', 'T_C_dCHL', 'T_C_uBAT', 'T_C_dDCDC', 'T_C_uEDU', 'T_C_dEDU', 'T_C_uHC', 'T_C_dHC', 'T_C_uLCC',
                'T_C_dLCC', 'T_C_MTR1', 'T_C_MTR2', 'T_C_LTR1', 'T_C_LTR2', 'T_C_uCC', 'T_C_dCC', 'T_A_DFRST', 'T_A_VENT1',
                'T_A_VENT2', 'T_A_VENT3', 'T_A_VENT4', 'T_A_VENT5', 'T_A_VENT6', 'T_A_CentrCons', 'T_A_FootWellF', 'T_A_HeadR',
                'T_A_FootWellR', 'T_A_HeadF', 'T_A_CC1', 'T_A_CC2', 'T_A_CC3', 'T_A_CC4', 'T_A_CC5', 'T_A_CC6', 'T_A_CC7',
                'T_A_CC8', 'T_A_HC1', 'T_A_HC2', 'T_A_HC3', 'T_A_HC4', 'T_A_HC5', 'T_A_HC6', 'T_A_HC7', 'T_A_HC8', 'T_C_dBAT',
                'T_C_dOBC', 'T_A_uRAD', 'T_A_AMB', 'T_A_Hood1', 'T_A_Hood2', 'T_O_EDU1', 'T_A_dLTR1', 'T_A_dLTR2', 'T_A_dLTR3',
            ],
            'path': `${car_id}/modified/MAMBATHMM`,
            'type': 'heatflow',
        },
        {
            'measurements': [
                'Com_iHvbAct', 'ComEmac_iDcLnk01', 'Com_iCmprInp', 'Com_iDcdcHvActRvsn1', 'ComChrgr_iDcHvAct',
                'PTU_nActCmpr_RP', 'CmprUsFldP_p_f', 'CmprDsFldP_p_f', 'Com_uHvbAct', 'Com_uDcdcHvAct',
                'Com_uDcdcLvAct', 'ComEmac_nAct01', 'ComEmac_tqAct01', 'ComEmac_uDcLnk01', 'Com_iGridActual',
                'Com_iDcdcLvActRvsn1', 'FTU_agActCPV3_RP', 'VehFan_nAct_RP', 'Blwr_ratAct', 'PTU_uActCmprLIN_RP',
                'Com_uGridActual', 'ComChrgr_uDcHvAct', 'CondrDsFldP_p_f', 'PTU_tCmprUs_RP', 'PTU_tCmprDs_RP',
                'PTU_tEXVlvUs_RP', 'ThermLvl_stVlvMod', 'Com_stHvbMod', 'Com_ratHvbSoc', 'CTEVHvbUs_t_f',
                'MedCooltT1_t_f', 'CbnAirT_tEvapAct', 'CbnAirT_tEvapDes', 'Com_tHvbAct', 'ComEmac_tInvr01',
                'ElM_t01', 'Com_tCbn', 'Com_tDcdcAct', 'ComChrgr_tChrgrMaxAct', 'EnvT_t',
            ],
            'path': `${car_id}/modified/MambaUDS`,
            'type': 'heatflow',
        },
        {
            'measurements': [
                'Qdot_ambient_LTrad', 'Qdot_LTrad_PTU', 'Qdot_PTU_HVAC', 'Qdot_HVAC_cabin', 'Qdot_MTrad_ambient',
                'Qdot_HVAC_PTU', 'Qdot_cabin_HVAC', 'Qdot_EDU_MTrad', 'Qdot_BAT_LTrad', 'Com_pwrCmprInp',
            ],
            'path': `${car_id}/result/HeatFlow`,
            'type': 'heatflow',
        },
        {
            'measurements': [
                // 'enthalpy1', 'CmprUsFldP_p_f', 'enthalpy2', 'CmprDsFldP_p_f', 'enthalpy3', 'CondrDsFldP_p_f',
                'enthalpy1', 'pressure1', 'enthalpy2', 'pressure2', 'enthalpy3', 'pressure3',
            ],
            'path': `${car_id}/result/PH_Diagram`,
            'type': 'heatflow',
        },
        {
            'measurements': [
                'VehCoorn_dstRngPrdnDrvgCnd_0', 'VehCoorn_dstRngPrdnDrvgCnd_1', 'VehCoorn_dstRngPred',
                'Com_dstOdo', 'Com_egyHvbAvl_f', 'ComEmac_uDcLnk01', 'ComEmac_iDcLnk01', 'Com_uCmprInp',
                'Com_iCmprInp', 'Com_uPtcAct', 'Com_iPtcAct',
            ],
            'path': `${car_id}/modified/UDS`,
            'type': 'safehomemode',
        },
        {
            'measurements': [
                'VehCoorn_dstRngPred', 'Com_dstOdo', 'Com_egyHvbAvl_f', 'GlbDa_lTotDst', 'VehV_v',
                'VehEgyMngt_vMaxLim', 'VehEgyMngt_stCbnCdng', 'Com_tEnv', 'Com_tCbn',
                'ThermPrepn_wElecDmndPreCdng', 'VehEgyMngt_stHiUBattCdng', 'VehEgyMngt_wElecLimCdngBatt',
            ],
            'path': `${car_id}/modified/UDS`,
            'type': 'shortTripAndLastMileMode',
        },
        {
            'measurements': [
                'VehCoorn_dstRngPrdnDrvgCnd0', 'VehCoorn_dstRngPrdnDrvgCnd1', 'VehCoorn_dstRngPred',
                'Com_dstOdo', 'Com_egyHvbAvl_f', 'ComEmac_uDcLnk01', 'ComEmac_iDcLnk01', 'Com_uCmprInp',
                'Com_iCmprInp', 'Com_uPtcAct', 'Com_iPtcAct',
            ],
            'path': `${car_id}/modified/UDS`,
            'type': 'safehomemode',
        },
        {
            'measurements': [
                'VehCoorn_dstRngPred', 'Com_dstOdo', 'Com_egyHvbAvl_f', 'GlbDa_lTotDst', 'VehV_v',
                'VehEgyMngt_vMaxLim', 'VehEgyMngt_stCbnCdng', 'Com_tEnv', 'Com_tCbn',
                'ThermPrepn_wElecDmndPreCdng', 'VehEgyMngt_stHiUBattCdng', 'VehEgyMngt_wElecLimCdngBatt',
            ],
            'path': `${car_id}/modified/UDS`,
            'type': 'shortTripAndLastMileMode',
        },
    ];
    /* eslint-enable array-element-newline, array-bracket-newline */
}

/**
 * array for saving all possible topics
 * Used in get_data_callback and in Settings
 */
export let topics = [];
export let eventSource = null;
// TMM state
export const tmm_state = {};
export let on_TMM_page = false;
// Thermomanagement state
export const thermomanagement_state = {};
export let on_thermomanagement_page = false;
// Mamba Heat Flow state
export const heatflow_state = {};
export let on_heatflow_page = false;
// Safe Home Mode state
export const safehomemode_state = {};
export let on_safehomemode_page = false;
// Short Trip Mode and Last Mile Mode sate
export const shortTripAndLastMileMode_state = {};
export let on_shortTripOrLastMileMode_page = false;

/**
 * activate SSE-Connection and set onmessage function
 * used for sides, that print realtime-data (e.g. Main, Drive and CustomDashboard)
 * @returns close SSE-Stream on Leaving the page
 */
export default function request_sse() {
    if (eventSource !== null) {
        return;
    }

    eventSource = new EventSource(requests.listen);
    console.info('New connection to', requests.listen);

    // store if we are on the TMM page
    on_TMM_page = (Object.keys(tmm_state).length !== 0);

    // store if we are on any Thermomanagement page
    on_thermomanagement_page = (Object.keys(thermomanagement_state).length !== 0);

    // store if we are on any Mamba Heat Flow page
    on_heatflow_page = (Object.keys(heatflow_state).length !== 0);

    on_safehomemode_page = (Object.keys(safehomemode_state).length !== 0);

    on_shortTripOrLastMileMode_page = (Object.keys(shortTripAndLastMileMode_state).length !== 0);

    /* onMessage function -> Calls get_data_callback */
    eventSource.onmessage = (e) => {
        if (e.data !== '') {
            get_data_callback(e.data);
        }
    };

    eventSource.onerror = () => {
        eventSource.close();
        eventSource = null;
        request_sse();
    };

    return () => {
        eventSource.close();
        eventSource = null;
    };
}

/**
 * encode JSON-Object and select (and convert) data and manipulate plots
 * used in onmessage in request_sse - function called as callback by onMessage
 *
 * @param {*} data Data that was send by MQTT over SSE
 */
function get_data_callback(data) {
    /* converting the string into a json object */
    const object = JSON.parse(data);
    const obj = object.payload.fields;
    const topic = object.topic;

    const time_ms = object.payload.time; // timestamp in micro seconds
    const time = new Date(time_ms * 1000);

    let color, min, max;

    console.log(data);

    /* Check if mqtt-topic is in topic-tree */
    const elements = topics.filter((t) => t.path === topic);

    /* Throw error, if element is not in topic tree */
    if (elements.length === 0) {
        if (topic.includes('raw')) {
            // console.info('Topic', topic, 'is raw data and will not be printed in Topic-Tree');
            return;
        }

        console.error('Topic', topic, 'not found in Topic-Tree');

        return;
    }

    elements.forEach((element) => {
    /* Throw error, if topic-tree is malformed (measurement is string not array) */
        if ((typeof element.measurements) === 'string') {
            console.error('Malformed Topic-Tree. Measurements have to be list not string.');
            return;
        }

        /* Filter after types of the topics and act like it is defined */
        if (element.type === 'gps') {
            const value = [ obj.latitude, obj.longitude ];
            sse_plots.new_value_in_maps(value, time, 'latLong_maps');
        }
        else if (element.type === 'weather') {
        // Set header
            let value = [ obj.temp_c, obj.location, obj.condition_code ];
            sse_meta.update_weather_status(value);

            for (let i = 0; i < element.measurements.length; i++) {
                if (element.measurements[i] !== 'location') {
                    value = obj[element.measurements[i]];
                    sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                }
            }
        }
        else if (element.type === 'tmm') {
            for (let i = 0; i < element.measurements.length; i++) {
                const value = obj[element.measurements[i]];

                // update graphs on all pages
                sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                sse_plots.new_value_in_scale(value, color, min, max, `${element.measurements[i]}_scale`);

                // update TMM state only on TMM page
                if (on_TMM_page) {
                    tmm_state.measurements[element.measurements[i]].value = value;
                    tmm_state.measurements[element.measurements[i]].timestamp = time;
                }
            }
        }
        else if (element.type === 'thermomanagement') {
            for (let i = 0; i < element.measurements.length; i++) {
                const value = obj[element.measurements[i]];

                if (value === undefined) {
                    continue;
                }

                // update graphs on all pages
                sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                sse_plots.new_value_in_scale(value, color, min, max, `${element.measurements[i]}_scale`);

                // update Thermomanagement state only on any Thermomanagement page
                if (on_thermomanagement_page) {
                    thermomanagement_state.measurements[element.measurements[i]].value = value;
                    thermomanagement_state.measurements[element.measurements[i]].timestamp = time;
                }
            }
        }
        else if (element.type === 'heatflow') {
            for (let i = 0; i < element.measurements.length; i++) {
                const value = obj[element.measurements[i]];

                if (value === undefined) {
                    continue;
                }

                // update graphs on all pages
                sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                sse_plots.new_value_in_scale(value, color, min, max, `${element.measurements[i]}_scale`);

                // update Mamba Heat Flow state only on any Mamba Heat Flow page
                if (on_heatflow_page) {
                    heatflow_state.measurements[element.measurements[i]].value = value;
                    heatflow_state.measurements[element.measurements[i]].timestamp = time;
                }
            }
        }
        else if (element.type === 'safehomemode') {
            for (let i = 0; i < element.measurements.length; i++) {
                const value = obj[element.measurements[i]];

                if (value === undefined) {
                    continue;
                }

                // update graphs on all pages
                sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                sse_plots.new_value_in_scale(value, color, min, max, `${element.measurements[i]}_scale`);

                if (on_safehomemode_page) {
                    safehomemode_state.measurements[element.measurements[i]].value = value;
                    safehomemode_state.measurements[element.measurements[i]].timestamp = time;
                }
            }
        }
        else if (element.type === 'shortTripAndLastMileMode') {
            for (let i = 0; i < element.measurements.length; i++) {
                const value = obj[element.measurements[i]];

                if (value === undefined) {
                    continue;
                }

                /*
                 * TODO: this may not be good here if there are multiple elements with the same measurements
                 * update graphs on all pages
                 */
                sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                sse_plots.new_value_in_scale(value, color, min, max, `${element.measurements[i]}_scale`);

                if (on_shortTripOrLastMileMode_page) {
                    safehomemode_state.measurements[element.measurements[i]].value = value;
                    safehomemode_state.measurements[element.measurements[i]].timestamp = time;
                }
            }
        }
        else {
        // TODO: this may not be good here if there are multiple elements with the same measurements

            /* Iterate through all measurements of a package */
            for (let i = 0; i < element.measurements.length; i++) {
                let value = obj[element.measurements[i]];

                if (value === undefined || value === null) {
                    console.warn('Measurement', element.measurements[i], 'is in Topic-Tree but not in sent MQTT/SSE-Message.');
                    continue;
                }

                /* if necessary convert the type of the value */
                if (element.type !== null) {
                    [ value, min, max, color ] = convert_depending_on_type(value, element.type[i], topic);
                }

                /* Add value to the different graphs */
                sse_plots.new_value_in_graph(value, time, `${element.measurements[i]}_graph`, 250);
                sse_plots.new_value_in_scale(value, color, min, max, `${element.measurements[i]}_scale`);
            }
        }
    });
}

/**
 * encode JSON-Object and select (and convert) data and manipulate plots
 *
 * @param {any} value Values that were extracted out of mqtt-message
 * @param {string} type Special interpretation of data
 * @param {string} topic Topic-Path
 *
 * @returns [value, min, max, color] Values that should be used in the display of the graph
 */
function convert_depending_on_type(value, type, topic) {
    let min, max, color;

    switch (type) {
        case 'speed':
            value = convert_speed(parseFloat(value));
            [ color, min, max ] = get_cab_speed(parseFloat(value));
            min = convert_speed(min);
            max = convert_speed(max);
            break;
        case 'temp':
            value = convert_temperature(parseFloat(value));
            [ color, min, max ] = get_cab_temp(topic, parseFloat(value));
            min = convert_temperature(min);
            max = convert_temperature(max);
            break;
        case 'degree':
            value = convert_rad_to_degree(parseFloat(value));
            color = get_color();
            min = -180;
            max = 360;
            break;
        case 'gps':
            // value = [parseFloat(obj['latitude']), parseFloat(obj['longitude'])];
            break;
        case 'percentage':
            value = parseFloat(value) * 100;
            [ color, min, max ] = get_cab_efficiency(parseFloat(value));
            break;
        default:
            value = parseFloat(value);
            color = get_color();
    }

    return [ value, min, max, color ];
}
