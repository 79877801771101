import React, { createContext, useContext, useEffect, useRef, useReducer, useState } from 'react';
import { parseTopicPath, getTopicKeyByPath } from './SSETopicsUtil';

// Define initial state
const initialMeasurementsState = { 'measurements': {} };

const SSE_ACTIONS = {
    'UPDATE_MEASUREMENT': 'UPDATE_MEASUREMENT',
    'RESET_MEASUREMENTS': 'RESET_MEASUREMENTS',
    'INIT_MESSAGES': 'INIT_MESSAGES',
};

// Define reducer for measurements
const messagesReducer = (state, action) => {
    switch (action.type) {
        case SSE_ACTIONS.UPDATE_MEASUREMENT: {
            const { carId, measurements, topicKey } = action.payload;
            return {
                ...state,
                'measurements': {
                    ...(state.measurements || {}),
                    [carId]: {
                        ...(state.measurements?.[carId] || {}),
                        [topicKey]: {
                            ...(state.measurements?.[carId]?.[topicKey] || {}),
                            ...measurements,
                        },
                    },
                },
            };
        }
        case SSE_ACTIONS.RESET_MEASUREMENTS:
            return {
                ...state,
                'measurements': {},
            };
        case SSE_ACTIONS.INIT_MESSAGES:
            return action.payload;
        default:
            return state;
    }
};

// Create SSE Context
const SSEContext = createContext();

// SSE Provider to manage SSE connection and store data by topic
export const SSEProvider = ({ url, initialRetryInterval = 3000, maxRetryInterval = 60000, children }) => {
    const eventSourceRef = useRef(null);
    const retryTimeoutRef = useRef(null);

    // Sử dụng useReducer cho messages
    const [ messages, dispatch ] = useReducer(messagesReducer, []);
    const [ isConnected, setIsConnected ] = useState(false);
    const [ error, setError ] = useState(null);
    const retryIntervalRef = useRef(initialRetryInterval);

    useEffect(() => {
        dispatch({ 'type': SSE_ACTIONS.INIT_MESSAGES, 'payload': initialMeasurementsState });
    }, []);

    useEffect(() => {
        const connect = () => {
            const es = new EventSource(url);
            eventSourceRef.current = es;

            es.onopen = () => {
                console.log(`Connected to server successfully: ${url}`);
                setIsConnected(true);
                setError(null);
                retryIntervalRef.current = initialRetryInterval; // Reset retry interval
            };

            es.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log('Received message: ', data);

                    const { topic, payload } = data;

                    const { fields } = payload;

                    // Extract carID and topicPath from topic
                    const { carId, path } = parseTopicPath(topic);

                    // Determine Key based on topicPath
                    const key = getTopicKeyByPath(path);

                    if (key) {
                        dispatch({ 'type': SSE_ACTIONS.UPDATE_MEASUREMENT, 'payload': { 'carId': carId, 'measurements': fields, 'topicKey': key } });
                    }
                }
                catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            es.onerror = (err) => {
                console.error('Connection error, try again later:', retryIntervalRef.current, 'ms');
                setIsConnected(false);
                setError(err);

                // Close current connection
                es.close();

                // Reset connection after a period of time with exponential backoff
                retryTimeoutRef.current = setTimeout(() => {
                    // Double the retryInterval but do not exceed maxRetryInterval
                    retryIntervalRef.current = Math.min(
                        retryIntervalRef.current * 2,
                        maxRetryInterval
                    );
                    connect();
                }, retryIntervalRef.current);
            };
        };

        // Initialize connection
        connect();

        // Cleanup when component unmounts or when URL changes
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
            if (retryTimeoutRef.current) {
                clearTimeout(retryTimeoutRef.current);
            }
        };
    }, [ url, initialRetryInterval, maxRetryInterval ]);

    return (
        <SSEContext.Provider value={{ 'messages': messages, 'isConnected': isConnected, 'error': error, 'dispatch': dispatch }}>
            {children}
        </SSEContext.Provider>
    );
};

// Hook to use SSE context
export const useSSE = () => useContext(SSEContext);
