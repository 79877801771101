import React, { useState } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Loads dbc file names, and renders them on the dashboard
 * @param {boolean} admin props, if user is admin
 * @param {int} reload props, value to trigger update
 * @param {function} setReload  props, function to set the reload
 * @param {Object} dbcFiles props, object containing the dbc files
 * @param {function} setDbcFiles props, function to set the dbc files
 * @returns {html} return list of cars
 */

export default function Dbc({ admin, reload, setReload, dbcFiles, setDbcFiles }) {
    const [ openUpload, setOpenUpload ] = useState(false);

    const [ openDelete, setOpenDelete ] = useState(null);

    const [ notificationBarText, setNotificationBarText ] = useState('');
    const [ notificationBarSuccess, setNotificationBarSuccess ] = useState(false);

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    /**
     *
     * @param {string} filename
     */
    function deleteDBCFile(filename) {
        const request_body = { 'filename': filename };

        fetch(requests.delete_dbc_file, {
            'method': 'DELETE',
            'body': JSON.stringify(request_body),
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.delete_dbc);
                    setFetchResponse('DBC file deleted!');
                    setOpenDelete(null);
                    setReload(reload + 1);
                    setDbcFiles(dbcFiles.filter((dbc) => dbc.name !== filename));
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.delete_dbc);
                    setFetchResponse(`Error deleting DBC file! ${data.message}`);
                    setOpenDelete(null);
                }
            }))
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.delete_dbc);
                setNotificationBarText(`Error deleting DBC file! ${error}`);
            });
    }

    function uploadNewDbcFile() {
        const file = document.getElementById('upload_dbc').files[0];
        if (file === undefined) {
            return false;
        }

        const formData = new FormData();
        formData.append('file', file);

        fetch(requests.upload_dbc_file, {
            'method': 'POST',
            'body': formData,
            'headers': { 'Authorization': localStorage.getItem('token') },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.upload);
                    setFetchResponse('DBC file uploaded successfully.');
                    setOpenUpload(false);
                    setReload(reload + 1);
                }
                else {
                    setNotificationBarSuccess(false);
                    setNotificationBarText(`Error uploading DBC file! ${data.message}`);
                }
            }))
            .catch((error) => {
                setNotificationBarSuccess(false);
                setNotificationBarText(`Error uploading DBC file! ${error}`);
            });
    }

    function onFileSelected() {
        document.querySelector('#upload_dbc_name').innerText = document.querySelector('#upload_dbc').files[0].name;
    }

    return (admin)
        ? (
            <>
                <div id="dbc_settings" className="settings_block">
                    <h3>{language.dbc_file}</h3>
                    <table className="m-table">
                        <thead>
                            <tr>
                                <th>{language.dbc}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (dbcFiles.length > 0)
                                    ? (
                                        dbcFiles.map((dbc) => (
                                            <tr key={dbc.id}>
                                                <td>{dbc.name}</td>
                                                <td>
                                                    <Button
                                                        icon="delete"
                                                        onClick={() => setOpenDelete(dbc.name)}
                                                    >
                                                        {language.delete}
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    : <tr><th colSpan="2"><i>No DBC files.</i></th></tr>
                            }
                        </tbody>
                    </table>
                    <p>
                        <Button
                            icon="upload"
                            onClick={() => {
                                setNotificationBarText('');
                                setOpenUpload(true);
                            }}
                        >{language.upload}
                        </Button>
                    </p>
                </div>

                <Dialog
                    open={openDelete !== null}
                    header={language.delete_dbc}
                    level="warning"
                    button1={(<Button icon="delete" onClick={() => deleteDBCFile(openDelete)}>{language.delete}</Button>)}
                    button2={(<Button icon="close" secondary onClick={() => setOpenDelete(null)}>{language.cancel}</Button>)}
                >
                    {language.delete_dbc_text}
                </Dialog>

                <Dialog
                    header={language.upload}
                    open={openUpload}
                    button1={(
                        <Button
                            icon="upload"
                            onClick={uploadNewDbcFile}
                        >{language.upload}
                        </Button>
                    )}
                    close={() => setOpenUpload(false)}
                >
                    <div
                        className="a-file-upload-input"
                        onDragEnter={(event) => event.preventDefault()}
                        onDragOver={(event) => event.preventDefault()}
                        onDrop={(event) => {
                            event.preventDefault();
                            document.querySelector('#upload_dbc').files = event.dataTransfer.files;
                            onFileSelected();
                        }}
                    >
                        <label htmlFor="upload_dbc">
                            <i className="a-icon boschicon-bosch-ic-upload"></i>
                            {language.choose_file}
                        </label>
                        <input
                            id="upload_dbc"
                            name="input file upload"
                            type="file"
                            accept=".dbc"
                            onChange={onFileSelected}
                        />
                        <div className="a-file-upload-input__preview">
                            <p id="upload_dbc_name">{language.no_file_chosen}</p>
                        </div>
                    </div>
                    <NotificationBar type={notificationBarSuccess ? 'success' : 'error'}>{notificationBarText}</NotificationBar>
                </Dialog>

                <Dialog
                    header={fetchResponseTitle}
                    level={fetchResponseSuccess ? 'success' : 'error'}
                    button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                    open={fetchResponse.length > 0}
                >
                    {fetchResponse}
                </Dialog>
            </>
        )
        : null;
}
