import React, { useEffect, useState, useRef } from 'react';

/* Import pictures */
import login_logo from '../images/login_logo.png';

/* Import self written configs, requests or helpers */
import { requests } from './config/network';
import { language_config } from './config/languages';

const language = language_config();

/**
 * Login-Page to avoid unauthorized Use
 *
 * @param {function} setValidToken function to validate token
 * @param {function} setToken function to set token
 * @returns {html} Login-Page
 */
export default function Login({ setValidToken, setToken, errorMessage }) {
    // UseState-Variabels to save register and login Data
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ registerUsername, setRegisterUsername ] = useState('');
    const [ registerPassword, setRegisterPassword ] = useState('');
    const [ registerRepeatPassword, setRegisterRepeatPassword ] = useState('');

    // UseState-Variables to display error-Messages
    const [ loginErrorText, setLoginErrorText ] = useState(errorMessage);
    const [ signupErrorText, setSignupErrorText ] = useState('');

    const passwordField = useRef(null);
    const registrationModal = useRef(null);
    const forgotPasswordModal = useRef(null);
    const registrationSuccessfulModal = useRef(null);

    useEffect(() => {
        localStorage.setItem('car_id', -1);
    }, []);

    function resetStates() {
        setUsername('');
        setPassword('');
        setRegisterUsername('');
        setRegisterPassword('');
        setRegisterRepeatPassword('');
        setLoginErrorText('');
        setSignupErrorText('');
    }

    /**
     * function to manage the login (allow or decline login)
     * print error-message on error
     */
    function save_login() {
        const request_body = JSON.stringify({
            'username': username,
            'password': password,
        });

        fetch(requests.login, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                //
            },
        }).then((response) => response.json())
            .then((data) => {
                localStorage.setItem('token', data.token);

                if (data.token === undefined) {
                    /* User is not autorized to login */
                    setValidToken(false);
                    localStorage.setItem('username', null);

                    if (data.message === 'User not authorized') {
                        setLoginErrorText(language.login_auth_error);
                    }
                    else {
                        setLoginErrorText(language.login_error);
                    }
                }
                else {
                    /* User is authorized and can login */
                    setLoginErrorText('');
                    setValidToken(true);
                    setToken(data.token);
                    localStorage.setItem('username', username);

                    /* redirect to URL without query string parameters, if necessary */
                    const url = new URL(window.location);
                    if (url.searchParams.size > 0) {
                        window.location.replace(url.origin + url.pathname);
                    }
                }
            })
            .catch(() => {
                setLoginErrorText(language.network_error);
            });
    }

    /**
     * manage register-process, check password and create user
     */
    function save_register() {
        if (registerUsername.length < 1 || registerPassword.length < 1 || registerRepeatPassword.length < 1) {
            setSignupErrorText(language.login_all_fields_error);
            return;
        }

        if (registerPassword.length < 8) {
            setSignupErrorText(language.login_pw_length_error);
            return;
        }

        if (registerPassword !== registerRepeatPassword) {
            setSignupErrorText(language.login_pw_repeat_error);
            return;
        }

        const request_body = JSON.stringify({
            'username': registerUsername,
            'password': registerPassword,
        });

        fetch(requests.create_user, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                //
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                registrationSuccessfulModal.current.showModal();
                registrationModal.current.close();
            }
            else if (response.status === 400) {
                setSignupErrorText(language.signup_username_error);
            }
            else if (response.status === 409) {
                setSignupErrorText(language.signup_user_error);
            }
            else {
                setSignupErrorText(language.signup_failed_error);
            }
        })
            .catch((error) => {
                setSignupErrorText(language.connection_failed);
                console.log(error);
            });
    }

    /**
     * make it possible to approve login with enter
     * @param {*} event
     */
    const handle_key_down = (event) => {
        if (event.key === 'Enter') {
            save_login();
        }
    };

    /**
     * make it possible to approve registration with enter
     * @param {*} event
     */
    const handle_key_down_register = (event) => {
        if (event.key === 'Enter') {
            save_register();
        }
    };

    return (
        <div id="login_site">

            <img id="login_logo" src={login_logo} alt="Login-Logo CPT"></img>

            {
                (loginErrorText)
                    ? (
                        <div className="a-notification -error error_field" role="alert">
                            <i className="a-icon ui-ic-alert-error"></i>
                            <div className="a-notification__content">{loginErrorText}</div>
                            <button type="button" className="a-button a-button--integrated -without-label" onClick={() => setLoginErrorText('')}>
                                <i className="a-icon a-button__icon ui-ic-close" title={language.close}></i>
                            </button>
                        </div>
                    )
                    : null
            }

            <div className="a-text-field">
                <label htmlFor="user">{language.username}</label>
                <input type="text" value={username} autoFocus onChange={(event) => setUsername(event.target.value)} />
            </div>

            <div className="a-password-input">
                <label htmlFor="password">{language.password}</label>
                <input
                    ref={passwordField}
                    type="password"
                    name="input password with label"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    onKeyDown={handle_key_down}
                />
                <button
                    type="button"
                    className="a-password-input__icon-password"
                    onClick={(event) => {
                        if (passwordField.current.type === 'password') {
                            passwordField.current.type = 'text';
                            event.target.parentNode.querySelector('.a-icon').className = 'a-icon ui-ic-watch-off';
                        }
                        else {
                            passwordField.current.type = 'password';
                            event.target.parentNode.querySelector('.a-icon').className = 'a-icon ui-ic-watch-on';
                        }
                    }}
                >
                    <i className="a-icon ui-ic-watch-on" title={language.show}></i>
                </button>
            </div>

            <div className="right">
                <button type="button" className="a-button a-button--primary -without-icon" onClick={save_login}>
                    <span className="a-button__label">{language.login}</span>
                </button>
            </div>

            <div className="right">
                <button type="button" className="a-button a-button--secondary -without-icon" onClick={() => forgotPasswordModal.current.showModal()}>
                    <span className="a-button__label">{language.forgot_pw}</span>
                </button>

                <button
                    type="button"
                    className="a-button a-button--secondary -without-icon"
                    onClick={() => {
                        resetStates();
                        registrationModal.current.showModal();
                    }}
                >
                    <span className="a-button__label">{language.signup}</span>
                </button>
            </div>

            <dialog ref={registrationModal} className="m-dialog -floating-shadow-s -floating">
                <div className="m-dialog__content">
                    <div className="m-dialog__headline">{language.signup}</div>
                    <div className="m-dialog__body form">

                        {
                            (signupErrorText)
                                ? (
                                    <div className="a-notification -error" role="alert">
                                        <i className="a-icon ui-ic-alert-error"></i>
                                        <div className="a-notification__content">{signupErrorText}</div>
                                        <button type="button" className="a-button a-button--integrated -without-label" onClick={() => setSignupErrorText('')}>
                                            <i className="a-icon a-button__icon ui-ic-close" title={language.close}></i>
                                        </button>
                                    </div>
                                )
                                : null
                        }

                        <div className="a-text-field">
                            <label htmlFor="register_username">{language.username} *</label>
                            <input type="text" value={registerUsername} autoFocus onChange={(event) => setRegisterUsername(event.target.value)} />
                        </div>

                        <div className="a-password-input">
                            <label htmlFor="register_password">{language.password} *</label>
                            <input type="password" value={registerPassword} onChange={(event) => setRegisterPassword(event.target.value)} />
                        </div>

                        <div className="a-password-input">
                            <label htmlFor="register_password_repeat">{language.repeat_password} *</label>
                            <input type="password" value={registerRepeatPassword} onChange={(event) => setRegisterRepeatPassword(event.target.value)} onKeyDown={handle_key_down_register} />
                        </div>

                    </div>
                    <div className="m-dialog__actions">
                        <button
                            type="button"
                            className="a-button a-button--secondary -without-icon"
                            onClick={() => {
                                resetStates();
                                registrationModal.current.close();
                            }}
                        >
                            <span className="a-button__label">{language.close}</span>
                        </button>
                        <button type="button" className="a-button a-button--primary -without-icon" onClick={save_register}>
                            <span className="a-button__label">{language.signup}</span>
                        </button>
                    </div>
                </div>
            </dialog>

            <dialog ref={forgotPasswordModal} className="m-dialog -floating-shadow-s -floating" aria-labelledby="dialog-default-dialog-without-header-title">
                <div className="m-dialog__content">
                    <div className="m-dialog__headline">{language.forgot_pw}</div>
                    <div className="m-dialog__body">{language.forgot_pw_text}</div>
                    <div className="m-dialog__actions">
                        <button type="button" className="a-button a-button--primary -without-icon" onClick={() => forgotPasswordModal.current.close()}>
                            <span className="a-button__label">{language.close}</span>
                        </button>
                    </div>
                </div>
            </dialog>

            <dialog ref={registrationSuccessfulModal} className="m-dialog -floating-shadow-s -floating">
                <div className="m-dialog__remark --success"></div>
                <div className="m-dialog__header">
                    <i className="a-icon ui-ic-alert-success" title="success"></i>
                    <div className="m-dialog__title">{language.signup_succeed}</div>
                </div>
                <hr className="a-divider" />
                <div className="m-dialog__content">
                    <div className="m-dialog__headline">{language.signup_succeed}</div>
                    <div className="m-dialog__body">
                        <p>{language.signup_succeed_text}</p>
                        <p>{language.contact_us}</p>
                    </div>
                    <div className="m-dialog__actions">
                        <button type="button" className="a-button a-button--primary -without-icon" onClick={() => registrationSuccessfulModal.current.close()}>
                            <span className="a-button__label">{language.close}</span>
                        </button>
                    </div>
                </div>
            </dialog>

        </div>
    );
}
