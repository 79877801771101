/**
 * function to convert value, depending on temperature type of local storage
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_temperature(value, reverse = false) {
    const temperature = JSON.parse(localStorage.getItem('temperature'));

    switch (temperature) {
        case 'celsius':
            return reverse ? value + 273.15 : value - 273.15;
        case 'fahrenheit':
            return reverse ? (value - 32) * 5 / 9 + 273.15 : (value - 273, 15) * 9 / 5 + 32;
        default:
            return value;
    }
}

/**
 * function to convert value, depending on length unit type of local storage
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_speed(value, reverse = false) {
    const length_unit = JSON.parse(localStorage.getItem('length_unit'));

    switch (length_unit) {
        case 'kilometer':
            return reverse ? value / 3.6 : value * 3.6;
        case 'miles':
            return reverse ? value / 2.23694 : value * 2.23694;
        default:
            return value;
    }
}

/**
 * function to convert radiant to degree
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_rad_to_degree(value, reverse = false) {
    return reverse ? value / 57.95779 : value * 57.95779;
}

/**
 * function to convert rpm to rpmx100
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_to_rpm_x100(value, reverse = false) {
    return reverse ? value * 100 / 60 : value * 60 / 100;
}

/**
 * function to convert pascal to kilo pascal
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_pascal_to_kiloPascal(value, reverse = false) {
    return reverse ? value * 1000 : value / 1000;
}

/**
 * function to convert ampere to milli ampere
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_amp_to_milliAmps(value, reverse = false) {
    return reverse ? value / 1000 : value * 1000;
}

/**
 * function to convert quibic meters per second to liter per hour
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_m3ps_to_lph(value, reverse = false) {
    return reverse ? value / 3600000 : value * 3600000;
}

/**
 * function to convert joules to kwh
 * @param {float} value
 * @param {boolean} reverse - optional
 * @returns {float} converted value
 */
export function convert_j_to_kwh(value, reverse = false) {
    return reverse ? value * 3600000 : value / 3600000;
}

/**
 * function to check local Storage for value and map units
 * @param {string} type
 * @returns unit
 */
export function convert_to_displayable_unit(type) {
    if (type === 'speed') {
        const unit = JSON.parse(localStorage.getItem('length_unit'));
        switch (unit) {
            case 'kilometer':
                return 'km/h';
            case 'miles':
                return 'mph';
            default:
                return 'm/s';
        }
    }
    else if (type === 'temperature') {
        const unit = JSON.parse(localStorage.getItem('temperature'));
        switch (unit) {
            case 'celsius':
                return '°C';
            case 'fahrenheit':
                return '°F';
            default:
                return 'K';
        }
    }

    return 'Not defined';
}
