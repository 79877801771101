import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';

import { language_config } from './config/languages';

/* Import components for pages */
import Nav from './Elements/Nav';
import Main from './Main';
import CustomDashboard from './CustomDashboard';
import History from './History';
import Settings from './Settings';
import Maps from './Maps';
import Login from './Login';
import TMM from './TMM';
import HVPowernet from './HVPowernet';
import ThermalManagement from './ThermalManagement';
import HeatFlow from './HeatFlow';
import SafeHomeModePage from './CVEM/safe-home-mode/pages/SafeHomeModePage';
import ShortTripMode from './CVEM/ShortTripMode/ShortTripMode';
import LastMileMode from './CVEM/LastMileMode/LastMileMode';
import { LastMileModeProvider } from './CVEM/LastMileMode/LastMileModeContext';
import { ShortTripModeProvider } from './CVEM/ShortTripMode/ShortTripModeContext';
import PHDiagram from './PHDiagram';

/* Import self written configs, requests or helpers */
import { requests } from './config/network';
import { SSEProvider } from './RealtimeData/SSEContext';

const language = language_config();

/**
 * function that displays the current side-name
 * @returns {string} Title of Pages
 */
export function set_title() {
    const loc = window.location.pathname;
    switch (loc) {
        case '/':
            return language.dashboard;
        case '/history':
            return language.history;
        case '/custom_dashboard':
            return language.custom_dashboard;
        case '/tmm':
            return language.thermo;
        case '/hvpowernet':
            return language.hvpowernet;
        case '/thermalmanagement':
            return language.thermal_management;
        case '/heatflow':
            return language.heat_flow;
        case '/cvem_shm':
            return language.safe_home_mode;
        case '/cvem_stm':
            return language.short_trip_mode;
        case '/cvem_lmm':
            return language.last_mile_mode;
        case '/maps':
            return language.maps;
        case '/settings':
            return language.settings;
        default:
            console.log('No matching title found.');
            return '';
    }
}

/**
 * Component to manage and combine all pages
 * Router DOM (inclusive Header, Sidebar and Pages), Login and Loading-Page
 * @returns {html}
 */
export default function App() {
    /* UseState-Variables to safe settings-States for the whole project */
    const [ is_dark, set_dark ] = useState(JSON.parse(localStorage.getItem('is_dark')));
    const [ languageName, set_languageName ] = useState(JSON.parse(localStorage.getItem('language')));
    const [ temperature, set_temperature ] = useState(JSON.parse(localStorage.getItem('temperature')));
    const [ length_unit, set_length_unit ] = useState(JSON.parse(localStorage.getItem('length_unit')));

    /* UseState-Variables to safe the token or call him from the local storage */
    const [ token, setToken ] = useState(localStorage.getItem('token') === null ? '' : localStorage.getItem('token'));
    const [ validToken, setValidToken ] = useState(null);

    /* UseState-Variable shared to all pages to trigger update */
    const [ reload, setReload ] = useState(0);

    /* store prefered theme */
    const prefers_dark_scheme = window.matchMedia('(prefers-color-scheme: dark)');

    /* UseState-Variable to store the login error message used in token login */
    const [ errorMessage, setErrorMessage ] = useState('');

    const url = new URL(window.location);

    /**
     * function to switch between light and dark mode
     */
    function handle_theme_change(e) {
        if (e._reactName === 'onChange') {
            set_dark(e.target.checked);
        }
    }

    const title = set_title();
    document.title = title.length ? `Connected Powertrain - ${title}` : 'Connected Powertrain';

    /**
     * function to switch between the different languages used in Settings.js
     * @param {*} e event-Object from language-settings
     */
    function handle_language_change(e) {
        set_languageName(e.target.value);
        window.location.reload(true);
    }

    /**
     * function to switch between the different temperature types used in Settings.js
     * @param {*} e event-Object from temperature-settings
     */
    function handle_temperature_change(e) {
        set_temperature(e.target.value);
        window.location.reload(true);
    }

    /**
     * function to switch between the length units used in Settings.js
     * @param {*} e event-Object from length-settings
     */
    function handle_length_unit_change(e) {
        set_length_unit(e.target.value);
        window.location.reload(true);
    }

    /**
     * useEffect to set the prefered value for the first start
     */
    useEffect(() => {
        if (localStorage.getItem('is_dark') === null) {
            set_dark(prefers_dark_scheme.matches);
        }
        if (localStorage.getItem('language') === null) {
            set_languageName('english'); // default: english
        }
        if (localStorage.getItem('temperature') === null) {
            set_temperature('celsius'); // default: celsius
        }
        if (localStorage.getItem('length_unit') === null) {
            set_length_unit('kilometer'); // default: kilometer
        }

        if (document.getElementById('mode')) {
            document.getElementById('mode').checked = is_dark;
        }
    }, [ is_dark, prefers_dark_scheme.matches ]);

    /**
     * useEffect triggered by [id_dark] and adjust theme corresponding to is_dark
     */
    useEffect(() => {
        /* update the local storage */
        localStorage.setItem('is_dark', JSON.stringify(is_dark));

        /* update the dark mode class */
        document.body.classList.toggle('-light-mode', !is_dark);
        document.getElementById('root').classList.toggle('-primary', !is_dark);

        document.body.classList.toggle('-dark-mode', is_dark);
        document.getElementById('root').classList.toggle('-secondary', is_dark);
    }, [ is_dark ]);

    /**
     * useEffect triggered by [language] and  adjust language corresponding to language and update local storage
     */
    useEffect(() => {
        localStorage.setItem('language', JSON.stringify(languageName));
    }, [ languageName ]);

    /**
     * useEffect triggered by [temperature] and   adjust temperature corresponding to temperature and update local storage
     */
    useEffect(() => {
        localStorage.setItem('temperature', JSON.stringify(temperature));
    }, [ temperature ]);

    /**
     * useEffect triggered by [length_unit] and  adjust length_unit corresponding to length_unit and update local storage
     */
    useEffect(() => {
        localStorage.setItem('length_unit', JSON.stringify(length_unit));
    }, [ length_unit ]);

    /**
     * function to check the token saved in the local storage
     * if token is valid, the validToken is set to true
     */
    function checkToken() {
        const storedToken = localStorage.getItem('token');

        // don't check token if there is no token or login token is used
        if (storedToken === null || url.searchParams.has('token')) {
            setValidToken(false);
            return;
        }

        fetch(requests.validate_token, {
            'method': 'POST',
            'body': JSON.stringify({ 'token': storedToken }),
            'headers': {
                'Content-Type': 'application/json',
                //
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200 && data.valid) {
                    setValidToken(true);
                    if (data.token) {
                        // store new token if received
                        localStorage.setItem('token', data.token);
                    }
                }
                else {
                    setValidToken(false);
                    localStorage.removeItem('token');
                    localStorage.removeItem('username');
                    setErrorMessage(language.token_expired);
                }
            }))
            .catch((error) => {
                console.log('Network Error', error);
                setValidToken(false);
                setErrorMessage(language.network_error);
            });
    }

    /**
     * useEffect to check the token if a new token is entered
     */
    useEffect(() => {
        checkToken();
    }, [ token ]);

    /**
     * check for token in url for logging in
     */
    if (url.searchParams.has('token')) {
        fetch(`${requests.login_token}?token=${url.searchParams.get('token')}`, {
            'headers': {
                'Content-Type': 'application/json',
                //
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 201) {
                    localStorage.setItem('username', data.username);
                    localStorage.setItem('token', data.token);
                    setValidToken(null);
                    window.location.replace(url.origin + url.pathname);
                }
                else {
                    console.warn('Token error:', data.message);
                    localStorage.removeItem('username');
                    localStorage.removeItem('token');
                    setValidToken(false);
                }
            }))
            .catch((error) => {
                console.error('Network Error', error);
                setValidToken(false);
                setErrorMessage(language.network_error);
            });
    }

    /**
     * check if token is valid, not valid or null
     */
    if (validToken === null) {
        /* if validToken is null the Page is not loaded yet and it should show a Loading-Bouncer */
        return (
            <div id="loading_site">
                <BounceLoader />
            </div>
        );
    }
    else if (!validToken) {
        /* if validToken is false, no valid identity is checked in */
        return <Login setValidToken={setValidToken} setToken={setToken} errorMessage={errorMessage} />;
    }
    else {
        return (

            /**
             * mulitpaging with ReactDOM
             * Add Navigation (sidebar) and Header on each site
             * Routes: defines the url and creates the corresponding elements (pages)
             */
            <Router>
                <Nav />
                <Routes>
                    <Route path="/" element={<Main reload={reload} />} />
                    <Route path="/custom_dashboard" element={<CustomDashboard reload={reload} setReload={setReload} />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/maps" element={<Maps reload={reload} />} />
                    <Route path="/settings" element={<Settings handle_theme_change={handle_theme_change} handle_language_change={handle_language_change} handle_temperature_change={handle_temperature_change} handle_length_unit_change={handle_length_unit_change} />} />
                    <Route path="/tmm" element={<TMM />} />
                    <Route path="/hvpowernet" element={<HVPowernet />} />
                    <Route path="/thermalmanagement" element={<ThermalManagement />} />
                    <Route path="/heatflow" element={<HeatFlow />} />
                    <Route path="/cvem_shm" element={<SafeHomeModePage />} />
                    <Route
                        path="/cvem_stm"
                        element={
                            (
                                <SSEProvider url={requests.listen}>
                                    <ShortTripModeProvider>
                                        <ShortTripMode />
                                    </ShortTripModeProvider>
                                </SSEProvider>
                            )
                        }
                    />
                    <Route
                        path="/cvem_lmm"
                        element={
                            (
                                <SSEProvider url={requests.listen}>
                                    <LastMileModeProvider>
                                        <LastMileMode />
                                    </LastMileModeProvider>
                                </SSEProvider>
                            )
                        }
                    />
                    <Route path="/ph_diagram" element={<PHDiagram />} />
                </Routes>
            </Router>
        );
    }
}

