import React, { useEffect, useState } from 'react';
import './OverflowSlider.scss';

export default function OverflowSlider({ id, minValue, maxValue, defaultValue, onChangeCallback, unit = 'km' }) {
    const [ value, setValue ] = useState(defaultValue);

    const [ previousMaxValue, setPreviousMaxValue ] = useState(maxValue);

    const [ realMinValue, setRealMinValue ] = useState(Math.floor(minValue * 0.95));
    const [ realMaxValue, setRealMaxValue ] = useState(Math.ceil(maxValue * 1.05));

    useEffect(() => {
        setRealMinValue(Math.floor(minValue * 0.95));
    }, [ minValue ]);

    useEffect(() => {
        setRealMaxValue(Math.ceil(maxValue * 1.05));
        const maxValueDifference = (previousMaxValue - maxValue);
        setValue((prevValue) => prevValue - maxValueDifference);
        setPreviousMaxValue(maxValue);
    }, [ maxValue ]);

    const handleChange = (e) => {
        const newValue = parseInt(e.target.value);
        if (isNaN(newValue)) {
            return;
        }

        setValue(newValue);
    };

    const emitValue = () => {
        onChangeCallback(value);
    };

    function round(number) {
        if (number === null || number === undefined) {
            return number;
        }

        return parseFloat(number.toFixed(1));
    }

    return (
        <div className="slider-overrideable">
            <div className="titles">
                <label htmlFor={id}>{`${round(minValue)} ${unit}`}</label>
                <label htmlFor={id}>{`${round(maxValue)} ${unit}`}</label>
            </div>
            <div className="slider-container">
                <input
                    id={id}
                    type="range"
                    min={realMinValue}
                    max={realMaxValue}
                    value={value}
                    step={1}
                    className="slider"
                    style={{
                        '--min-slider-percent': `${(minValue - realMinValue) * 100 / (realMaxValue - realMinValue)}%`,
                        '--max-slider-percent': `${100 - (realMaxValue - maxValue) * 100 / (realMaxValue - realMinValue)}%`,
                    }}
                    onChange={handleChange}
                    onMouseUp={emitValue}
                    onTouchEnd={emitValue}
                />
                <div
                    className={`slider-thumb ${value < minValue ? 'gray' : ''} ${value > maxValue ? 'red' : ''}`}
                    style={{ 'left': `calc(${(value - realMinValue) * 100 / (realMaxValue - realMinValue)}% - 3em * ${value * 100 / realMaxValue} / 100)` }}
                >
                    {round(value)} <small>{unit}</small>
                </div>
            </div>
        </div>

    );
}

