/* import colors for plotly */
import { light_colors, dark_colors } from './plotlyColors';

/* define colors for plotly */
let color = '';
JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;

/**
 * function to build data traces from array
 * @param {Array} items
 * @returns {Array} data object
 */
export function get_data(items) {
    const data = [];
    if (items.length !== 0) {
        items.forEach((trace) => {
            const tracerList = [];

            const tracerOrig = {
                'x': [],
                'y': [],
                'mode': 'lines+markers',
                'type': 'scatter',
                'name': '',
            };

            const tracer = tracerOrig;
            Object.values(trace).forEach((element) => {
                if (tracer.name === '') {
                    tracer.name = Object.values(element)[0]._field;
                }

                if (tracer.name !== Object.values(element)[0]._field) {
                    console.log('Unequal', tracer.name, Object.values(element)[0]._field);

                    if (tracer.name !== 'GPS' && tracer.name !== 'latitude' && tracer.name !== 'longitude') {
                        tracerList.push(
                            {
                                'x': tracer.x,
                                'y': tracer.y,
                                'mode': tracer.mode,
                                'type': tracer.type,
                                'name': tracer.name,
                            }
                        );
                    }

                    tracer.x = [];
                    tracer.y = [];
                    tracer.name = Object.values(element)[0]._field;
                }

                tracer.y.push(Object.values(element)[0]._value);
                tracer.x.push(new Date(Object.values(element)[0]._time));
            });

            tracerList.push({
                'x': tracer.x,
                'y': tracer.y,
                'mode': tracer.mode,
                'type': tracer.type,
                'name': tracer.name,
            });

            tracerList.forEach((tracerElement) => {
                if (tracer.name !== 'GPS' && tracer.name !== 'latitude' && tracer.name !== 'longitude') {
                    console.log('Release', tracerElement.name);
                    data.push(tracerElement);    // don't display gps data inside graph
                }
            });
        });
    }

    return data;
}

/**
 * function to build map trace from input Array
 * @param {Array} items
 * @returns {Array} data
 */
export function get_map(items) {
    const data = [];
    const tracer = {
        'lat': [],
        'lon': [],
        'customdata': { 'time': [] },
        'mode': 'lines+markers',
        'type': 'scattermapbox',
        'name': 'latlon',
        'marker': { 'color': color.primary_accent_color },
        'line': { 'color': color.primary_accent_color },
        'hovertemplate': '<extra></extra>',
    };
    if (items.length !== 0) {
        items.forEach((trace) => {
            if (trace !== undefined && Object.values(trace[0])[0]._measurement === 'GPS') {
                Object.values(trace).forEach((element) => {
                    if (Object.values(element)[0]._field === 'latitude') {
                        tracer.lat.push(Object.values(element)[0]._value);
                    }
                    if (Object.values(element)[0]._field === 'longitude') {
                        tracer.lon.push(Object.values(element)[0]._value);
                        tracer.customdata.time.push(Object.values(element)._time);
                    }
                });
            }
        });
    }
    data.push(tracer);
    return data;
}
