import React, { useState, useEffect } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Component for rendering Userlist on Settings-Page
 *
 * @param {boolean} reload props which triggers reloading
 * @param {function} setReload props which sets reload
 * @param {object} users props which has object of users
 * @returns {html} Rendering of List of Users
 */
export default function UserManagment({ reload, setReload }) {
    const [ users, setUsers ] = useState([]);
    const [ openDelete, setOpenDelete ] = useState(null);
    const [ openChangeRole, setOpenChangeRole ] = useState(null);
    const [ openPasswordReset, setOpenPasswordReset ] = useState(null);

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    /**
     * UseEffect triggert by [reload]
     * New Request of Users
     */
    useEffect(() => {
        request_users();
    }, [ reload ]);

    /**
     * function to request all users
     */
    function request_users() {
        /* API-Request to the Fleet-API - request all User */
        fetch(requests.get_users, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setUsers(data);
            });
    }

    /**
     * function to delete a user by username
     * @param {string} username Username of user to be removed
     */
    function request_delete_user(username) {
        /* API-Request to the Fleet-API - delete User */
        fetch(requests.delete_user, {
            'method': 'DELETE',
            'body': JSON.stringify({ 'username': username }),
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    console.log('API-Request for Deleting of:', username);
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.delete_account);
                    setFetchResponse('User deleted.');
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.delete_account);
                    setFetchResponse(`Error deleting user! ${data.message}`);
                }
            }))
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.delete_account);
                setFetchResponse(`Error deleting user! ${error}`);
                setOpenDelete(null);
            })
            .finally(() => {
                setOpenDelete(null);
                setReload(reload + 1);
            });
    }

    /**
     * function to set a new state (Role) for a user
     *
     * @param {string} username Username of the manipulated user
     * @param {string} new_role New Role: 'default' or 'admin'
     */
    function request_set_user_state(username, new_role) {
        let admin_count = 0;

        Object.keys(users).map((user) => {
            if (users[user].role === 'admin') {
                admin_count++;
            }
        });

        if (new_role === 'default' && admin_count < 2) {
            setFetchResponseSuccess(false);
            setFetchResponseTitle(language.change_role);
            setFetchResponse('At least one user must remain admin!');
            setOpenChangeRole(null);
        }

        const request_body = JSON.stringify({
            'username': username,
            'new_role': new_role,
        });

        /* API-Request to the Fleet-API - change state of User */
        fetch(requests.set_role, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.change_role);
                    setFetchResponse('Role changed.');
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.change_role);
                    setFetchResponse(`Error changing role! ${data.message}`);
                }
            }))
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.change_role);
                setFetchResponse(`Error changing role! ${error}`);
            })
            .finally(() => {
                setOpenChangeRole(null);
                setReload(reload + 1);
            });
    }

    /**
     * function to set the authorization status of an user (true or false)
     *
     * @param {string} username Username of user to be changed
     * @param {boolean} authorized State of Authorization to use the application
     */
    function request_set_auth_status(username, authorized) {
        const request_body = JSON.stringify({
            'username': username,
            'new_status': authorized,
        });

        fetch(requests.set_auth_status, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.approve);
                    setFetchResponse('User approved.');
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.approve);
                    setFetchResponse(`Error approving user! ${data.message}`);
                }
            }))
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.approve);
                setFetchResponse(`Error approving user! ${error}`);
            })
            .finally(() => {
                setReload(reload + 1);
            });
    }

    /**
     * @param {string} username username of the account whose password needs to be reset
     */
    function request_user_password_reset(username) {
        // TODO: protect password reset for admins on the backend
        const request_body = JSON.stringify({ 'username': username });

        fetch(requests.reset_password, {
            'method': 'POST',
            'body': request_body,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 201) {
                    const newPassword = data.message.split(':')[1].trim();
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.reset_password);
                    setFetchResponse(`${username}'s new password: ${newPassword}`);
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.reset_password);
                    setFetchResponse(`Error resetting password! ${data.message}`);
                }
            }))
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.reset_password);
                setFetchResponse(`Error resetting password! ${error}`);
            })
            .finally(() => {
                setOpenPasswordReset(null);
            });
    }

    /**
     * Return delete and Role-Buttons if username is not admin or the user itself
     *
     * @param {string} username Username of requested user
     * @returns {html} Return Buttons
     */
    function print_buttons(username) {
        /* Do not return buttons for self */
        if (username === localStorage.getItem('username')) {
            return null;
        }

        /* Return buttons for approve / deny if user is not authorized yet */
        if (!users[username].authorized) {
            return (
                <>
                    <Button
                        icon="checkmark"
                        secondary
                        onClick={() => request_set_auth_status(username, true)}
                    >
                        {language.approve}
                    </Button>
                    <Button
                        icon="close"
                        onClick={() => setOpenDelete(username)}
                    >
                        {language.deny}
                    </Button>
                </>
            );
        }

        return (
            <>
                {/* change role */}
                <Button
                    icon={(users[username].role !== 'admin') ? 'user-tie' : 'user'}
                    secondary
                    onClick={() => setOpenChangeRole(username)}
                >
                    {`${language.change_role_to} ${(users[username].role !== 'admin') ? 'admin' : 'default'}`}

                </Button>

                {/* password reset */}
                {
                    (users[username].role !== 'admin')
                        ? (
                            <Button
                                icon="reset"
                                secondary
                                onClick={() => setOpenPasswordReset(username)}
                            >
                                {language.reset_password}
                            </Button>
                        )
                        : (
                            <Button
                                icon="reset"
                                title="Can't reset admin's passwords"
                                secondary
                                disabled
                            >
                                {language.reset_password}
                            </Button>
                        )
                }

                {/* delete account */}
                {
                    (users[username].role !== 'admin')
                        ? (
                            <Button
                                icon="delete"
                                onClick={() => setOpenDelete(username)}
                            >
                                {language.delete}
                            </Button>
                        )
                        : (
                            <Button
                                icon="delete"
                                title="Admins cannot be deleted"
                                disabled
                            >
                                {language.delete}
                            </Button>
                        )
                }
            </>
        );
    }

    return (
        <>
            <div id="admin_settings" className="settings_block">
                <h4>{language.user_management}</h4>

                <table className="m-table">
                    <thead>
                        <tr>
                            <th>{language.user}</th>
                            <th>{language.role}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(users).map((user, index) => (
                                <tr key={index}>
                                    <td>{user}</td>
                                    <td>{
                                        (!users[user].authorized)
                                            ? <i>not authorized</i>
                                            : users[user].role
                                    }
                                    </td>
                                    <td>{print_buttons(user)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Dialog
                open={openDelete !== null}
                header={language.delete_account}
                level="warning"
                button1={(
                    <Button
                        icon="delete"
                        onClick={() => request_delete_user(openDelete)}
                    >
                        {language.delete}
                    </Button>
                )}
                button2={(
                    <Button
                        icon="close"
                        secondary
                        onClick={() => setOpenDelete(null)}
                    >
                        {language.cancel}
                    </Button>
                )}
            >
                {language.delete_account_text}
            </Dialog>

            <Dialog
                open={openPasswordReset !== null}
                header={language.reset_password}
                level="warning"
                button1={(
                    <Button
                        icon="reset"
                        onClick={() => request_user_password_reset(openPasswordReset)}
                    >
                        {language.reset_password}
                    </Button>
                )}
                button2={(
                    <Button
                        icon="close"
                        secondary
                        onClick={() => setOpenPasswordReset(null)}
                    >
                        {language.cancel}
                    </Button>
                )}
            >
                {language.reset_password_text}
            </Dialog>

            <Dialog
                open={openChangeRole !== null}
                header={language.change_role}
                level="warning"
                button1={(
                    <Button
                        icon="user-beginner"
                        onClick={() => request_set_user_state(openChangeRole, (users[openChangeRole].role !== 'admin') ? 'admin' : 'default')}
                    >
                        {language.change_role}
                    </Button>
                )}
                button2={(
                    <Button
                        icon="close"
                        secondary
                        onClick={() => setOpenChangeRole(null)}
                    >
                        {language.cancel}
                    </Button>
                )}
            >
                {
                    (openChangeRole) !== null
                        ? `${language.change_role_text} ${(users[openChangeRole].role !== 'admin') ? 'admin' : 'default'}?`
                        : null
                }
            </Dialog>

            <Dialog
                header={fetchResponseTitle}
                level={fetchResponseSuccess ? 'success' : 'error'}
                button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                open={fetchResponse.length > 0}
            >
                {fetchResponse}
            </Dialog>
        </>
    );
}
