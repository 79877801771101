/*
 * build grid using react-grid-layout
 * - functions:   get_current_layout(), handle_layout_change(), reset_layout(), build_custom_layout(), exists(id), resize_plots(), elements()
 */
import React, { useRef, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';

import Plotly from 'plotly.js';

/* Layouts for react-grid-layout */
import { default_layouts, tmm_layouts, hvpowernet_layouts, thermal_management_layouts, prediction_layouts } from './gridConfig';

import { topics } from '../RealtimeData/SSE_index';
import { language_config } from '../config/languages';

/* images */
import undo from '../../images/grid/undo.png';
import drag from '../../images/grid/drag.svg';

const language = language_config();

/**
 * Component to return a customizable grid layout
 * @param {Array} plots
 * @returns {Grid} grid with plots inside
 */
export default function Grid({ plots }) {
    /* get actual width to make the grid responsible */
    const ResponsiveReactGridLayout = new WidthProvider(Responsive);
    const grid_layout = useRef('grid_layout');

    /**
     * useState - [xy_grid_layouts, set_xy_layout]
     * store and adjust grid layout on xy page using an object array
     */
    const [ main_grid_layouts, set_main_layout ] = useState(JSON.parse(localStorage.getItem('main_grid_layout')) === null ? default_layouts : { 'lg': JSON.parse(localStorage.getItem('main_grid_layout')) });
    const [ tmm_grid_layout, set_tmm_layout ] = useState(JSON.parse(localStorage.getItem('tmm_grid_layout')) === null ? tmm_layouts : { 'lg': JSON.parse(localStorage.getItem('tmm_grid_layout')) });
    const [ hvpowernet_grid_layout, set_hvpowernet_layout ] = useState(JSON.parse(localStorage.getItem('hvpowernet_grid_layout')) === null ? hvpowernet_layouts : { 'lg': JSON.parse(localStorage.getItem('hvpowernet_grid_layout')) });
    const [ thermal_management_grid_layout, set_thermal_management_layout ] = useState(JSON.parse(localStorage.getItem('thermal_management_grid_layout')) === null ? thermal_management_layouts : { 'lg': JSON.parse(localStorage.getItem('thermal_management_grid_layout')) });
    const [ prediction_layout, set_prediction_layout ] = useState(JSON.parse(localStorage.getItem('prediction_layout')) === null ? prediction_layouts : { 'lg': JSON.parse(localStorage.getItem('prediction_layout')) });
    const [ custom_grid_layouts, set_custom_layout ] = useState(JSON.parse(localStorage.getItem('custom_grid_layout')) === null ? build_custom_layout() : { 'lg': JSON.parse(localStorage.getItem('custom_grid_layout')) });
    const loc = window.location.pathname;

    /**
     * Get the correct layout for the current page
     * @returns {Array} layout
     */
    function get_current_layout() {
        switch (loc) {
            case '/':
                return main_grid_layouts;
            case '/custom_dashboard':
                return custom_grid_layouts;
            case '/tmm':
                return tmm_grid_layout;
            case '/hvpowernet':
                return hvpowernet_grid_layout;
            case '/thermalmanagement':
                return thermal_management_grid_layout;
            case '/prediction':
                return prediction_layout;
            default:
                console.log('No grid defined.');
        }
    }

    /**
     * save current layout to local storage when changes are made
     * @param {Array} layouts
     */
    function handle_layout_change(layouts) {
        switch (loc) {
            case '/':
                localStorage.setItem('main_grid_layout', JSON.stringify(layouts));
                break;
            case '/custom_dashboard':
                localStorage.setItem('custom_grid_layout', JSON.stringify(layouts));
                break;
            case '/tmm':
                localStorage.setItem('tmm_grid_layout', JSON.stringify(layouts));
                break;
            case '/hvpowernet':
                localStorage.setItem('hvpowernet_grid_layout', JSON.stringify(layouts));
                break;
            case '/thermalmanagement':
                localStorage.setItem('thermal_management_grid_layout', JSON.stringify(layouts));
                break;
            case '/prediction':
                localStorage.setItem('prediction_layout', JSON.stringify(layouts));
                break;
            default:
                console.log('No grid defined.');
        }
    }

    /**
     * resets the current layout to the predefined one
     */
    function reset_layout() {
        switch (loc) {
            case '/':
                set_main_layout(default_layouts);
                localStorage.removeItem('main_grid_layout');
                break;
            case '/custom_dashboard':
                set_custom_layout({ 'lg': build_custom_layout() });
                localStorage.removeItem('custom_grid_layout');
                break;
            case '/tmm':
                set_tmm_layout(tmm_layouts);
                localStorage.removeItem('tmm_grid_layout');
                break;
            case '/hvpowernet':
                set_hvpowernet_layout(hvpowernet_layouts);
                localStorage.removeItem('hvpowernet_grid_layout');
                break;
            case '/thermalmanagement':
                set_thermal_management_layout(thermal_management_layouts);
                localStorage.removeItem('thermal_management_grid_layout');
                break;
            case '/prediction':
                set_prediction_layout(prediction_layouts);
                localStorage.removeItem('prediction_layout');
                break;
            default:
                console.log('No grid defined.');
        }
        window.location.reload(false);
    }

    /**
     * define the default layout for CustomDashboard.js
     * @returns {Array} layout
     */
    function build_custom_layout() {
        const custom_layout = [];

        let key, x, y, column = 0, row = 0;
        for (let i = 0; i <= 47; i++) {
            key = (i + 1).toString();
            row++;
            if (i % 3 === 0) {
                row = 0;
                column++;
            }
            x = row * 4;
            y = column * 7;
            custom_layout.push({ 'i': key, 'x': x, 'y': y, 'w': 4, 'h': 7 });
        }
        localStorage.setItem('custom_grid_layout', JSON.stringify(custom_layout));
        return custom_layout;
    }

    /**
     * Function to return if an element exists on the page
     * @param {string} id element name
     * @returns {boolean} true | false
     */
    function exists(id) {
        return (document.getElementById(id));
    }

    /**
     * resizes plots on layout change
     */
    function resize_plots() {
        topics.forEach((element) => {
            element.measurements.forEach((measurement) => {
                if (exists(`${measurement}_scale`)) {
                    Plotly.Plots.resize(`${measurement}_scale`);
                }
                if (exists(`${measurement}_graph`)) {
                    Plotly.Plots.resize(`${measurement}_graph`);
                }
                if (exists('latLong_maps')) {
                    Plotly.Plots.resize('latLong_maps');
                }
                if (exists(`${measurement}_number`)) {
                    Plotly.Plots.resize(`${measurement}_number`);
                }
            });
        });
    }

    /**
     * renders all plots displayd inside the grid
     * @returns {Plots} all plots to display
     */
    function elements() {
        if (loc === '/custom_dashboard' && plots.length > Object.values(custom_grid_layouts.lg).length) {
            set_custom_layout({ 'lg': build_custom_layout() });
        }

        return (
            plots.map((plot, index) => (
                <div key={index + 1} className="grid_element">
                    <div className="drag_handle">
                        <img src={drag} alt="drag"></img>
                    </div>
                    {plot}
                </div>
            ))
        );
    }

    return (
        <>
            <button id="reset" onClick={reset_layout}>
                <img src={undo} alt="reset layout"></img>
                <span className="tooltip">{language.reset_layout}</span>
            </button>
            <ResponsiveReactGridLayout
                ref={grid_layout}
                className="layout"
                cols={{ 'lg': 12, 's': 1 }}
                breakpoints={{ 'lg': 800, 's': 0 }}
                rowHeight={window.innerWidth < 600 ? 40 : (window.innerHeight - 390) / 28}
                layouts={get_current_layout()}
                draggableHandle=".drag_handle"
                onResizeStop={resize_plots}
                onLayoutChange={handle_layout_change}
            >
                {elements()}
            </ResponsiveReactGridLayout>
        </>
    );
}
