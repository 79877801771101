import React from 'react';

export default function TimeDisplay({ seconds }) {
    if (!seconds) {
        seconds = 0;
    }

    const format = (number) => String(number).padStart(2, '0');

    const hours = format(Math.floor(seconds / 3600));
    const minutes = format(Math.floor((seconds % 3600) / 60));

    return (<time>{`${hours}:${minutes}`}</time>);
}
