import styled from 'styled-components';

export const AutoModeCardWrapper = styled.div`
    .slider-overrideable {
        width: 100%;
    }
    .slider-auto-range-wrapper {
        padding-bottom: 0.75rem;
    }
`;
