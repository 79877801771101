import React, { lazy, Suspense } from 'react';

const LazyVehicleLighting = lazy(() => import('./VehicleLighting'));

const VehicleLighting = (props) => (
    <Suspense fallback={null}>
        <LazyVehicleLighting {...props} />
    </Suspense>
);

export default VehicleLighting;
