import React from 'react';

export default function Button({ children, primary, secondary, tertiary, integrated, icon, ...props }) {
    let rank = 'primary';
    if (integrated) {
        rank = 'integrated';
    }
    else if (tertiary) {
        rank = 'tertiary';
    }
    else if (secondary) {
        rank = 'secondary';
    }
    else if (primary) {
        rank = 'primary';
    }

    let className = `a-button a-button--${rank}`;
    if (props.fixed) {
        className += ' -fixed';
    }
    if (icon === undefined || icon === null) {
        className += ' -without-icon';
    }
    if (children === undefined || children === null) {
        className += ' -without-label';
    }

    return (
        <button type="button" className={className} {...props}>
            {(!(icon === undefined || icon === null))
                ? <i className={`a-icon a-button__icon boschicon-bosch-ic-${icon}`}></i>
                : null}
            {(!(children === undefined || children === null))
                ? <span className="a-button__label">{children}</span>
                : null}
        </button>
    );
}
