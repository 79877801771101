/**
 * dictonary to save variables to translate them into the german language
 */
const language_german = {
    'dashboard': 'Dashboard',
    'custom_dashboard': 'Nutzerdefiniert',
    'customized': 'Personalisiert',
    'prediction': 'Prediction',
    'maps': 'Karte',
    'history': 'Historisch',
    'settings': 'Einstellungen',
    'language': 'Sprache',
    'length_unit': 'Längeneinheiten',
    'temperature': 'Temperatur',
    'about': 'Impressum',
    'developer': 'Entwickler',
    'mentor': 'Mentor',
    'module': 'Modul',
    'historical_data': 'Historische Daten',
    'realtime_data': 'Echtzeitdaten',
    'realtime': 'Echtzeit',
    'from': 'von',
    'themes': 'Farbgebung',
    'home': 'Start',
    'collapse': 'einklappen',
    'expand': 'ausklappen',
    'kilometer': 'Kilometer',
    'settings_for_graph': 'Einstellungen für Graphen',
    'scale_of': 'Skala für',
    'graph_of': 'Graph für',
    'save': 'Speichern',
    'on': 'auf',
    'tachometer': 'Tachometer',
    'velocity_plot': 'Geschwindigkeits-Graph',
    'rev_meter': 'Drehzahl-Skala',
    'rotational_speed': 'Drehzahl-Graph',
    'ambient_temperature': 'Umgebungstemperatur',
    'oil_temperature': 'Öltemperatur',
    'gradiant': 'Steigung',
    'time_period': 'Zeitraum vom',
    'to': 'bis',
    'car_id': 'Car ID',
    'value_filter': 'Werte-Filter',
    'measurement': 'Messungen / Measurement',
    'display_type': 'Darstellungstyp',
    'tag_filter': 'Tag-Filter',
    'drives': 'Fahrten',
    'show': 'Anzeigen',
    'error_missing_start': 'Error: Startzeit fehlt!',
    'error_start_for_end': 'Error: Endzeit liegt vor Startzeit!',
    'error_start_after_today': 'Error: Startzeit liegt nach heutigem Tag!',
    'error_end_after_today': 'Error: Endzeit liegt nach heutigem Tag!',
    'error_carId_missing': 'Error: Die CarId muss angegeben werden!',
    'error_select_selected': 'Error: "select" ist ausgewählt!',
    'error_measurement_missing': 'Error: Measurements fehlen, bitte mind. eins wählen!',
    'error_occurred': 'Fehler aufgetreten',
    'error_arguments_missing': 'Error: Argumente fehlen',
    'error_car_already_exists': 'Error: VIN bereits vergeben',
    'error_popup_text_1': 'Kann es sein, dass Sie das erste Mal hier sind? Wenn ja, kann es gut sein, dass Sie dem Zertifikat unserers Servers nicht vertrauen.',
    'error_popup_text_2': 'Um die historischen Daten einsehen zu können, müssen Sie bestätigen, dass Sie unserem Server vertrauen. Dies können Sie unter folgendem Link tun:',
    'error_popup_text_3': 'Anderenfalls kann es an einem anderen Fehler liegen, wie beispielsweise eine fehlende Internetverbindung.',
    'graph': 'Graph',
    'table': 'Tabelle',
    'degree': 'grad',
    'login': 'Login',
    'username': 'Nutzername',
    'password': 'Passwort',
    'repeat_password': 'Passwort',
    'login_all_fields_error': 'All fields must be filled',
    'login_pw_length_error': 'Passwort muss mind. 8 Zeichen enthalten.',
    'login_pw_repeat_error': 'Passwörter stimmen nicht überein.',
    'login_error': 'Nutzername oder Passwort falsch.',
    'login_auth_error': 'Nutzer noch nicht autorisiert.',
    'signup': 'Registrieren',
    'forgot_pw': 'Passwort vergessen',
    'forgot_pw_text': 'Bitte wenden Sie sich für die Zurücksetzung ihres Passworts an die Administration.',
    'close': 'Schließen',
    'signup_succeed': 'Registrierung erfolgreich',
    'signup_succeed_text': 'Es muss nun eine manuelle Authentifizierung für die Nutzung erfolgen. Dies kann eine Weile dauern.',
    'contact_us': 'Bei Fragen können Sie sich gerne an uns wenden.',
    'administration': 'Administration',
    'user_management': 'Nutzer Management',
    'admin': 'Admin',
    'default': 'Nutzer',
    'signup_user_error': 'Nutzer existiert bereits',
    'signup_username_error': 'Nutzername zu kurz',
    'signup_failed_error': 'Registrierung fehlgeschlagen',
    'connection_failed': 'Keine Verbindung',
    'delete': 'Löschen',
    'approve': 'Zulassen',
    'deny': 'Ablehnen',
    'account': 'Account',
    'delete_account': 'Account löschen',
    'delete_account_text': 'Wollen Sie diesen Account wirklich löschen? Damit gehen alle Daten und Autos von diesem Account verloren. Es ist nicht möglich diese Aktion rückgängig zu machen.',
    'delete_account_admin_self_admin': 'Sie wollen Ihren Account löschen? Da Sie Admin sind, ist dies nicht ohne weiteres möglich. Bitte fragen Sie einen anderen Administrator, der Sie zu einem Default-Nutzer machen kann.',
    'delete_car': 'Auto löschen',
    'delete_car_text': 'Wollen Sie dieses Auto wirklich löschen? Damit gehen alle Daten von diesem Auto verloren. Es ist nicht möglich diese Aktion rückgängig zu machen.',
    'delete_model': 'Delete model',
    'delete_model_text': 'Do you really want to delete this model? It is not possible to undo this action.',
    'delete_fleet': 'Delete fleet',
    'delete_fleet_text': 'Do you really want to delete this fleet? It is not possible to undo this action.',
    'delete_dbc': 'Delete DBC file',
    'delete_dbc_text': 'Do you really want to delete this DBC file? It is not possible to undo this action.',
    'cars': 'Autos',
    'car': 'Auto',
    'cancel': 'Abbruch',
    'signout': 'Abmelden',
    'map': 'Karte von',
    'reset_layout': 'Layout-Reset',
    'new_car': 'Neues Auto',
    'new_model': 'Neues Model',
    'model': 'Model',
    'vin': 'Fahrzeug-Identifizierungsnummer',
    'licence_plate': 'Kennzeichen',
    'weight': 'Gewicht',
    'model_name': 'Model Name',
    'manufacturer': 'Hersteller',
    'year': 'Baujahr',
    'cross_section_area': 'Projektionsfläche',
    'rolling_resistance': 'Rollwiederstand',
    'drag_coefficient': 'CW-Wert',
    'error_fields_not_filled': 'Alle Felder müssen ausgefüllt sein!',
    'graph_and_map': 'Graph & Karte',
    'logout': 'Abmelden',
    'user': 'Nutzer',
    'select': 'auswählen...',
    'waiting_api': 'Wartet auf API',
    'network_error': 'Netzwerk Problem',
    'token_expired': 'Session expired',
    'manage_simulator': 'Start/stop Simulator',
    'started': 'gestartet',
    'stopped': 'gestoppt',
    'simulator_error': 'Error: Simulator not available',
    'id': 'ID',
    'vin_short': 'Fahrzeug-ID',
    'dbc': 'DBC',
    'owner': 'Eigentümer',
    'info': 'Info',
    'back': 'zurück',
    'add': 'hinzufügen',
    'physics_api': 'Physik API',
    'constants': 'Konstanten',
    'thermo': 'Thermo',
    'hvpowernet': 'HV Powernet',
    'thermal_management': 'Thermal Management',
    'heat_flow': 'Heat Flow',
    'dropdown_select': 'Select...',
    'login_tokens': 'Login Tokens',
    'token': 'Token',
    'start_date': 'Start Date',
    'end_date': 'End Date',
    'new_login_token': 'Create new login token',
    'delete_login_token': 'Delete login token',
    'confirm_delete_login_token': 'Do you really want to delete this login token?',
    'generate_random': 'Randomize',
    'safe_home_mode': 'Safe Home Mode',
    'short_trip_mode': 'Short Trip Mode',
    'last_mile_mode': 'Last Mile Mode',
};

/**
 * dictonary to save variables to translate them into the english language
 */
const language_english = {
    'dashboard': 'Dashboard',
    'custom_dashboard': 'Custom Dashboard',
    'customized': 'Customized',
    'prediction': 'Prediction',
    'maps': 'Maps',
    'history': 'History',
    'settings': 'Settings',
    'language': 'Language',
    'length_unit': 'Length Unit',
    'temperature': 'Temperature',
    'about': 'About',
    'developer': 'Developer',
    'mentor': 'Mentor',
    'module': 'Module',
    'historical_data': 'Historical Data',
    'realtime_data': 'Realtime Data',
    'realtime': 'Realtime',
    'from': 'from',
    'themes': 'Themes',
    'home': 'Home',
    'collapse': 'Collapse',
    'expand': 'expand',
    'kilometer': 'Kilometer',
    'settings_for_graph': 'Settings for Graph',
    'scale_of': 'Scale of',
    'graph_of': 'Graph of',
    'save': 'Save',
    'edit': 'Edit',
    'on': 'On',
    'tachometer': 'Tachometer',
    'velocity_plot': 'Velocity Plot',
    'rev_meter': 'Rev Meter',
    'rotational_speed': 'Rotational Speed',
    'ambient_temperature': 'Ambient Temperature',
    'oil_temperature': 'Oil Temperature',
    'gradiant': 'Gradiant',
    'time_period': 'Time period from',
    'to': 'to',
    'car_id': 'Car ID',
    'value_filter': 'Value filter',
    'measurement': 'Measurement',
    'display_type': 'Display type',
    'tag_filter': 'Tag filter',
    'drives': 'Drives',
    'show': 'Show',
    'error_missing_start': 'Error: Start time is missing!',
    'error_start_for_end': 'Error: End time is earlyer than start time!',
    'error_start_after_today': 'Error: Start time after current day!',
    'error_end_after_today': 'Error: End time after current day!',
    'error_carId_missing': 'Error: Car ID is missing!',
    'error_select_selected': 'Error: "select" is selected!',
    'error_measurement_missing': 'Error: Measurements missing, please select at least one!',
    'error_occurred': 'Error occurred',
    'error_arguments_missing': 'Error: Missing Arguments',
    'error_car_already_exists': 'Error: VIN already assigned',
    'error_popup_text_1': 'Is it possible, that you are here for the first time? If so, it might be, that you do not trust our server`s certificate.',
    'error_popup_text_2': 'In order to view historical data, you must confirm that you trust our server. You can do this at the following link:',
    'error_popup_text_3': 'Otherwise, there is another error, such as no internet connection.',
    'graph': 'Graph',
    'table': 'Table',
    'degree': 'degree',
    'login': 'Login',
    'username': 'Username',
    'password': 'Password',
    'repeat_password': 'Repeat password',
    'login_all_fields_error': 'All fields must be filled',
    'login_pw_length_error': 'Password must contain at least 8 symbols',
    'login_pw_repeat_error': 'Passwords are not equal',
    'login_error': 'Username or Password wrong',
    'login_auth_error': 'User not authorized',
    'signup': 'Signup',
    'forgot_pw': 'Forgot password',
    'change_password': 'Change password',
    'forgot_pw_text': 'Please contact the administration to reset your password.',
    'signup_succeed': 'Signup successful',
    'signup_succeed_text': 'Manual authentication must now be carried out for use. This may take a while.',
    'contact_us': 'If you have any questions, please do not hesitate to contact the administrator.',
    'close': 'Close',
    'administration': 'Administration',
    'user_management': 'User Management',
    'role': 'Role',
    'change_role': 'Change role',
    'change_role_text': 'Do you want to change the role of this user to',
    'change_role_to': 'Change role to',
    'admin': 'Admin',
    'default': 'User',
    'signup_user_error': 'User already exists',
    'signup_username_error': 'Username too short',
    'signup_failed_error': 'Signup failed',
    'connection_failed': 'Connection failed',
    'delete': 'Delete',
    'approve': 'Approve',
    'deny': 'Deny',
    'account': 'Account',
    'reset_password': 'Reset password',
    'reset_password_text': 'Do you want to reset the password of this account?',
    'change': 'Change',
    'delete_account': 'Delete account',
    'delete_account_text': 'Do you really want to delete this account? All data and cars from this account will then be lost. It is not possible to undo this action.',
    'delete_account_text_self': 'Do you really want to delete your account? All data and cars from your account will then be lost. It is not possible to undo this action.',
    'delete_account_admin_self_admin': 'Do you want to delete your account? Since you are an admin, this is not easily possible. Please ask another administrator who can make you a default user first.',
    'change_account_password': 'Do you want to change your password?',
    'delete_car': 'Delete car',
    'delete_car_text': 'Do you really want to delete this car? All data from this car will then be lost. It is not possible to undo this action.',
    'delete_model': 'Delete model',
    'delete_model_text': 'Do you really want to delete this model? It is not possible to undo this action.',
    'delete_dbc': 'Delete DBC file',
    'delete_dbc_text': 'Do you really want to delete this DBC file? It is not possible to undo this action.',
    'delete_fleet': 'Delete fleet',
    'delete_fleet_text': 'Do you really want to delete this fleet? It is not possible to undo this action.',
    'cars': 'Cars',
    'car': 'Car',
    'fleets': 'Fleets',
    'fleet': 'Fleet',
    'create_fleet': 'New fleet',
    'cancel': 'Cancel',
    'signout': 'Signout',
    'map': 'Map of',
    'reset_layout': 'reset layout',
    'new_car': 'New Car',
    'new_model': 'New Model',
    'show_model': 'Show Model',
    'model': 'Model',
    'models': 'Models',
    'vin': 'Vehicle Identification Number',
    'licence_plate': 'Licence Plate',
    'weight': 'Weight',
    'model_name': 'Model Name',
    'manufacturer': 'Manufacturer',
    'year': 'Year',
    'cross_section_area': 'Cross-section Area',
    'rolling_resistance': 'Rolling Resistance',
    'drag_coefficient': 'Drag Coefficient',
    'error_fields_not_filled': 'All fields musst be filled!',
    'graph_and_map': 'graph & map',
    'logout': 'Logout',
    'user': 'User',
    'select': 'select...',
    'waiting_api': 'Waiting for API',
    'network_error': 'Network Error',
    'token_expired': 'Session expired',
    'manage_simulator': 'Start/stop simulator',
    'started': 'Simulator started',
    'stopped': 'Simulator stopped',
    'simulator_error': 'Error: Simulator not available',
    'id': 'ID',
    'vin_short': 'Vehicle-ID',
    'can_dbc': 'CAN_DBC',
    'uds_dbc': 'UDS_DBC',
    'dbc_file': 'DBC Files',
    'owner': 'Owner',
    'info': 'Info',
    'back': 'Back',
    'add': 'Add',
    'physics_api': 'Physics API',
    'constants': 'Constants',
    'thermo': 'Thermo',
    'dbc_filename': 'DBC filename',
    'upload': 'Upload',
    'choose_file': 'Choose file',
    'no_file_chosen': 'No file chosen',
    'hvpowernet': 'HV Powernet',
    'thermal_management': 'Thermal Management',
    'heat_flow': 'Heat Flow',
    'old_password': 'Old password',
    'new_password': 'New password',
    'confirm_password': 'Confirm new password',
    'dropdown_select': 'Select...',
    'login_tokens': 'Login Tokens',
    'token': 'Token',
    'start_date': 'Start Date',
    'end_date': 'End Date',
    'new_login_token': 'Create new login token',
    'delete_login_token': 'Delete login token',
    'confirm_delete_login_token': 'Do you really want to delete this login token?',
    'generate_random': 'Randomize',
    'safe_home_mode': 'Safe Home Mode',
    'short_trip_mode': 'Short Trip Mode',
    'last_mile_mode': 'Last Mile Mode',
    'ph_diagram': 'Pressure-Enthalpy Diagram',
};

/**
 * dictonary to save variables to translate them into the french language
 */
const language_french = {
    'dashboard': 'Tableau de bord',
    'custom_dashboard': 'Personnalisé',
    'customized': 'Personnalisé',
    'prediction': 'Prediction',
    'maps': 'Carte',
    'history': 'Histoire',
    'settings': 'Réglages',
    'language': 'Langue',
    'length_unit': 'Unité de longueur',
    'temperature': 'Température',
    'about': 'Sur',
    'developer': 'Développeur',
    'mentor': 'Mentor',
    'module': 'Module',
    'historical_data': 'Données historiques',
    'realtime_data': 'temps réel ',
    'realtime': 'temps réel',
    'from': 'de',
    'themes': 'Coloration',
    'home': 'Accueil',
    'collapse': 'Replier',
    'expand': 'Dérouler',
    'kilometer': 'Kilomètre',
    'settings_for_graph': 'Paramètre pour les graphs',
    'scale_of': 'Écelle de',
    'graph_of': 'Graph de',
    'save': 'sauver',
    'on': 'a',
    'tachometer': 'Tachymètre',
    'velocity_plot': 'Graphique de vitesse',
    'rev_meter': 'Vitesse rotationnelle',
    'rotational_speed': 'Vitesse rotationnelle',
    'ambient_temperature': 'Température extérieure',
    'oil_temperature': 'Température de l´huile',
    'gradiant': 'Pente',
    'time_period': 'periode de temps',
    'to': 'à',
    'car_id': 'Car ID',
    'value_filter': 'filtre de valeurs',
    'measurement': 'mesures',
    'display_type': 'Type d´affichage',
    'tag_filter': 'Filtre de balise',
    'drives': 'monte',
    'show': 'montre',
    'error_missing_start': 'Erreur : Heure de début manquante!',
    'error_start_for_end': 'Erreur : l´heure de fin est antérieure à l´heure de début!',
    'error_start_after_today': 'Erreur : l´heure de début est postérieure à aujourd´hui!',
    'error_end_after_today': 'Erreur : l´heure de fin est postérieure à aujourd´hui!',
    'error_carId_missing': 'Erreur : Le CarId doit être spécifié !',
    'error_select_selected': 'Erreur : "select" est sélectionné!',
    'error_measurement_missing': 'Erreur : Il manque des mesures, veuillez en sélectionner au moins une!',
    'error_occurred': 'Erreur est survenue',
    'error_arguments_missing': 'Erreur : arguments manquants',
    'error_car_already_exists': 'Erreur : VIN déjà attribué',
    'error_popup_text_1': 'e pourrait-il que vous soyez ici pour la première fois ? Si tel est le cas, il est possible que vous ne fassiez pas confiance au certificat de notre serveur.',
    'error_popup_text_2': 'Pour afficher les données historiques, vous devez confirmer que vous faites confiance à notre serveur. Vous pouvez le faire sur le lien suivant:',
    'error_popup_text_3': 'Sinon, cela peut être dû à une autre erreur, comme un manque de connexion Internet.',
    'graph': 'graphique',
    'table': 'tableau',
    'degree': 'degré',
    'login': 'connexion',
    'username': 'Nom d´utilisateur',
    'password': 'mot de passe',
    'repeat_password': 'mot de passe',
    'login_all_fields_error': 'All fields must be filled',
    'login_pw_length_error': 'Le mot de passe doit contenir au moins 8 caractères.',
    'login_pw_repeat_error': 'Les mots de passe ne correspondent pas.',
    'login_error': 'Nom d´utilisateur ou mot de passe erroné.',
    'login_auth_error': 'Utilisateur pas encore autorisé.',
    'signup': 'enregistrer',
    'forgot_pw': 'Mot de passe oublié',
    'forgot_pw_text': 'Veuillez contacter l´administration pour réinitialiser votre mot de passe.',
    'signup_succeed': 'Inscription réussi',
    'signup_succeed_text': 'L´authentification manuelle doit maintenant être effectuée pour l´utilisation. Cela peut prendre un peu de temps.',
    'close': 'fermer',
    'contact_us': 'Si vous avez des questions, n´hésitez pas à nous contacter.',
    'administration': 'administration',
    'user_management': 'gestion des utilisateurs',
    'admin': 'admin',
    'default': 'défaut',
    'signup_user_error': 'L´utilisateur existe déjà',
    'signup_username_error': 'Nom d´utilisateur trop court',
    'signup_failed_error': 'échec de l´enregistrement',
    'connection_failed': 'Pas de connection',
    'delete': 'effacer',
    'approve': 'permettre',
    'deny': 'rejeter',
    'account': 'Compte',
    'delete_account': 'Supprimer le compte',
    'delete_account_text': 'Voulez-vous vraiment supprimer ce compte? Toutes les données et voitures de ce compte seront alors perdues. Il n´est pas possible d´annuler cette action.',
    'delete_account_admin_self_admin': 'Voulez-vous supprimer votre compte? Puisque vous êtes un administrateur, ce n´est pas facilement possible. Veuillez demander à un autre administrateur qui peut faire de vous un utilisateur par défaut.',
    'delete_car': 'Supprimer la voiture',
    'delete_car_text': 'Voulez-vous vraiment supprimer cette voiture ? Cela signifie que toutes les données de cette voiture seront perdues. Il n´est pas possible d´annuler cette action.',
    'delete_model': 'Delete model',
    'delete_model_text': 'Do you really want to delete this model? It is not possible to undo this action.',
    'delete_fleet': 'Delete fleet',
    'delete_fleet_text': 'Do you really want to delete this fleet? It is not possible to undo this action.',
    'delete_dbc': 'Delete DBC file',
    'delete_dbc_text': 'Do you really want to delete this DBC file? It is not possible to undo this action.',
    'cars': 'voitures',
    'car': 'voiture',
    'cancel': 'annuler',
    'signout': 'Déconnexion',
    'map': 'Carte de',
    'reset_layout': 'réinitialiser',
    'new_car': 'Nouvelle voiture',
    'new_model': 'nouveau',
    'model': 'maquette',
    'vin': 'vin',
    'licence_plate': 'marquer',
    'weight': 'masse',
    'model_name': 'nom du modèle',
    'manufacturer': 'fabricant',
    'year': 'année',
    'cross_section_area': 'surface de projection',
    'rolling_resistance': 'résistance au roulement',
    'drag_coefficient': 'valeur CW',
    'error_fields_not_filled': 'Tous les champs doivent être remplis!',
    'graph_and_map': 'graphique & carte',
    'logout': 'Déconnexion',
    'user': 'nom',
    'select': 'choisir...',
    'waiting_api': 'En attente d´API',
    'network_error': 'problème de réseau',
    'token_expired': 'Session expired',
    'manage_simulator': 'Gérer le simulateur',
    'started': 'débuté',
    'stopped': 'arrêté',
    'simulator_error': 'Error: Simulator not available',
    'id': 'IDENTIFIANT',
    'vin_short': 'Identification du véhicule',
    'dbc': 'DBC',
    'owner': 'propriétaire',
    'info': 'Info',
    'back': 'revenir',
    'add': 'ajouter',
    'physics_api': '..',
    'constants': '..',
    'thermo': '..',
    'hvpowernet': 'HV Powernet',
    'thermal_management': 'Thermal Management',
    'heat_flow': 'Heat Flow',
    'dropdown_select': 'Select...',
    'login_tokens': 'Login Tokens',
    'token': 'Token',
    'start_date': 'Start Date',
    'end_date': 'End Date',
    'new_login_token': 'Create new login token',
    'delete_login_token': 'Delete login token',
    'confirm_delete_login_token': 'Do you really want to delete this login token?',
    'generate_random': 'Randomize',
    'safe_home_mode': 'Safe Home Mode',
    'short_trip_mode': 'Short Trip Mode',
    'last_mile_mode': 'Last Mile Mode',
};

/**
 * function to get used language from local storage and return language-vocabularies
 * @returns {dictonary} Dictonary of selected language
 */
export function language_config() {
    const language_ = JSON.parse(localStorage.getItem('language'));

    switch (language_) {
        case 'german':
            return { ...language_english, ...language_german };
        case 'french':
            return { ...language_english, ...language_french };
        default:
            return language_english;
    }
}

