import React, { useEffect, useState } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import Dropdown from '../../../frok/Components/Dropdown';
import Input from '../../../frok/Components/Input';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Component to load all login tokens
 *
 * @param {int} reload props, value to trigger update
 * @param {function} setReload  props, function to set the reload
 * @returns {html} return a table of login tokens
 */
export default function LoginTokens({ reload, setReload, users }) {
    const [ loginTokens, setLoginTokens ] = useState({});

    const [ openDeleteLoginTokenPopup, setOpenDeleteLoginTokenPopup ] = useState(null);
    const [ openNewLoginTokenPopup, setOpenNewLoginTokenPopup ] = useState(false);
    const [ createErrorMessage, setCreateErrorMessage ] = useState(null);

    const [ fetchResponse, setFetchResponse ] = useState('');
    const [ fetchResponseTitle, setFetchResponseTitle ] = useState('');
    const [ fetchResponseSuccess, setFetchResponseSuccess ] = useState(false);

    useEffect(() => {
        getLoginTokens();
    }, [ reload ]);

    /**
     * function to fetch login tokens
     */
    function getLoginTokens() {
        fetch(requests.get_login_tokens, {
            'headers': {
                'Authorization': localStorage.getItem('token'),
                //
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setLoginTokens(data);
                }
                else {
                    setLoginTokens({});
                }
            }))
            .catch((error) => {
                console.error('Network Error', error);
                setLoginTokens({});
            });
    }

    /**
     * function to create a new login token
     */
    function createLoginToken() {
        const token = document.querySelector('#login_token').value;
        const username = document.querySelector('#username').value;
        let startDate = document.querySelector('#start_date').value;
        let endDate = document.querySelector('#end_date').value;

        // check if all fields are filled
        if ([ token, username, startDate, endDate ].some((value) => value === '')) {
            setCreateErrorMessage(language.error_fields_not_filled);
            return;
        }

        startDate = (new Date(startDate)).toISOString();
        endDate = (new Date(endDate)).toISOString();

        const requestBody = JSON.stringify({
            'token': token,
            'username': username,
            'start_date': startDate,
            'end_date': endDate,
        });

        fetch(requests.create_login_token, {
            'method': 'POST',
            'headers': {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            'body': requestBody,
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 201) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.new_login_token);
                    setFetchResponse(data.message);
                    setOpenNewLoginTokenPopup(false);
                    setReload(reload + 1);
                }
                else {
                    setCreateErrorMessage(data.message);
                }
            }))
            .catch((error) => {
                setCreateErrorMessage(`${language.network_error}: ${error.message}`);
            });
    }

    /**
     * function to delete a login token
     */
    function deleteLoginToken(token) {
        const requestBody = JSON.stringify({ 'token': token });
        fetch(requests.delete_login_token, {
            'method': 'DELETE',
            'headers': {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            'body': requestBody,
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setFetchResponseSuccess(true);
                    setFetchResponseTitle(language.delete_login_token);
                    setFetchResponse(data.message);
                }
                else {
                    setFetchResponseSuccess(false);
                    setFetchResponseTitle(language.delete_login_token);
                    setFetchResponse(`Error while deleting login token! ${data.message}`);
                }
            }))
            .catch((error) => {
                setFetchResponseSuccess(false);
                setFetchResponseTitle(language.delete_login_token);
                setFetchResponse(`Error while deleting login token! ${error}`);
            })
            .finally(() => {
                setReload(reload + 1);
                setOpenDeleteLoginTokenPopup(null);
            });
    }

    /**
     * Create login token element
     *
     * @param {int} tokenString Token string
     * @param {Object} tokenString Data of the login token to be printed
     * @returns {html} Table row of the login token
     */
    function printLoginTokenRow(tokenString, tokenProperties) {
        return (
            <tr key={tokenString}>
                <td style={{ 'fontFamily': 'monospace' }}>{tokenString}</td>
                <td>{tokenProperties.username}</td>
                <td>{(new Date(tokenProperties.start_date)).toLocaleString()}</td>
                <td>{(new Date(tokenProperties.end_date)).toLocaleString()}</td>
                <td>
                    <Button
                        icon="delete"
                        onClick={() => setOpenDeleteLoginTokenPopup(tokenString)}
                    >
                        {language.delete}
                    </Button>
                </td>
            </tr>
        );
    }

    return (
        <>
            <div id="token_settings" className="settings_block">
                <h4>{language.login_tokens}</h4>
                <table>
                    <thead>
                        <tr>
                            <th>{language.token}</th>
                            <th>{language.username}</th>
                            <th>{language.start_date}</th>
                            <th>{language.end_date}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (Object.keys(loginTokens).length > 0)
                                ? Object.entries(loginTokens).map(([ tokenString, tokenProperties ]) => printLoginTokenRow(tokenString, tokenProperties))
                                : <tr><th colSpan="5"><i>No login tokens.</i></th></tr>
                        }
                    </tbody>
                </table>
                <p>
                    <Button
                        icon="add"
                        onClick={() => {
                            setCreateErrorMessage(null);
                            setOpenNewLoginTokenPopup(true);
                        }}
                    >{language.new_login_token}
                    </Button>
                </p>
            </div>

            <Dialog
                open={Boolean(openDeleteLoginTokenPopup)}
                header={language.delete_login_token}
                level="warning"
                button1={(
                    <Button
                        icon="delete"
                        onClick={() => {
                            deleteLoginToken(openDeleteLoginTokenPopup);
                            setOpenDeleteLoginTokenPopup(null);
                        }}
                    >
                        {language.delete}
                    </Button>
                )}
                button2={(
                    <Button
                        icon="close"
                        secondary
                        onClick={() => setOpenDeleteLoginTokenPopup(null)}
                    >
                        {language.cancel}
                    </Button>
                )}
            >
                <p>{language.confirm_delete_login_token}</p>
                <p>{openDeleteLoginTokenPopup}</p>
            </Dialog>

            <Dialog
                open={openNewLoginTokenPopup}
                header={language.new_login_token}
                button1={(
                    <Button onClick={createLoginToken}>
                        {language.save}
                    </Button>
                )}
                close={() => setOpenNewLoginTokenPopup(false)}
            >
                <div className="o-form__row">
                    <div className="m-form-field">
                        <Input
                            id="login_token"
                            label={`${language.token} *`}
                        />
                    </div>
                    <div className="m-form-field -quarter">
                        <Button
                            icon="binary"
                            onClick={() => {
                                document.querySelector('#login_token').value = window.crypto.randomUUID();
                            }}
                        >
                            {language.generate_random}
                        </Button>
                    </div>
                </div>

                <div className="m-form-field">
                    <Dropdown
                        id="username"
                        name="username"
                        label={`${language.username} *`}
                    >
                        {Object.keys(users).map((user, index) => (
                            <option
                                key={index}
                                value={(users[user].role === 'admin') ? null : user}
                                disabled={(users[user].role === 'admin')}
                            >
                                {`${user}${(users[user].role === 'admin') ? ' - ADMIN USER' : ''}`}
                            </option>
                        ))}
                    </Dropdown>
                </div>

                <div className="m-form-field">
                    <Input
                        id="start_date"
                        name="start_date"
                        type="datetime-local"
                        label={`${language.start_date} *`}
                    />
                </div>

                <div className="m-form-field">
                    <Input
                        id="end_date"
                        name="end_date"
                        type="datetime-local"
                        label={`${language.end_date} *`}
                    />
                </div>

                <NotificationBar type="error">{createErrorMessage}</NotificationBar>
            </Dialog>

            <Dialog
                header={fetchResponseTitle}
                level={fetchResponseSuccess ? 'success' : 'error'}
                button1={(<Button onClick={() => setFetchResponse('')}>{language.close}</Button>)}
                open={fetchResponse.length > 0}
            >
                {fetchResponse}
            </Dialog>
        </>
    );
}
