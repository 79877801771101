import React, { useEffect, useState } from 'react';
import DrivingTab from './DrivingTab';
import ResultsTab from './ResultTab/ResultsTab';
import request_sse, { safehomemode_state, set_topics } from '../../../RealtimeData/SSE_index';
import { initialize_safehomemode_state } from '../../SafeHomeModeState';
import NoData from '../../NoData/NoData';
import Navbar from '../components/NavBar/NavBar';

/**
 * Display current state of Safe Home Mode dashboard
 */
export default function SafeHomeModePage() {
    const [ hasSignal, setHasSignal ] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(0);

    const [ ComEmac_iDcLnk01, setComEmac_iDcLnk01 ] = useState(0);
    const [ ComEmac_uDcLnk01, setComEmac_uDcLnk01 ] = useState(0);
    const [ Com_iCmprInp, setCom_iCmprInp ] = useState(0);
    const [ Com_iPtcAct, setCom_iPtcAct ] = useState(0);
    const [ VehCoorn_dstRngPrdnDrvgCnd0, setVehCoorn_dstRngPrdnDrvgCnd0 ] = useState(0);
    const [ VehCoorn_dstRngPrdnDrvgCnd1, setVehCoorn_dstRngPrdnDrvgCnd1 ] = useState(0);
    const [ VehCoorn_dstRngPred, setVehCoorn_dstRngPred ] = useState(0);
    const [ Com_dstOdo, setCom_dstOdo ] = useState(0);
    const [ Com_uCmprInp, setCom_uCmprInp ] = useState(0);
    const [ Com_uPtcAct, setCom_uPtcAct ] = useState(0);
    const [ Com_egyHvbAvl_f, setCom_egyHvbAvl_f ] = useState(0);

    /**
     * useEffect to request and activate realtime data
     */
    useEffect(() => {
        set_topics();
        initialize_safehomemode_state();
        request_sse();

        const interval = setInterval(() => {
            if (safehomemode_state?.measurements) {
                // TODO: check again later
                if (safehomemode_state.measurements?.ComEmac_iDcLnk01.value !== null
                    && safehomemode_state.measurements?.ComEmac_iDcLnk01.timestamp !== 0) {
                    setHasSignal(true);
                }
                setComEmac_iDcLnk01({ ...safehomemode_state.measurements?.ComEmac_iDcLnk01 });
                setComEmac_uDcLnk01({ ...safehomemode_state.measurements?.ComEmac_uDcLnk01 });
                setCom_iCmprInp({ ...safehomemode_state.measurements?.Com_iCmprInp });
                setCom_iPtcAct({ ...safehomemode_state.measurements?.Com_iPtcAct });
                setVehCoorn_dstRngPrdnDrvgCnd0({ ...safehomemode_state.measurements?.VehCoorn_dstRngPrdnDrvgCnd_0 });
                setVehCoorn_dstRngPrdnDrvgCnd1({ ...safehomemode_state.measurements?.VehCoorn_dstRngPrdnDrvgCnd_1 });
                setVehCoorn_dstRngPred({ ...safehomemode_state.measurements?.VehCoorn_dstRngPred });
                setCom_dstOdo({ ...safehomemode_state.measurements?.Com_dstOdo });
                setCom_uCmprInp({ ...safehomemode_state.measurements?.Com_uCmprInp });
                setCom_uPtcAct({ ...safehomemode_state.measurements?.Com_uPtcAct });
                setCom_egyHvbAvl_f({ ...safehomemode_state.measurements?.Com_egyHvbAvl_f });
            }
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const navItems = [ 'Driving', 'Results' ];

    const handleChangeTab = (index) => {
        setSelectedIndex(index);
    };

    const renderTabContent = () => {
        switch (selectedIndex) {
            case 0: return (
                <DrivingTab
                    ComEmac_iDcLnk01={ComEmac_iDcLnk01}
                    ComEmac_uDcLnk01={ComEmac_uDcLnk01}
                    Com_iCmprInp={Com_iCmprInp}
                    Com_iPtcAct={Com_iPtcAct}
                    VehCoorn_dstRngPrdnDrvgCnd0={VehCoorn_dstRngPrdnDrvgCnd0}
                    VehCoorn_dstRngPrdnDrvgCnd1={VehCoorn_dstRngPrdnDrvgCnd1}
                    VehCoorn_dstRngPred={VehCoorn_dstRngPred}
                    Com_dstOdo={Com_dstOdo}
                    Com_uCmprInp={Com_uCmprInp}
                    Com_uPtcAct={Com_uPtcAct}
                    Com_egyHvbAvl_f={Com_egyHvbAvl_f}
                />
            );

            /*
             * case 1: return <StandstillManualTab />;
             * case 2: return <StandstillAutomaticTab />;
             */
            case 1: return <ResultsTab />;
        }
    };

    return (
        <main id="cvem_shm" style={{ 'minWidth': '1024px' }} className="no_padding cvem">
            {hasSignal
                ? (<>
                    <Navbar
                        navItems={navItems}
                        selectedIndex={selectedIndex}
                        changeTabCallback={handleChangeTab}
                    />
                    {renderTabContent()}
                </>)
                : <NoData title="No signal" icon="data-loss" large={true} />}

        </main>
    );
}
