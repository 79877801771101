import Toggle from './safe-home-mode/components/Toggle/Toogle';
import React, { useState } from 'react';
import OverflowSlider from './safe-home-mode/components/OverflowSlider/OverflowSlider';

export default function AutoModeCard({ number, minRange, maxRange, activeMode, changeCallback }) {
    const defaultAvgRange = Math.floor((minRange + maxRange) / 2);
    const [ range, setRange ] = useState(defaultAvgRange);

    /*
     * const [ mockedMinRange, setMockedMinRange ] = useState(minRange);
     * const [ mockedMaxRange, setMockedMaxRange ] = useState(maxRange);
     *
     * useEffect(() => {
     *     const interval = setInterval(() => {
     *         const step = 1;
     *         const setter = (prevValue) => prevValue - step;
     *         setMockedMinRange(setter);
     *         setMockedMaxRange(setter);
     *         console.log('inter', mockedMinRange);
     *     }, 1000);
     *
     *     return () => clearInterval(interval);
     * }, [ ]);
     */

    const handleToggleMode = (isChecked) => {
        const unselectedModeNumber = 0;
        changeCallback(isChecked ? number : unselectedModeNumber, range);
    };

    const handleChangeRange = (value) => {
        setRange(value);
        if (activeMode === 4) {
            changeCallback(number, range);
        }
    };

    return (
        <div className="a-box -floating-shadow-s mode">
            <div className="name">
                <span>Auto</span>
            </div>
            <div className="properties wide">
                {(minRange && maxRange)
                    ? <OverflowSlider id="slider_auto_range" minValue={minRange} maxValue={maxRange} defaultValue={defaultAvgRange} onChangeCallback={handleChangeRange} />
                    : null}
            </div>
            <div className="toggle">
                <Toggle name={'auto'} changeCallback={handleToggleMode} />
            </div>
        </div>
    );
}
