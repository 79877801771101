/**
 * define light mode colors in JS
 */
export const light_colors = {
    'primary_accent_color': '#78be20',        /* bosch_light_green */
    'secundary_accent_color': '#5a8e18',      /* bosch_light_green_b25 */
    'text_color': 'black',
    'background': '#dfdfe0',                  /* bosch-light-gray-w50 */
    'primary_background_color': 'white',

    'indicator_green': '#78be20',             /* bosch_light_green */
    'indicator_orange': '#fcaf17',            /* bosch_yellow */
    'indicator_red': '#ea0016',               /* bosch_red */

    'hover_color': '#b4b4b4',
};

/**
 * define dark mode colors in JS
 */
export const dark_colors = {
    'primary_accent_color': '#5a8e18',        /* bosch-light-green-b25 */
    'secundary_accent_color': '#78be20',      /* bosch-light-green */
    // text_color: "#dfdfe0",               /* bosch-light-gray-w50 */
    'text_color': '#A0A0A0',
    'background': '#15181b',                  /* bosch-light-gray-b25 */
    'primary_background_color': '#303031',

    'indicator_green': '#5a8e18',             /* bosch_light_green_b25 */
    'indicator_orange': '#e18800',            /* bosch-orange-65 */
    'indicator_red': '#680001',               /* bosch-rot-20 */

    'hover_color': '#7d7d7d',
};
