import React, { useEffect } from 'react';

import request_sse, { set_topics, thermomanagement_state } from './RealtimeData/SSE_index';
import { initialize_thermomanagement_state, initialize_thermomanagement_timer } from './Thermomanagement';
import { exists, formatNumber } from './SVGUtils';

import { ReactComponent as ThermalManagement_SVG } from '../images/svg/thermal_management.svg';

/**
 * Display current state in SVG
 */
export function draw_thermal_management(state) {
    if (!exists('ThermalManagement_SVG')) {
        return;
    }

    const EXPIRATION_DELAY_MS = 10000;

    const EXPIRATION_DATE = new Date(Date.now() - EXPIRATION_DELAY_MS);

    /**
     * measurements
     */
    for (const measurement in state.measurements) {
        const textElem = exists(`text_${measurement}`);
        if (textElem) {
            const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
            let value = state.measurements[measurement].value;
            if (value === undefined || value === null) {
                value = '?';
            }
            else {
                value = formatNumber(value, textElem);
            }
            textElem.innerHTML = `${value}${unit}`;

            textElem.classList.toggle('expired', state.measurements[measurement].timestamp < EXPIRATION_DATE);
        }
    }

    /**
     * animate elements
     */
    exists('radiator_fan').classList.toggle('animate', Boolean(state.measurements.Fan_nDes.value) && (state.measurements.Fan_nDes.timestamp >= EXPIRATION_DATE));
    exists('evaporator_fan').classList.toggle('animate', Boolean(state.measurements.Blwr_ratAct.value) && (state.measurements.Blwr_ratAct.timestamp >= EXPIRATION_DATE));

    /**
     * line thickness
     */
    const V_C_THRESHOLD = 0.05;
    const pump2_on = state.measurements.V_C_PMP2.value > V_C_THRESHOLD;
    const pump3_on = state.measurements.V_C_PMP3.value > V_C_THRESHOLD;
    const valveTowardsHC = state.measurements.Com_agHeatrVlvCurrPosn.value > 0;

    /* eslint-disable key-spacing */
    const lineThicknessBinaryState = {
        /**
         * binary states for line thickness mapping
         * 0 = thin, 1 = thick
         *
         * integer                        0  1  2  3  4  5  6  7
         *
         * 1: pump2_on                    0  1  0  1  0  1  0  1
         * 2: pump3_on                    0  0  1  1  0  0  1  1
         * 4: valveTowardsHC              0  0  0  0  1  1  1  1
         */
        'arrow_pump2_ptcHeater':        [ 0, 1, 0, 1, 0, 1, 0, 1 ],
        'arrow_ptcHeater_valve':        [ 0, 1, 0, 1, 0, 1, 0, 1 ],
        'arrow_valve_heaterCore':       [ 0, 0, 0, 0, 0, 1, 0, 1 ],
        'arrow_heaterCore_pump2':       [ 0, 0, 0, 0, 0, 1, 0, 1 ],
        'arrow_diverge_pump2':          [ 0, 1, 0, 1, 0, 0, 0, 1 ],
        'arrow_pump2':                  [ 0, 1, 0, 1, 0, 1, 0, 1 ],
        'arrow_valve_checkValve1':      [ 0, 1, 0, 1, 0, 0, 0, 0 ],
        'arrow_checkValve1_merge':      [ 0, 1, 0, 1, 0, 0, 0, 0 ],
        'arrow_pump3_checkValve2':      [ 0, 0, 1, 1, 0, 0, 1, 1 ],
        'arrow_checkValve2_merge':      [ 0, 0, 1, 1, 0, 0, 1, 1 ],
        'arrow_merge_hvBattery':        [ 0, 1, 1, 1, 0, 0, 1, 1 ],
        'arrow_hvBattery_obc':          [ 0, 1, 1, 1, 0, 0, 1, 1 ],
        'arrow_hvBattery_diverge':      [ 0, 1, 1, 1, 0, 0, 1, 1 ],
        'arrow_obc_heatExchanger':      [ 0, 1, 1, 1, 0, 0, 1, 1 ],
        'arrow_diverge_pump3':          [ 0, 0, 1, 1, 0, 0, 1, 1 ],
        'arrow_valve_to_battery':       [ 0, 1, 0, 1, 0, 0, 0, 0 ],
        'arrow_valve_to_heaterCore':    [ 0, 0, 0, 0, 0, 1, 0, 1 ],
    };
    /* eslint-enable key-spacing */

    Object.entries(lineThicknessBinaryState).forEach(([ id, states ]) => {
        const key = 1 * pump2_on + 2 * pump3_on + 4 * valveTowardsHC; // eslint-disable-line no-implicit-coercion
        exists(id).classList.toggle('thin', !states[key]);
    });

    /**
     * valve position
     */
    exists('valve_heat_cabin').style.display = valveTowardsHC ? 'block' : 'none';
    exists('valve_heat_battery').style.display = valveTowardsHC ? 'none' : 'block';
}

/**
 * Page to show SVGs and Animations
 */
export default function ThermalManagement() {
    /**
     * useEffect to request and activate realtime data
     */
    useEffect(() => {
        set_topics();
        initialize_thermomanagement_state();
        initialize_thermomanagement_timer(() => {
            draw_thermal_management(thermomanagement_state);
        });
        request_sse();
    }, []);

    return (
        <main id="thermal_management_page" className="no_padding svg">
            <ThermalManagement_SVG />
        </main>
    );
}
