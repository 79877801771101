import { topics, thermomanagement_state } from './RealtimeData/SSE_index';

// Thermomanagement state
let thermomanagement_update_timer = null;

export function initialize_thermomanagement_state() {
    thermomanagement_state.counter = 0;

    thermomanagement_state.measurements = {};
    topics.filter((t) => (t.type === 'thermomanagement'))
        .flatMap((t) => t.measurements)
        .forEach((t) => {
            thermomanagement_state.measurements[t] = {
                'value': null,
                'timestamp': 0,
            };
        });
}

export function initialize_thermomanagement_timer(fn) {
    clearInterval(thermomanagement_update_timer);

    thermomanagement_update_timer = setInterval(() => {
        fn();
        thermomanagement_state.counter++;
    }, 200);
}
