import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';

/* FROK */
// import './frok/frontend-kit.css';
import '@bosch/frontend.kit-npm/dist/frontend-kit.css';

/* all own-created css fiels */
import './css/style.scss';
import './css/customized.scss';
import './css/sidebar.scss';
import './css/settings.scss';
import './css/history.scss';
import './css/login.scss';
import './css/svg.scss';
import './css/cvem.scss';

/* css files for react grid layout */
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import './i18n';

/* create a root-Object that renders all elements implemented in App */
createRoot(document.getElementById('root')).render(<App />);
