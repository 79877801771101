import React, { useEffect, useState } from 'react';

import Button from '../../../frok/Components/Button';
import Dialog from '../../../frok/Components/Dialog';
import Input from '../../../frok/Components/Input';
import NotificationBar from '../../../frok/Components/NotificationBar';

/* Import self written configs, requests or helpers */
import { requests } from '../../config/network';
import { language_config } from '../../config/languages';

const language = language_config();

/**
 * Component to create a list of existing constants in physic module on the settings page
 *
 * @param {boolean} admin props, if user is admin
 * @param {int} reload trigger for reloading the page
 * @param {function} setReload function to set reload
 */
export default function RequestPhysics({ admin, reload, setReload }) {
    const [ constants, setConstants ] = useState([]);
    const [ openConstantsPopup, setOpenConstantsPopup ] = useState(false);
    const [ selectedConst, setSelectedConst ] = useState({ 'name': '', 'value': '' });
    const [ errorText, setErrorText ] = useState('');

    /**
     * Reload the Page on Trigger [reload]
     */
    useEffect(() => {
        fetch(requests.constants)
            .then((response) => response.json().then((data) => {
                setConstants(data);
            }))
            .catch((error) => {
                console.error(`Error fetching Physics constants: ${error}`);
            });
    }, [ reload ]);

    /**
     * Content-Line of Constants
     * @param {string} constantName Name of Constant
     * @param {int} constantValue Value of Constant
     * @returns
     */
    function print_constants(constantName, constantValue) {
        return (
            <tr key={constantName}>
                <td>{constantName}</td>
                <td>{constantValue}</td>
                <td>
                    <Button
                        icon="edit"
                        onClick={() => {
                            setErrorText('');
                            setOpenConstantsPopup(true);
                            setSelectedConst({ 'value': constantValue, 'name': constantName });
                        }}
                    >
                        {language.edit}
                    </Button>
                </td>
            </tr>
        );
    }

    /**
     * Request Physics-Backend and add new value to constant
     */
    function saveConst() {
        fetch(`${requests.constants}/${selectedConst.name}`, {
            'method': 'PUT',
            'body': JSON.stringify({ 'value': selectedConst.value }),
            'headers': {
                'Content-Type': 'application/json',
                //
            },
        })
            .then((response) => response.json().then((data) => {
                if (response.status === 200) {
                    setOpenConstantsPopup(false);
                }
                else {
                    setErrorText(`Error: ${data.message}`);
                }
                setReload(reload + 1);
            }))
            .catch((error) => {
                setErrorText(`Error: ${error}`);
            });
    }

    return (admin)
        ? (
            <div id="physics_settings" className="settings_block">
                <h4>{language.constants}</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (Object.keys(constants).length > 0)
                                ? Object.keys(constants).map((constant) => print_constants(constants[constant].name, constants[constant].value))
                                : <tr><th colSpan="3"><i>No constants.</i></th></tr>
                        }
                    </tbody>
                </table>

                <Dialog
                    open={openConstantsPopup}
                    header={<>{language.edit} <i>{selectedConst.name}</i></>}
                    button1={(
                        <Button
                            icon="save"
                            onClick={saveConst}
                        >
                            {language.save}
                        </Button>
                    )}
                    close={() => setOpenConstantsPopup(false)}
                >
                    <div className="m-form-field">
                        <Input
                            type="number"
                            label="Value"
                            defaultValue={selectedConst.value}
                            onChange={(event) => setSelectedConst({ 'value': event.target.value, 'name': selectedConst.name })}
                        />
                    </div>
                    <NotificationBar type="error">{errorText}</NotificationBar>
                </Dialog>
            </div>)
        : null;
}
