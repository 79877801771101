import React, { useEffect } from 'react';

import { Container, Row, Col } from 'react-grid-system';
import './ShortTripMode.scss';
import LazyEnvironmentTemperature from './components/EnvironmentTemperature/EnvironmentTemperature.lazy';
import LazyMeasures from './components/Measures/Measures.lazy';
import LazySTMResult from './components/STMResult/STMResult.lazy';
import { useSSE } from '../../RealtimeData/SSEContext';
import { useShortTripMode } from './ShortTripModeContext';
import { selectMeasurementsByTopicType } from '../../RealtimeData/SSETopicsUtil';
import { safeHomeModeTopicKey, safeHomeModeTopicType, shortTripModeTopicKey, shortTripModeTopicType } from '../../RealtimeData/SSETopics';
import { MessageProvider } from '../../MessageDialog/MessageContext';
import MessageDialog from '../../MessageDialog/MessageDialog';
import AutoModeCard from './components/AutoModeCard/AutoModeCard';
import { ShortTripModeWrapper } from './ShortTripMode.styled';

/**
 * Display current state of Safe Home Mode dashboard
 */
export default function ShortTripMode() {
    const { setVCUSignal, setVCUShortTripModeSignal } = useShortTripMode();

    const { messages } = useSSE();

    useEffect(() => {
        const carId = parseInt(localStorage.getItem('car_id'));
        const measurements = selectMeasurementsByTopicType(messages.measurements?.[carId]?.[shortTripModeTopicKey] || {}, shortTripModeTopicType);
        const stmMeasurements = selectMeasurementsByTopicType(messages.measurements?.[carId]?.[safeHomeModeTopicKey] || {}, safeHomeModeTopicType);
        setVCUSignal({
            'VehCoorn_dstRngPred': measurements.VehCoorn_dstRngPred,
            'Com_egyHvbAvl_f': measurements.Com_egyHvbAvl_f,
            'VehEgyMngt_vMaxLim': measurements.VehEgyMngt_vMaxLim,
            'VehEgyMngt_stCbnCdng': measurements.VehEgyMngt_stCbnCdng,
            'Com_tEnv': measurements.Com_tEnv,
            'Com_tCbn': measurements.Com_tCbn,
            'ThermPrepn_wElecDmndPreCdng': measurements.ThermPrepn_wElecDmndPreCdng,
            'VehEgyMngt_stHiUBattCdng': measurements.VehEgyMngt_stHiUBattCdng,
            'VehEgyMngt_wElecLimCdngBatt': measurements.VehEgyMngt_wElecLimCdngBatt,
        });
        setVCUShortTripModeSignal({
            'VehCoorn_dstRngPrdnDrvgCnd_0': stmMeasurements.VehCoorn_dstRngPrdnDrvgCnd_0,
            'VehCoorn_dstRngPrdnDrvgCnd_1': stmMeasurements.VehCoorn_dstRngPrdnDrvgCnd_1,
        });
    }, [ messages ]);

    return (
        <MessageProvider>
            <main className="main-content">
                <ShortTripModeWrapper>
                    <Container fluid>
                        <Row className="content">
                            <Col xl={6} lg={6} sm={12} className="mb-0">
                                <Row>
                                    <Col sm={12} className="mb-1">
                                        <LazyEnvironmentTemperature />
                                    </Col>
                                    <Col sm={12} className="mb-1">
                                        <LazySTMResult />
                                    </Col>
                                    <Col sm={12} className="mb-1">
                                        <AutoModeCard number={4} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                <LazyMeasures />
                            </Col>
                        </Row>
                    </Container>
                </ShortTripModeWrapper>
            </main>
            <MessageDialog />
        </MessageProvider>
    );
}
