import React from 'react';
import { useEffect } from 'react';

/* Import own components */
import Grid from './Grid/Grid';
import { plots } from './Elements/Plots/Plot';

/* Import self written configs, requests or helpers */
import request_sse, { set_topics } from './RealtimeData/SSE_index';

/**
 * Main-Page to show default data-plots
 * @param {*} reload trigger for reloading page
 * @returns {html} Main-Page
 */
export default function Main({ reload }) {
    /**
     * useEffect to request and activate realtime data
     */
    useEffect(() => {
        set_topics();
        request_sse();
    }, []);

    /**
     * function triggered by [reload] to Trigger update (e.g. on change of car_id)
     */
    useEffect(() => {}, [ reload ]);

    return (
        // print all plots (scales, graphs and maps)
        <main id="main_site" className="no_padding">
            <Grid
                // TODO: Anpassung der Skalen an aktuelle Längeneinheit
                plots={[
                    plots.plot_graph('Cnv_vVehAvrg1s_graph', 'averageVehicleVelocity'),
                    plots.plot_graph('temp_c_graph'),
                    plots.plot_graph('Cnv_cpVehAvlEgyAct_graph', 'remainingBatteryCap'),
                    plots.plot_graph('Cloud_dstAvlRngAll_graph', 'electricPowerConsumption'),
                    plots.plot_graph('Cnv_cnsVeh1s_graph', 'electricPowerConsumption'),
                    plots.plot_graph('Cloud_dstAvlRngAllWth_graph'),

                    plots.plot_maps('latLong_maps', 'GPS'),

                    plots.plot_graph('Cloud_dstAvlRngShrt_graph', 'averageVehicleVelocity'),
                    plots.plot_graph('Cloud_dstAvlRngMed_graph', 'remainingBatteryCap'),

                    plots.plot_graph('VCU_tHVBatt_graph', 'Temperature'),
                    plots.plot_graph('Cloud_dtHVBatt_graph', 'batteryPreCondition'),
                ]}
            />
        </main>
    );
}
