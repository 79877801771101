import styled from 'styled-components';
import { Dialog } from '@bosch/react-frok';

export const StyledDialog = styled(Dialog)`
    .a-box {
        background-color: #ffffff;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`;
