import { light_colors, dark_colors } from './plotlyColors';

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_speed(value) {
    let color;
    const [ min, max ] = [ 0, 70 ];
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    if (value > 50) {
        color = color.indicator_red;
    }
    else if (value > 41) {
        color = color.indicator_orange;
    }
    else {
        color = color.indicator_green;
    }

    return [ color, min, max ];
}

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_temp(topic, value) {
    let color, min, max = '';
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    if (topic.includes('ambient')) {
        if (value > 310) {
            color = color.indicator_red;
        }
        else if (value > 300) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
        [ min, max ] = [ 250, 320 ];
    }
    else if (topic.includes('coolent')) {
        if (value > 380) {
            color = color.indicator_red;
        }
        else if (value > 365) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
        [ min, max ] = [ 250, 420 ];
    }
    else if (topic.includes('catalyst')) {
        if (value > 1300) {
            color = color.indicator_red;
        }
        else if (value > 950) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
        [ min, max ] = [ 273, 1400 ];
    }
    else if (topic.includes('intake')) {
        if (value > 340) {
            color = color.indicator_red;
        }
        else if (value > 320) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
        [ min, max ] = [ 250, 400 ];
    }
    else if (topic.includes('oil')) {
        if (value > 390 || value < 310) {
            color = color.indicator_red;
        }
        else if (value > 375 || value < 320) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
        [ min, max ] = [ 290, 430 ];
    }
    else {
        color = color.text_color;
        [ min, max ] = [ 273, 1500 ];
    }

    return [ color, min, max ];
}

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_preasure(topic, value) {
    let color;
    const [ min, max ] = [ 0, 20000 ];
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    if (topic.includes('intake')) {
        if (value > 15000) {
            color = color.indicator_red;
        }
        else if (value > 12500) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
    }
    else {
        color = color.text_color;
    }

    return [ color, min, max ];
}

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_amperage(topic, value) {
    let color;
    const [ min, max ] = [ -0.128, 0.128 ];
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    if (topic.includes('intake')) {
        if (value < -0.125 || value > 0.125) {
            color = color.indicator_red;
        }
        else if (value < -0.12 || value > 0.12) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
    }
    else {
        color = color.text_color;
    }

    return [ color, min, max ];
}

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_rpm(topic, value) {
    let color;
    const [ min, max ] = [ 0, 5000 ];
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    if (topic.includes('intake')) {
        if (value > 2750) {
            color = color.indicator_red;
        }
        else if (value > 3500) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
    }
    else {
        color = color.text_color;
    }

    return [ color, min, max ];
}

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_fuelRate(type, value) {
    let color;
    const [ min, max ] = [ 0, 0.000002 ];
    JSON.parse(localStorage.getItem('is_dark')) ? (color = dark_colors) : (color = light_colors);
    if (type === 'mm3ps') {
        if (value > 2000) {
            color = color.indicator_red;
        }
        else if (value > 125000) {
            color = color.indicator_orange;
        }
        else {
            color = color.indicator_green;
        }
    }
    else {
        color = color.text_color;
    }

    return [ color, min, max ];
}

/**
 * function to get bar color and boundaries
 * @param {float} value
 * @returns {Array} [color, min, max]
 */
export function get_cab_efficiency(value) {
    let color;
    const [ min, max ] = [ 0, 100 ];
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    if (value > 95) {
        color = color.indicator_green;
    }
    else if (value > 85) {
        color = color.indicator_orange;
    }
    else {
        color = color.indicator_red;
    }

    return [ color, min, max ];
}

/**
 * function to return current text color
 * @returns {string} color
 */
export function get_color() {
    let color;
    JSON.parse(localStorage.getItem('is_dark')) ? color = dark_colors : color = light_colors;
    return color.text_color;
}
