import React from 'react';

import Popup from 'reactjs-popup';

/**
 * Dialog component
 * @param {string} title - Title of the dialog
 * @param {string} header - Header element in the dialog
 * @param {string} level - Alert level of the dialog. Valid values are 'info', 'success', 'warning', 'error'.
 * @param {function} close - Function triggered by the top right close button. If omitted, the close button will not be displayed.
 * @param {object} button1 - JSX element for the first button
 * @param {object} button2 - JSX element for the second button
 * @param {object} button3 - JSX element for the third button
 * @returns {html} html for dialog
 * @example
 */
export default function Dialog({ children, title, header, code, level, close, button1, button2, button3, ...props }) {
    // set level
    if (level instanceof String) {
        level = level.toLowerCase();
    }
    switch (level) {
        case undefined:
        case 'info':
        case 'success':
        case 'warning':
        case 'error':
            break;
        default:
            console.warn(`Invalid dialog alert level "${level}".`);
            level = undefined;
    }

    return (
        <Popup
            position="center center"
            arrow="false"
            closeOnEscape={false}
            closeOnDocumentClick={false}
            {...props}
        >
            <div className="m-dialog -floating-shadow-s -floating">
                {(level !== undefined) ? <div className={`m-dialog__remark --${level}`}></div> : null}
                {(header !== undefined)
                    ? (
                        <div className="m-dialog__header">
                            {(level !== undefined) ? <i className={`a-icon ui-ic-alert-${level}`}></i> : null}
                            <div className="m-dialog__title">{header}</div>
                            {(close)
                                ? (
                                    <button
                                        type="button"
                                        className="a-button a-button--integrated -without-label"
                                        data-frok-action="close"
                                        aria-label="close dialog"
                                        onClick={close}
                                    >
                                        <i className="a-icon a-button__icon ui-ic-close"></i>
                                    </button>
                                )
                                : null}
                        </div>)
                    : null}

                <hr className="a-divider" />
                <div className="m-dialog__content">
                    {(title !== undefined) ? <div className="m-dialog__headline">{title}</div> : null}
                    <div className="m-dialog__body">{children}</div>
                    {(code !== undefined) ? <div className="m-dialog__code">{code}</div> : null}
                    <div className="m-dialog__actions">
                        {button3}
                        {button1}
                        {button2}
                    </div>
                </div>
            </div>
        </Popup>
    );
}
