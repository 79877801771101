import { TOPICS, TOPIC_PATH_KEY_MAP } from './SSETopics';

export const getTopicPath = (carId, topicType) => (TOPICS[topicType]?.path ? `${carId}${TOPICS[topicType].path}` : null);

export const getTopicByType = (topicType) => TOPICS[topicType];

export const getTopicKeyByPath = (key) => TOPIC_PATH_KEY_MAP[key];

export const parseTopicPath = (topicPath) => {
    if (typeof topicPath !== 'string') {
        console.error('Topic Path must be a string');
        return { 'carId': null, 'path': null };
    }

    const firstSlash = topicPath.indexOf('/');
    if (firstSlash === -1) {
        console.error('Invalid Topic Path');
        return { 'carId': null, 'path': null };
    }

    const carIdStr = topicPath.substring(0, firstSlash).trim();
    const carId = Number(carIdStr);

    if (isNaN(carId)) {
        console.error('Invalid Car ID');
        return { 'carId': null, 'path': null };
    }

    const path = topicPath.substring(firstSlash).trim();

    return { 'carId': carId, 'path': path };
};

export const selectMeasurements = (state, keys) => {
    if (!state) {
        return {};
    }

    return keys.reduce((acc, key) => {
        if (key in state) {
            acc[key] = state[key];
        }

        return acc;
    }, {});
};

export const selectMeasurementsByTopicType = (state, topicType) => {
    const selectedTopic = getTopicByType(topicType);
    if (!selectedTopic) {
        return {};
    }
    const measurements = selectedTopic.measurements;
    if (!measurements || measurements.length === 0) {
        return {};
    }

    return selectMeasurements(state, measurements);
};
