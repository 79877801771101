import React, { lazy, Suspense } from 'react';

const LazyComfort = lazy(() => import('./Comfort'));

const Comfort = (props) => (
    <Suspense fallback={null}>
        <LazyComfort {...props} />
    </Suspense>
);

export default Comfort;
