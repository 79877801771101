import React, { createContext, useContext, useReducer } from 'react';

// Define notification types
const MESSAGE_TYPES = {
    'ERROR': 'error',
    'WARNING': 'warning',
    'INFO': 'info',
    'SUCCESS': 'success',
};

// Initialize Context
const MessageContext = createContext();

// Initial state for messages
const initialState = {
    'open': false,
    'type': undefined, // "error" | "warning" | "info" | "success" | undefined
    'title': '',
    'message': '',
};

// Define a reducer to manage message state
const messageReducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_MESSAGE': {
            const { type, title, message } = action.payload;
            return {
                'open': true,
                'type': type,
                'title': title,
                'message': message,
            };
        }
        case 'HIDE_MESSAGE':
            return {
                ...state,
                'open': false,
            };
        default:
            return state;
    }
};

// Provider component
export const MessageProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(messageReducer, initialState);

    return (
        <MessageContext.Provider value={{ 'messageState': state, 'messageDispatch': dispatch, 'MESSAGE_TYPES': MESSAGE_TYPES }}>
            {children}
        </MessageContext.Provider>
    );
};

// Hook to use Message Context
export const useMessage = () => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessage phải được sử dụng trong MessageProvider');
    }

    return context;
};
