import React from 'react';

export default function Dropdown({ children, label, disabled, dynamicWidth, ...props }) {
    return (
        <div className={`a-dropdown${disabled ? ' a-dropdown--disabled' : ''}${dynamicWidth ? ' a-dropdown--dynamic-width' : ''}`}>
            {
                (label !== undefined)
                    ? <label htmlFor={props.id}>{label}</label>
                    : null
            }
            <select id="1" {...props}>{children}</select>
        </div>
    );
}
