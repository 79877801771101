import React, { lazy, Suspense } from 'react';

const LazyMeasures = lazy(() => import('./Measures'));

const Measures = (props) => (
    <Suspense fallback={null}>
        <LazyMeasures {...props} />
    </Suspense>
);

export default Measures;
