import React, { useEffect } from 'react';

import request_sse, { set_topics, thermomanagement_state } from './RealtimeData/SSE_index';
import { initialize_thermomanagement_state, initialize_thermomanagement_timer } from './Thermomanagement';
import { exists, formatNumber } from './SVGUtils';

import { ReactComponent as HVPowernet_SVG } from '../images/svg/hvpowernet.svg';

/**
 * Display current state in SVG
 */
export function draw_hvpowernet(state) {
    const MIN_THICKNESS = 0.5;
    const MAX_THICKNESS = 66.66667;

    const EXPIRATION_DELAY_MS = 10000;

    const EXPIRATION_DATE = new Date(Date.now() - EXPIRATION_DELAY_MS);

    if (!exists('HVPowernet_SVG')) {
        return;
    }

    /**
     * arrows
     */
    for (const measurement in state.measurements) {
        const arrowElem = exists(`arrow_${measurement}`);
        if (arrowElem) {
            let value = state.measurements[measurement].value;
            if (value === undefined || value === null) {
                arrowElem.classList.add('unknown');
                value = 1;
                continue;
            }

            arrowElem.classList.remove('unknown');
            if (value >= 0) {
                // reverse <- arrow
                if (arrowElem.getAttribute('marker-start')) {
                    arrowElem.setAttribute('marker-end', arrowElem.getAttribute('marker-start'));
                    arrowElem.removeAttribute('marker-start');
                }
            }
            else {
                // reverse -> arrow
                if (arrowElem.getAttribute('marker-end')) {
                    arrowElem.setAttribute('marker-start', arrowElem.getAttribute('marker-end'));
                    arrowElem.removeAttribute('marker-end');
                }
            }

            value = Math.abs(value);
            if (value < 0.01) {
                arrowElem.setAttribute('stroke-width', `${MIN_THICKNESS}px`);
                arrowElem.classList.add('zero');
                continue;
            }

            arrowElem.classList.remove('zero');
            value = Math.max(value, 0.01); // limit for log scale
            value = Math.log10(value) * 24.5 - 0.8;
            value = Math.max(MIN_THICKNESS, Math.min(value, MAX_THICKNESS));
            arrowElem.setAttribute('stroke-width', `${value}px`);

            arrowElem.classList.toggle('expired', (state.measurements[measurement].timestamp < EXPIRATION_DATE));
        }
    }

    /**
     * measurements
     */
    for (const measurement in state.measurements) {
        const textElem = exists(`text_${measurement}`);
        if (textElem) {
            const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
            let value = state.measurements[measurement].value;
            if (value === undefined || value === null) {
                value = '?';
            }
            else {
                value = formatNumber(value, textElem);
            }
            textElem.innerHTML = `${value}${unit}`;

            textElem.classList.toggle('expired', state.measurements[measurement].timestamp < EXPIRATION_DATE);
        }
    }
}

/**
 * Page to show SVGs and Animations
 */
export default function HVPowernet() {
    /**
     * useEffect to request and activate realtime data
     */
    useEffect(() => {
        set_topics();
        initialize_thermomanagement_state();
        initialize_thermomanagement_timer(() => {
            draw_hvpowernet(thermomanagement_state);
        });
        request_sse();
    }, []);

    return (
        <main id="hvpowernet_page" className="no_padding svg">
            <HVPowernet_SVG />
        </main>
    );
}
