import React, { lazy, Suspense } from 'react';

const LazyShowCase = lazy(() => import('./ShowCase'));

const ShowCase = (props) => (
    <Suspense fallback={null}>
        <LazyShowCase {...props} />
    </Suspense>
);

export default ShowCase;
