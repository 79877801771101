import React from 'react';

import { Container, Row, Col } from 'react-grid-system';

import LazyShowCase from './components/ShowCase/ShowCase.lazy';
import LazyGeneralSettings from './components/GeneralSettings/GeneralSettings.lazy';
import LazyVehicleInfrastructure from './components/VehicleInfrastructure/VehicleInfrastructure.lazy';
import LazyVehicleLighting from './components/VehicleLighting/VehicleLighting.lazy';
import LazyManoeuvringAndParking from './components/ManoeuvringAndParking/ManoeuvringAndParking.lazy';
import LazySafetyManoeuvres from './components/SafetyManoeuvres/SafetyManoeuvres.lazy';
import LazyComfort from './components/Comfort/Comfort.lazy';
import './LastMileMode.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@bosch/react-frok';
import { Dialog } from '@bosch/react-frok';
import { useLastMileMode } from './LastMileModeContext';
import { MessageProvider } from '../../MessageDialog/MessageContext';
import MessageDialog from '../../MessageDialog/MessageDialog';
import { LastMileModeWrapper } from './LastMileMode.styled';

/**
 * Display current state of Safe Home Mode dashboard
 */
export default function LastMileMode() {
    const {
        lastMileModeDialogOpened,
        setLastMileModeDialogOpened,
        lastMileModeNotificationOpened,
        setLastMileModeNotificationOpened,
        safeStopModeDialogOpened,
        setSafeStopModeDialogOpened,
        safeStopModeNotificationOpened,
        setSafeStopModeNotificationOpened,
        engagingTheParkingBrakeDialogOpened,
        setEngagingTheParkingBrakeDialogOpened,
        engagingTheParkingBrakeNotificationOpened,
        setEngagingTheParkingBrakeNotificationOpened,
        lastMileMode,
    } = useLastMileMode();

    const closeLastMileModeDialog = () => {
        setLastMileModeDialogOpened(false);
        if (lastMileMode) {
            setLastMileModeNotificationOpened(true);
        }
    };

    const closeSafeStopModeDialog = () => {
        setSafeStopModeDialogOpened(false);
        if (lastMileMode) {
            setSafeStopModeNotificationOpened(true);
        }
    };

    const closeEngagingTheParkingBrakeDialog = () => {
        setEngagingTheParkingBrakeDialogOpened(false);
        if (lastMileMode) {
            setEngagingTheParkingBrakeNotificationOpened(true);
        }
    };

    return (
        <MessageProvider>
            <main className="main-content">
                <LastMileModeWrapper>
                    <Container fluid>
                        <Row className="content">
                            <Col xl={6}>
                                <LazyShowCase>
                                </LazyShowCase>
                            </Col>
                            <Col xl={6}>
                                <LazyGeneralSettings>
                                </LazyGeneralSettings>
                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                <LazyVehicleInfrastructure>
                                </LazyVehicleInfrastructure>
                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                <LazyVehicleLighting>
                                </LazyVehicleLighting>

                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                <LazyManoeuvringAndParking>
                                </LazyManoeuvringAndParking>
                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                <LazySafetyManoeuvres>
                                </LazySafetyManoeuvres>
                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                <LazyComfort>
                                </LazyComfort>
                            </Col>
                        </Row>
                    </Container>
                    <Dialog
                        className="dialog"
                        open={lastMileModeDialogOpened}
                        title="Last Mile Mode Warning"
                        variant="warning"
                        modal
                        onClose={closeLastMileModeDialog}
                    >
                        <h3>Insufficient energy</h3>
                        <span>Search for a safe parking space</span>
                    </Dialog>
                    <Notification
                        variant="banner"
                        type="warning"
                        open={lastMileModeNotificationOpened}
                        className="notification-with-title"
                    >
                        <h5>Insufficient energy</h5>
                        <span>Search for a safe parking space</span>
                    </Notification>
                    <Dialog
                        className="dialog"
                        open={safeStopModeDialogOpened}
                        title="Last Mile Mode Warning"
                        variant="error"
                        modal
                        onClose={closeSafeStopModeDialog}
                    >
                        <h3>No more energy</h3>
                        <span>The propulsion is now switched off!</span>
                    </Dialog>
                    <Notification
                        variant="banner"
                        type="error"
                        open={safeStopModeNotificationOpened}
                        className="notification-with-title"
                    >
                        <h5>Insufficient energy</h5>
                        <span>Search for a safe parking space</span>
                    </Notification>
                    <Dialog
                        className="dialog"
                        open={engagingTheParkingBrakeDialogOpened}
                        title="Last Mile Mode Warning"
                        variant="warning"
                        modal
                        onClose={closeEngagingTheParkingBrakeDialog}
                    >
                        <h3>Engaging the parking brake</h3>
                        <span>Secure the vehicle against rolling away by engaging the parking brake</span>
                    </Dialog>
                    <Notification
                        variant="banner"
                        type="warning"
                        open={engagingTheParkingBrakeNotificationOpened}
                        className="notification-with-title"
                    >
                        <h5>Engaging the parking brake</h5>
                        <span>Secure the vehicle against rolling away by engaging the parking brake</span>
                    </Notification>
                    <MessageDialog />
                </LastMileModeWrapper>
            </main>
        </MessageProvider>
    );
}
