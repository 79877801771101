import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

/* Import own components */
import Grid from './Grid/Grid';
import { plots } from './Elements/Plots/Plot';

/* Import self written configs, requests or helpers */
import { language_config } from './config/languages';
import { topics } from './RealtimeData/SSE_index';
import request_sse, { set_topics } from './RealtimeData/SSE_index';

/* Import pictures */
import plus from '../images/grid/plus.png';

const language = language_config();

/**
 * Rendering Page to show customized plots
 *
 * @param {int} reload trigger reloading the page
 * @pararm {function} setReload change reload to trigger update
 * @returns {html} Custom-Dashboard Page
 */
export default function CustomDashboard({ reload, setReload }) {
    /* UseState-Variables to safe dashboard-settings */
    const [ custom_dashboard, setCustomDashboard ] = useState([]);
    const [ selected_plots, setSelectedPlots ] = useState([]);

    /* UseState-Variable to manage Popup */
    const [ openCustomizePopup, setOpenCustomizePopup ] = useState(false);

    /**
     * useEffect executed on each reload
     * - request and activate realtime data
     * - load custom_dashboard from topics
     */
    useEffect(() => {
        /* request realtime-data */
        set_topics();
        request_sse();

        /* load selection from topics */
        define_custom_dashboard();
    }, []);

    /**
     * useEffect triggered by [reload] to trigger update (e.g. on change of car_id)
     * reloading selected plots
     */
    useEffect(() => {
        if (custom_dashboard.length !== 0) {
            get_selected_plots();
        }
    }, [ reload ]);

    /**
     * useEffect triggered by [custom_dashboard] to trigger update (e.g. on change of car_id)
     * reloading selected plots
     */
    useEffect(() => {
        if (custom_dashboard.length !== 0) {
            get_selected_plots();
        }
    }, [ custom_dashboard ]);

    /**
     * function to set custom_dashboard to all possible scales, graphs and maps from the Topics-Tree
     * dictionary-notation: name : <widget name> value : <true | false>
     */
    function define_custom_dashboard() {
        let helpArray = [];
        const signalsAdded = new Set();

        /* Map through Topics-Dictonary and select relevant informations */
        topics.forEach((topic) => {
            /* Separation between one measurement per package and multiple measurements per package */
            if (typeof topic.measurements === 'string') {
                /* Create all graphs and scales, that should be listed in the custom dashboard */
                if (topic.type === 'gps') {
                    helpArray = [ ...helpArray, { 'name': 'latLong_maps', 'value': false } ];
                }
                else if (topic.type !== 'meta') {
                    helpArray = [ ...helpArray, { 'name': `${topic.measurements}_scale`, 'value': false } ];
                    helpArray = [ ...helpArray, { 'name': `${topic.measurements}_graph`, 'value': false } ];
                }
            }
            else {
                if (topic.type === 'gps') {
                    helpArray = [ ...helpArray, { 'name': 'latLong_maps', 'value': false } ];
                }
                else if (topic.type === 'weather') {
                    /* do not print it */
                }
                else {
                    /* Create all graphs and scales, that should be listed in the custom dashboard */
                    for (let i = 0; i < topic.measurements.length; i++) {
                        if (!signalsAdded.has(topic.measurements[i])) {
                            signalsAdded.add(topic.measurements[i]);
                            helpArray = [ ...helpArray, { 'name': `${topic.measurements[i]}_scale`, 'value': false } ];
                            helpArray = [ ...helpArray, { 'name': `${topic.measurements[i]}_graph`, 'value': false } ];
                        }
                    }
                }
            }
        });

        setCustomDashboard(helpArray);
    }

    /**
     * function to add all selected plots to an array and save it in selectedPlots
     */
    function get_selected_plots() {
        /* Get current state from local storage */
        check_local_storage(); //

        /* iterate through custom_dashboard and create list of selectedPlots */
        const selected_plots_helper = [];
        custom_dashboard.forEach((element) => {
            if (element.value) {
                if (element.name.includes('scale')) {
                    selected_plots_helper.push(plots.plot_scale(element.name));
                }
                else if (element.name.includes('graph')) {
                    selected_plots_helper.push(plots.plot_graph(element.name, element.name.replace('_', ' ')));
                }
                else if (element.name.includes('maps')) {
                    selected_plots_helper.push(plots.plot_maps(element.name));
                }
                else {
                    console.log('Unrecognized selection');
                }
            }
        });
        setSelectedPlots(selected_plots_helper);
    }

    /**
     * function to load entrys from local_storage (predefined custom dashboard)
     */
    function check_local_storage() {
        const local_custom_dashboard = JSON.parse(localStorage.getItem('custom_dashboard'));

        if (local_custom_dashboard === null) {
            localStorage.setItem('custom_dashboard', JSON.stringify(custom_dashboard));
        }
        else {
            local_custom_dashboard.forEach((element, index) => {
                custom_dashboard[index].value = element.value === true;
            });
        }
    }

    /**
     * function to set checkbox checkmark for all selected plots in the popup
     */
    function open() {
        custom_dashboard.forEach((element) => {
            document.getElementById(`${element.name}_filter`).checked = element.value;
        });
    }

    /**
     * function to Toggle boolean values for selected plots, save to local storage and trigger page reload
     * @param {*} e event to check which id the field has
     */
    function handle_cd_change(e) {
        const id = e.target.id.replace('_filter', '');
        custom_dashboard.forEach((element, index) => {
            if (element.name === id) {
                custom_dashboard[index].value = !custom_dashboard[index].value;
            }
        });
        localStorage.setItem('custom_dashboard', JSON.stringify(custom_dashboard));
        setReload(reload + 1);
    }

    return (
        <main id="customized_page" className="no_padding">
            <Grid plots={selected_plots} />

            <button id="add" onClick={() => setOpenCustomizePopup(true)}>
                <img src={plus} alt="add layout" />
                <span className="tooltip">{language.add}</span>
            </button>
            <Popup open={openCustomizePopup} arrow="false" onOpen={open} onClose={() => setOpenCustomizePopup(false)}>
                <h3 id="popup_title"> {language.settings_for_graph} </h3>
                <hr />
                <div id="filter_div">
                    <div>
                        {custom_dashboard.map((element, index) => {
                            if (element.name.includes('scale')) {
                                return (
                                    <label className="filter" key={index}>
                                        {`${language.scale_of} ${element.name.replace('_scale', '')}`}
                                        <input type="checkbox" className="input_filter" id={`${element.name}_filter`} defaultValue={false} onChange={handle_cd_change} />
                                        <span className="checkmark"></span>
                                    </label>
                                );
                            }
                            else if (element.name.includes('maps')) {
                                return (
                                    <label className="filter" key={index}>
                                        {`${language.map} ${element.name.replace('_maps', '')}`}
                                        <input type="checkbox" className="input_filter" id={`${element.name}_filter`} defaultValue={false} onChange={handle_cd_change} />
                                        <span className="checkmark"></span>
                                    </label>
                                );
                            }
                            else {
                                return null;
                            }
                        })}
                    </div>
                    <div>
                        {custom_dashboard.map((element, index) => {
                            if (element.name.includes('graph')) {
                                return (
                                    <label className="filter" key={index}>
                                        {`${language.graph_of} ${element.name.replace('_graph', '')}`}
                                        <input type="checkbox" className="input_filter" id={`${element.name}_filter`} defaultValue={false} onChange={handle_cd_change} />
                                        <span className="checkmark"></span>
                                    </label>
                                );
                            }
                            else { return null; }
                        })}
                    </div>
                </div>
                <button id="save" onClick={() => setOpenCustomizePopup(false)}>
                    {language.close}
                </button>
            </Popup>
        </main>
    );
}
